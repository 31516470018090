<div class="meus-vendedores" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [hasBackButton]="false"
        [hasButtons]="false"
        [hasSearchButton]="false"
        [searchPlaceholder]="'buyer.meus-vendedores.placeholder' | translate"
        [searchInput]="searchTerm"
        [title]="'buyer.meus-vendedores.titulo' | translate"
        (onSearch)="searchTerm = $event"
    ></app-header>
    <div class="card card-default col-md-8 offset-md-2">
        <div class="col-md-10 offset-md-1">
            <div class="row">
                <div class="col-md-5 text-center">
                    <h4>{{ 'buyer.meus-vendedores.cabecalho.empresa' | translate}}</h4>
                </div>
                <div class="col-md-6 text-center">
                    <h4>{{ 'buyer.meus-vendedores.cabecalho.vendedor' | translate}}</h4>
                </div>
                <div class="col-md-1"></div>
            </div>
            <div class="dados">
                <ng-scrollbar>
                    @for (holding of filteredHoldings; track holding) {
                        <div class="linha-vendedores" [ngClass]="{ 'removido': holding.removed }">
                            <div class="col-md-5">
                                <div class="mb-3 form-group">
                                    <input type="text" disabled class="form-control form-control-sm" value="{{holding.name}}" />
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="mb-3 form-group">
                                    <select class="form-select form-select-sm" (change)="alterarVendedor(holding, 0)" [(ngModel)]="holding.selected_seller_company_id" [disabled]="holding.removed ? 'disabled' : null">
                                        @for (seller of holding.sellers; track seller) {
                                            <option value="{{seller.seller_company_id}}" [attr.selected]="holding.selected_seller_company_id == seller?.seller_company_id">{{seller.name}}</option>
                                        }
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-1 acao cursor-pointer">
                                @if (!holding.removed) {
                                    <i class="fa fa-trash holding_{{holding.id}}" (click)="bloquearFornecedor(holding)" title="{{ 'buyer.meus-vendedores.tooltip.bloquear-fornecedor' | translate}}"></i>
                                } @else {
                                    <i class="fa fa-plus holding_{{holding.id}}" (click)="desbloquearFornecedor(holding, 0)" title="{{ 'buyer.meus-vendedores.tooltip.desbloquear-fornecedor' | translate}}"></i>
                                }
                            </div>
                            <div class="col-md-1 acao">
                                <div class="fa fa-spinner spin holding_{{holding.id}}" style="display: none;"><div></div></div>
                                <i class="fa fa-check text-success holding_{{holding.id}}" style="display: none;"></i>
                                <i class="fa fa-times text-danger holding_{{holding.id}}" style="display: none;"></i>
                            </div>
                        </div>
                    }
                </ng-scrollbar>
            </div>
        </div>
    </div>
</div>
<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
