<div class="central-semaforo" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [filterInput]="searchType"
        [filterOptions]="filterOptions"
        [hasBackButton]="false"
        [hasSearchButton]="false"
        [searchPlaceholder]="'buyer.semaforo.cabecalho.placeholder' | translate"
        [searchInput]="searchTerm"
        (onFilter)="searchType = $event"
        (onSearch)="searchTerm = $event"
    >
        <div class="atualizar-tela">
            <button [eventTracker]='{"event_category": googleAnalyticsEventNames.atualizarTelaCentral, "page": "conversor"}' class="btn btn-primary btn-sm mt0" (click)="carregarTela(true, false, 0)" ><i class="fa fa-refresh"></i> {{'buyer.semaforo.cabecalho.atualizar' | translate}}</button>
            <small>{{'buyer.semaforo.cabecalho.ultima-atualizacao' | translate}}: {{ ultimaAtualizacao | date:'dd/MM/yyyy HH:mm' }}</small>
        </div>
    </app-header>
    <div class="cotacoes">
        <div class="card card-default">
            @if (cotacoesFiltradas?.length == 0) {
                <div class="text-center vazio">
                    <p class="mb0">{{'buyer.semaforo.mensagens.nenhuma-cotacao-encontrada' | translate}}</p>
                </div>
            } @else {
                <section class="d-flex flex-wrap striped bordered">
                    <header>
                        <div (click)="ordenar_atualizar('semaforo')" class="col-0-5">
                            {{ 'buyer.semaforo.tabela.semaforo' | translate}}
                            @if (ordenar_por == 'semaforo') {
                                <span><em class="fa fa-caret-{{reverse ? 'down' : 'up'}}"></em></span>
                            }
                        </div>
                        <div (click)="ordenar_atualizar('status_id')" class="col-0-5 text-center">
                            {{ 'buyer.semaforo.tabela.fase' | translate}}
                            @if (ordenar_por == 'status_id') {
                                <span><em class="fa fa-caret-{{reverse ? 'down' : 'up'}}"></em></span>
                            }
                        </div>
                        <div (click)="ordenar_atualizar('oficina')" class="col-2">
                            <span>{{ 'buyer.semaforo.tabela.oficina' | translate}}</span>
                            @if (ordenar_por == 'oficina') {
                                <span><em class="fa fa-caret-{{reverse ? 'down' : 'up'}}"></em></span>
                            }
                        </div>
                        <div (click)="ordenar_atualizar('quotation_id')" class="col text-center">
                            {{ 'buyer.semaforo.tabela.id-cotacao' | translate}}
                            @if (ordenar_por == 'quotation_id') {
                                <span><em class="fa fa-caret-{{reverse ? 'down' : 'up'}}"></em></span>
                            }
                        </div>
                        <div (click)="ordenar_atualizar('veiculo')" class="col-4">
                            <span>{{ 'buyer.semaforo.tabela.veiculo' | translate}}</span>
                            @if (ordenar_por == 'veiculo') {
                                <span><em class="fa fa-caret-{{reverse ? 'down' : 'up'}}"></em></span>
                            }
                        </div>
                        <div class="col-5">{{ 'buyer.semaforo.tabela.detalhes' | translate}}</div>
                        <div class="col"></div>
                    </header>
                    <ng-scrollbar #scrollable="ngScrollbar" class="corpo" externalViewport="cdk-virtual-scroll-viewport">
                        <cdk-virtual-scroll-viewport [itemSize]="listItemHeightCentral">
                            <div *cdkVirtualFor="let cotacao of cotacoesFiltradas" class="linha" (click)="visualizar($event, cotacao)" [ngClass]="{ 'notificacao': cotacao.notificacao }">
                                <div class="col-0-5 text-center">
                                    <i class="fa fa-search cursor-pointer"></i>
                                    <i class="fa fa-hourglass-half text-{{cotacao.semaforo_cor}}"></i>
                                </div>
                                <div class="col-0-5 text-center" tooltip="{{cotacao.motivo}}">{{cotacao.fase}}</div>
                                <div class="col-2 truncate">{{cotacao.oficina}}</div>
                                <div class="col text-center">{{cotacao.quotation_id}}</div>
                                <div class="col-4 truncate"><i class="rounded-circle marca {{cotacao.marca}} float-start"></i> {{cotacao.veiculo}}</div>
                                <div class="col-5">
                                    @if (cotacao.status_id === StatusIdEnum.Cotacao) {
                                        <div>
                                            <span>{{ 'buyer.semaforo.tabela.dados.data-abertura' | translate}}: {{cotacao.data_abertura | date:'dd/MM/yyyy HH:mm'}}</span>
                                            |
                                            <span>{{ 'buyer.semaforo.tabela.dados.total-respostas' | translate}}: {{cotacao.total_respostas_positivas}}/{{cotacao.total_respostas}}</span>
                                            |
                                            <span>{{ 'buyer.semaforo.tabela.dados.total-pecas-respondidas' | translate}}: {{cotacao.total_pecas_respondidas}}/{{cotacao.total_pecas}}</span>
                                        </div>
                                    }
                                    @if (cotacao.status_id > StatusIdEnum.Cotacao && cotacao.status_id < StatusIdEnum.Confirmado) {
                                        <div>
                                            <span>{{ 'buyer.semaforo.tabela.dados.data-compra' | translate}}: {{cotacao.data_compra | date:'dd/MM/yyyy HH:mm'}}</span>
                                            @if (cotacao.status_id === StatusIdEnum.EmAnalise) {
                                                <span>| {{ 'buyer.semaforo.tabela.dados.em-analise' | translate}}</span>
                                            }
                                        </div>
                                    }
                                    @if (cotacao.status_id >= StatusIdEnum.Confirmado) {
                                        <div>
                                            <span>{{ 'buyer.semaforo.tabela.dados.data-entrega' | translate}}: {{cotacao.data_entrega | date:'dd/MM/yyyy'}}</span>
                                            |
                                            <span>{{ 'buyer.semaforo.tabela.dados.total-pecas-entregues' | translate}}: {{cotacao.total_pecas_entregues}}/{{cotacao.total_pecas}}</span>
                                        </div>
                                    }
                                </div>
                                <div class="col icons">
                                    @if (cotacao.is_saved || cotacao.already_sended) {
                                        <a href="javascript:void(0)" style="display: flex; text-decoration: none; width: 20px;" tooltip="{{ (cotacao.already_sended ? 'buyer.semaforo.tabela.cotacao-enviada.enviado' : 'buyer.semaforo.tabela.cotacao-enviada.nao-enviado') | translate }}">
                                            <i class="fa fa-paper-plane-o" [ngClass]="{ 'color-grey': cotacao.is_saved, 'color-link': cotacao.already_sended }"></i>
                                        </a>
                                    }
                                    <a href="javascript:void(0)" style="display: flex; text-decoration: none;" (click)="ver_comentarios($event, cotacao)">
                                        <i class="fa fa-comments-o popup fa-1x" [ngClass]="{ 'color-grey': !cotacao.comentarios_tem, 'color-link': cotacao.comentarios_tem }"></i>
                                    </a>
                                </div>
                            </div>
                        </cdk-virtual-scroll-viewport>
                    </ng-scrollbar>
                </section>
            }
        </div>
    </div>
</div>

<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
