<ng-template #templateShoppingCart>
    <div class="modal-shopping-cart">
        <div class="modal-header">
            <h4 class="modal-title">
                @if (!selectedHolding) {
                    <i-feather name="shopping-cart" class="shopping-cart"></i-feather>
                } @else {
                    <div class="btn btn-primary back-to-holdings" (click)="backToHoldings()" #backToHoldingsButton>
                        <i-feather name="arrow-left"></i-feather>
                    </div>
                }

                @if (shoppingCart.itemsPerHolding.length == 0) {
                    <div class="modal-title-text">
                        <b>{{ 'buyer.mercado-partsfy.carrinho.titulo.vazio' | translate }}</b>
                        <span>{{ ('buyer.mercado-partsfy.carrinho.quantidade-itens.vazio' | translate) }}</span>
                    </div>
                } @else if (shoppingCart.itemsPerHolding.length == 1 || selectedHolding) {
                    <div class="modal-title-text">
                        <b>
                            {{'buyer.mercado-partsfy.carrinho.titulo.um-holding' | translate}}
                        </b>
                        <span>
                            {{selectedHolding ? selectedHolding.name : shoppingCart.itemsPerHolding[0].name}} (
                                {{selectedQuantity}}
                                {{selectedQuantity == 1
                                    ? ('buyer.mercado-partsfy.carrinho.quantidade-itens.um-item' | translate)
                                    : ('buyer.mercado-partsfy.carrinho.quantidade-itens.varios-item' | translate)
                                }}
                            )
                        </span>
                    </div>
                } @else {
                    <div class="modal-title-text multiple-holdings">
                        <b>
                            {{'buyer.mercado-partsfy.carrinho.titulo.varios-holdings.principal' | translate}}
                        </b>
                        <span>
                            <i-feather name="info"></i-feather>
                            <p class="multiple-holdings-header-info" [innerHTML]="'buyer.mercado-partsfy.carrinho.titulo.varios-holdings.quantidade-holdings' | translate:{ quantity: shoppingCart.itemsPerHolding.length }"></p>
                        </span>
                    </div>
                }
            </h4>
            <button type="button" data-dismiss="modal" aria-label="Close" class="btn-close" (click)="close();"></button>
        </div>
        <div class="modal-body" >
            @if (shoppingCart.itemsPerHolding.length > 0) {
                <ng-scrollbar>
                    <section>
                    @if (selectedHolding) {
                            @for (item of selectedHolding.items; track item.part.id) {
                                <app-selected-holding (updateItemsInfo)="updateItemsInfo($event)" [companyRelationship]="companyRelationship" [item]="item" [shoppingCart]="shoppingCart" [selectedHolding]="selectedHolding" (saveCart)="saveCartIntern($event)" (backToHoldings)="backToHoldings()"></app-selected-holding>
                            }
                    } @else if (shoppingCart.itemsPerHolding.length == 1) {
                            @for (item of shoppingCart.itemsPerHolding[0].items; track item.part.id) {
                                <app-selected-holding (updateItemsInfo)="updateItemsInfo($event)" [companyRelationship]="companyRelationship" [item]="item" [shoppingCart]="shoppingCart" [selectedHolding]="shoppingCart.itemsPerHolding[0]" (saveCart)="saveCartIntern($event)" (backToHoldings)="backToHoldings()"></app-selected-holding>
                            }
                    } @else {
                            @for (holding of shoppingCart.itemsPerHolding; track holding.id) {
                                <app-multiple-holdings [holding]="holding" (selectHolding)="selectHolding($event)"></app-multiple-holdings>
                            }
                        }
                    </section>
                </ng-scrollbar>
            } @else {
                <div class="empty-cart">
                    <div class="top-section">
                        <i-feather name="frown"></i-feather>
                        <p>{{ 'buyer.mercado-partsfy.carrinho.vazio.titulo' | translate }}</p>
                    </div>
                    <button class="btn btn-primary" (click)="close();" >
                        <i-feather name="arrow-left"></i-feather>
                        {{ 'buyer.mercado-partsfy.carrinho.vazio.botao' | translate }}
                    </button>
                </div>
            }
        </div>
        @if (shoppingCart.itemsPerHolding.length > 0) {
            <div class="modal-footer">
                <app-footer [shoppingCart]="shoppingCart" [selectedHolding]="shoppingCart.itemsPerHolding.length == 1 ? shoppingCart.itemsPerHolding[0] : (selectedHolding ? selectedHolding : null)" [deliveryPrice]="deliveryPrice" [itemsPrice]="itemsPrice" [selectedQuantity]="selectedQuantity" (closeModal)="close()" (completePurchase)="confirmPurchase()"></app-footer>
            </div>
        }
    </div>
    <ngx-ui-loader [loaderId]="'loader-shopping-cart'"></ngx-ui-loader>
</ng-template>
