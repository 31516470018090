import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SettingsService } from '../../../../../_services/settings.service';
import { Peca } from '../../_models/Peca';

@Component({ selector: 'app-card', templateUrl: './card.component.html', styleUrls: ['./card.component.scss'] })
export class BuyerMercadoPartsfyListCardComponent
{
    @Input() public part: Peca;
    @Input() public companyRelationship: any;
    public maxInstallments: number;
    @Output() openModalEvent = new EventEmitter<{modalId: number, part: Peca, maxInstallments?: number}>();

    public freeShippingMinValue: number;

    constructor(public settingService: SettingsService) { }

    ngOnInit() {
        this.freeShippingMinValue = null;

        let frete = this.companyRelationship?.fretes.find(frete => frete.holding_id == this.part.holdingId)

        this.freeShippingMinValue = frete?.shipping_cost > 0 ? frete.min_value : 0

        this.maxInstallments = this.companyRelationship?.formasPagamento.findLast(formaPagamento => formaPagamento.holdingId == this.part.holdingId && formaPagamento.start_value <= this.part.price)?.nroX
    }

    public getCashbackValue() {
        let cashbackWithNoTax = (this.part.cashback / 100) * this.part.priceWithDiscount
        return  cashbackWithNoTax * 0.8
    }

    public openModal(modalId: number, part: Peca, maxInstallments: number): void {
        this.openModalEvent.emit({modalId, part, maxInstallments})
    }
}
