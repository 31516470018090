import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgScrollbar } from 'ngx-scrollbar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { GetVehicleResponseModel } from './_models/GetVehicleResponseModel';
import { Globals } from 'app/_models/globals';
import { MixPanelEventEnum } from 'app/_models/mixPanelEventEnum';
import { NotificationService } from 'app/_services/notification.service';
import { SweetAlertParams } from 'app/_models/notificationParams.model';
import { UploadQuotationFileResponseModel } from './_models/uploadQuotationFileResponseModel';
import { User } from 'app/_models/user';

import { AccountService } from 'app/account/account.service';
import { AddQuotationResponseModel } from './_models/addQuotationResponseModel';
import { BaseService } from 'app/_services/base.service';
import { BuyerNovaCotacaoService } from './novacotacao.service';
import { BuyerService } from '../_services/buyer.service';
import { GeneralService } from 'app/_services/general.service';

import { getBase64ImageSize, getBrandIdByShortName, getFilenameFromUrl } from 'app/_utils/general-utils';

declare const $: any;
declare const isEmpty: any;

@Component({ selector: 'app-novacotacao', templateUrl: './novacotacao.component.html', styleUrls: ['./novacotacao.component.scss'] })

export class BuyerNovaCotacaoComponent implements OnInit
{
    @ViewChild('scrollable') scrollable: NgScrollbar;

    add_peca_clicado: any;
    authMessages = [];
    cotacaoForm: UntypedFormGroup;
    currentUser: User;
    inputFiles: any;
    inputOrcamento: any;
    listas: any;
    loading: any;
    maxUploadImages: number = 15;
    novaCotacao: any;
    peca_nome_obrigatorio: any;
    peca_quantidade_obrigatorio: any;
    salvar_clicado: any;

    public patternPlaca = { '0': { pattern: new RegExp('^[a-zA-Z]{3}\-?\d{4}|[a-zA-Z]{3}[0-9]{1}[a-zA-Z]{1}[0-9]{2}$') } };

    constructor(public accountService: AccountService, private baseService: BaseService, private buyerNovaCotacaoService: BuyerNovaCotacaoService, private buyerService: BuyerService, private generalService: GeneralService,
        private notificationService: NotificationService, private globals: Globals, private formBuilder: UntypedFormBuilder, private location: Location, private ngxLoader: NgxUiLoaderService, private router: Router, private translate: TranslateService)
    {
        this.currentUser = this.baseService.usuarioLocal;
        this.maxUploadImages = this.buyerService.maxUploadImages;
    }

    get f() { return this.cotacaoForm.controls; }

    ngOnInit()
    {
        let maxYear = new Date().getFullYear() + 1;

        this.cotacaoForm = this.formBuilder.group({
            seguradora: ['', Validators.required],
            marca: ['', Validators.required],
            veiculo: ['', Validators.required],
            placa: ['', Validators.required],
            ano: ['', [Validators.required, Validators.min(1950), Validators.max(maxYear)]],
            chassi: ['', Validators.required],
            peca_nome: [''],
            peca_codigo: [''],
            peca_quantidade: ['1'],
            tipo_compra: [false],
            tipo_orcamento: [false],
            part_type_genuina: [''],
            part_type_original: [''],
            part_type_alternativa: [''],
            notes: ['']
        });

        this.clearNovaCotacao();

        if (!this.accountService.checkIfIsLogged())
        {
            this.ngxLoader.startLoader('loader-principal');
            return;
        }

        this.listarDadosCotacaoAsync();

        this.listas = {};
        this.listas.marcas = [];
        this.listas.seguradoras = [];
        this.inputFiles = [];
        this.inputOrcamento = {};

        this.add_peca_clicado = false;
        this.salvar_clicado = false;
    }

    async adicionarPeca()
    {
        this.add_peca_clicado = true;
        this.salvar_clicado = false;

        var nome = this.f.peca_nome.value.toString().toPascalCase();
        var quantidade = this.f.peca_quantidade.value;
        var codigo = $.trim(this.f.peca_codigo.value).toUpperCase();

        if (await this.reqPecaNome() || await this.reqQuantidade())
            return;

        this.novaCotacao.pecas.push({ name: nome, code: codigo, quantity: parseInt(quantidade) });
        this.limparPainelPecas();

        $('[ng-model="novaCotacao.peca.nome"]').focus();
    }

    addBase64ImageToList(b64file: string, filename: string, fileSize: number) {
        b64file = b64file.indexOf(',') > -1 ? b64file.split(',')[1] : b64file;

        var imagem = { b64file, filename, time: new Date().getTime(), size: fileSize };
        this.inputFiles.push(imagem);
    }

    alterarTipoCompra(is_orcamento)
    {
        this.novaCotacao.is_orcamento = is_orcamento;
        this.cotacaoForm.patchValue({ tipo_compra: !is_orcamento, tipo_orcamento: is_orcamento });
    }

    clearNovaCotacao()
    {
        this.novaCotacao = { is_orcamento: false };
        this.novaCotacao.uploader = {};
        this.novaCotacao.showObservacoes = false;
        this.novaCotacao.pecas = [];
    }

    clearVehicleFields() {
        this.cotacaoForm.patchValue({
            ano: '',
            chassi: '',
            marca: -1,
            placa: '',
            veiculo: ''
        });

        this.f.placa.enable();
        this.f.ano.markAsPristine();
        this.f.chassi.markAsPristine();
    }

    async enviar() {
        this.notificationService.clearToastr();
        var formData = this.cotacaoForm.getRawValue();
        let semObjetivo = !this.f.tipo_compra.value && !this.f.tipo_orcamento.value;

        if (this.cotacaoForm.invalid || (this.obterTipoPecaSelecionado() == 0) || (this.novaCotacao.pecas.length == 0) || semObjetivo) {
            var correcoes = '';

            if (this.novaCotacao.pecas.length == 0)
                correcoes += '<ul style="text-align: left"><li>' + this.globals.translate('buyer.nova-cotacao.pecas.obrigatorio') + '</li></ul>';

            if (this.f.placa.errors && this.f.placa.errors.required)
                correcoes += '<ul style="text-align: left"><li>' + this.globals.translate('buyer.nova-cotacao.placa.obrigatorio') + '</li></ul>';

            if (this.f.chassi.errors && this.f.chassi.errors.required)
                correcoes += '<ul style="text-align: left"><li>' + this.globals.translate('buyer.nova-cotacao.chassi.obrigatorio') + '</li></ul>';
            else if (formData.chassi.length !== 17)
                correcoes += '<ul style="text-align: left"><li>' + this.globals.translate('buyer.nova-cotacao.chassi.formato-invalido') + '</li></ul>';

            if (this.f.ano.errors && this.f.ano.errors.required)
                correcoes += '<ul style="text-align: left"><li>' + this.globals.translate('buyer.nova-cotacao.ano.obrigatorio') + '</li></ul>';
            else if (parseInt(formData.ano) < 1950 || parseInt(formData.ano) > (new Date().getFullYear() + 1))
                correcoes += '<ul style="text-align: left"><li>' + this.globals.translate('buyer.nova-cotacao.ano.min-max').replace('{0}', (new Date().getFullYear()).toString()) + '</li></ul>';

            if (this.f.veiculo.errors && this.f.veiculo.errors.required)
                correcoes += '<ul style="text-align: left"><li>' + this.globals.translate('buyer.nova-cotacao.veiculo.obrigatorio') + '</li></ul>';

            if ((this.f.marca.errors && this.f.marca.errors.required) || parseInt(formData.marca) === -1)
                correcoes += '<ul style="text-align: left"><li>' + this.globals.translate('buyer.nova-cotacao.marca.obrigatorio') + '</li></ul>';

            if ((this.f.seguradora.errors && this.f.seguradora.errors.required) || parseInt(formData.seguradora) === -1)
                correcoes += '<ul style="text-align: left"><li>' + this.globals.translate('buyer.nova-cotacao.seguradora.obrigatorio') + '</li></ul>';

            if (this.obterTipoPecaSelecionado() == 0)
                correcoes += '<ul style="text-align: left"><li>' + this.globals.translate('buyer.nova-cotacao.tipo_peca.obrigatorio') + '</li></ul>';

            if (semObjetivo)
                correcoes += '<ul style="text-align: left"><li>' + this.globals.translate('buyer.nova-cotacao.tipo-cotacao.obrigatorio') + '</li></ul>';

            let params: SweetAlertParams = {
                bigSwall: true,
                icon: 'warning',
                isHtml: true,
                message: correcoes,
                title: 'buyer.nova-cotacao.mensagens.atencao',
            }

            await this.notificationService.showInfoWithoutButtons(params);

            return;
        }

        this.ngxLoader.startLoader('loader-principal');

        var chassi = !isEmpty(formData.chassi) ? formData.chassi.toUpperCase() : "";

        var cotacaoData = {
            buyer_company_id: this.currentUser.user_company_id,
            brand_id: parseInt(formData.marca),
            insurance_id: parseInt(formData.seguradora),
            part_type: this.obterTipoPecaSelecionado(),
            is_orcamento: this.novaCotacao.is_orcamento,
            vehicle: formData.veiculo,
            year: parseInt(formData.ano),
            license_plate: formData.placa,
            chassi: chassi,
            items: this.novaCotacao.pecas,
            notes: formData.notes,
            fotos: this.inputFiles.map(function (el) { return { filename: el.filename, b64file: el.b64file }; }),
            // orcamento: this.novaCotacao.is_orcamento && !isEmpty(this.inputOrcamento) ? this.inputOrcamento : null,
            orcamento: !isEmpty(this.inputOrcamento) ? this.inputOrcamento : null
        };

        this.buyerNovaCotacaoService.newQuotation(cotacaoData).subscribe({
            next: async (response: ApiResponseModel<AddQuotationResponseModel>) => {
                if (response && response.success) {
                    this.notificationService.showSuccessToastr(this.globals.translate('buyer.nova-cotacao.mensagens.sucesso.corpo'));

                    let user = this.accountService.obterUsuario();
                    this.generalService.updateUserPropertiesMixPanel(user).subscribe({ error: (e: any) => console.error(e) });
                    this.generalService.trackMixPanelEvent(MixPanelEventEnum.Bid).subscribe({ error: (e: any) => console.error(e) });

                    this.router.navigate(['/comprador/visao-geral']);
                } else {
                    await this.notificationService.showWarning(this.globals.translate('buyer.nova-cotacao.mensagens.erro'), 'buyer.nova-cotacao.mensagens.atencao', true, true, true);
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                console.log(error);
                //this.translate.get('geral.erro.erro-desconhecido'));
                this.ngxLoader.stopLoader('loader-principal');
            }
        });

    }

    editFieldsApiPlate() {
        if (this.loading.apiPlate)
            return;

        let eventProperties = { 'license_plate': this.novaCotacao.license_plate.toUpperCase() };
        this.generalService.trackMixPanelEvent(MixPanelEventEnum.UPlate, eventProperties).subscribe({ error: (e: any) => console.error(e) });
        this.clearVehicleFields();
    }

    listarDadosCotacaoAsync()
    {
        this.loading = { marca: true, seguradora: true };

        this.generalService.listarSeguradoras().subscribe({
            next: response => {
                this.listas.seguradoras = response;
                setTimeout(() => { if ($('select[name="seguradora"]').length > 0) $('select[name="seguradora"]')[0].selectedIndex = 0; this.loading.seguradora = false; }, 500);
            },
            error: error => {
                console.log(error);
            }
        });

        this.generalService.listarMarcas().subscribe({
            next: response => {
                this.listas.marcas = response;
                setTimeout(() => { if ($('select[name="marca"]').length > 0) $('select[name="marca"]')[0].selectedIndex = 0; this.loading.marca = false; }, 500);
            },
            error: error => {
                console.log(error);
            }
        });
    }

    limparPainelPecas()
    {
        this.f.peca_nome.setValue("");
        this.f.peca_codigo.setValue("");
        this.f.peca_quantidade.setValue("1");

        this.f.peca_nome.markAsPristine();
        this.f.peca_codigo.markAsPristine();
        this.f.peca_quantidade.markAsPristine();

        this.peca_nome_obrigatorio = false;
        this.peca_quantidade_obrigatorio = false;
        this.add_peca_clicado = false;

        //$('[name="peca_nome"]').removeClass('ng-dirty');
    }

    manipularReader(readerEvt: any)
    {
        var binaryString = readerEvt.target.result;
        return btoa(binaryString);
    }

    obterTipoPecaSelecionado()
    {
        return (this.f.part_type_genuina.value ? 1 : 0) + (this.f.part_type_original.value ? 2 : 0) + (this.f.part_type_alternativa.value ? 4 : 0);
    }

    removerImagem(item)
    {
        this.inputFiles = this.inputFiles.filter(x => x.time != item.time);
    }

    removerPeca(index)
    {
        this.novaCotacao.pecas.splice(index, 1);
    }

    async reqPecaNome()
    {
        this.peca_nome_obrigatorio = $.trim(this.f.peca_nome.value).length <= 0;
        var result = !this.salvar_clicado && this.peca_nome_obrigatorio;

        if (result)
            await this.notificationService.showWarning('buyer.nova-cotacao.pecas.dados.descricao.obrigatorio', 'buyer.nova-cotacao.mensagens.atencao');

        return result;
    }

    async reqQuantidade()
    {
        if (isNaN(parseInt(this.f.peca_quantidade.value))) {
            await this.notificationService.showWarning('buyer.nova-cotacao.pecas.dados.quantidade.obrigatorio', 'buyer.nova-cotacao.mensagens.atencao');
            return true;
        }

        this.peca_quantidade_obrigatorio = false;
        var quantidade = this.f.peca_quantidade.value;
        var invalid = parseInt(quantidade) <= 0;

        if (invalid)
            this.peca_quantidade_obrigatorio = true;

        var result = !this.salvar_clicado && invalid;

        if (result)
            await this.notificationService.showWarning('buyer.nova-cotacao.pecas.dados.quantidade.obrigatorio', 'buyer.nova-cotacao.mensagens.atencao');

        return result;
    }

    reqTipoPecas()
    {
        return $('[name="tipoPeca"]:checked').length == 0;
    }

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    searchApiPlate() {
        if (this.f.placa.invalid)
            return;

        this.ngxLoader.startLoader('loader-search-plate');
        this.f.placa.disable();
        this.loading.apiPlate = true;

        let eventProperties = { 'license_plate': this.novaCotacao.license_plate.toUpperCase() };
        this.generalService.trackMixPanelEvent(MixPanelEventEnum.CPlate, eventProperties).subscribe({ error: (e: any) => console.error(e) });

        this.buyerNovaCotacaoService.getVehicleByLicensePlate(this.novaCotacao.license_plate).subscribe({
            next: (response: ApiResponseModel<GetVehicleResponseModel>) => {
                if (response && response.success) {
                    this.notificationService.showSuccessToastr(this.globals.translate('buyer.nova-cotacao.api-plate.plate-found'));

                    let brandId = getBrandIdByShortName(this.listas.marcas, response.result.brand);
                    let chassi = (response.result.extra && response.result.extra.chassi) ?? response.result.chassi;

                    if (chassi.includes('*'))
                        chassi = '';

                    this.cotacaoForm.patchValue({
                        veiculo: response.result.model,
                        chassi: chassi,
                        ano: response.result.modelYear,
                        marca: brandId
                    });

                    this.f.ano.markAsPristine();
                    this.f.chassi.markAsPristine();
                }
                this.ngxLoader.stopLoader('loader-search-plate');
                this.loading.apiPlate = false;
            },
            error: async () => {
                this.ngxLoader.stopLoader('loader-search-plate');
                this.loading.apiPlate = false;
                this.f.placa.enable();
                await this.notificationService.showWarning('buyer.nova-cotacao.api-plate.plate-not-found', 'buyer.nova-cotacao.api-plate.title');
                this.generalService.trackMixPanelEvent(MixPanelEventEnum.LicensePlateNotFound, eventProperties).subscribe({ error: (e: any) => console.error(e) });
            }
        });
    }

    async uploadImages() {
        var files = (<any>document.getElementById('novaFoto')).files;

        if(!await this.buyerService.validateUploadImage(files, this.inputFiles)) {
            $('#novaFoto').val('');
            return;
        }

        var totalFiles = files.length;

        $('.uploading-image').show();

        for (let i = 0; i < totalFiles; i++) {
            var file = (<any>document.getElementById('novaFoto')).files[i];
            var b64file = (await this.toBase64(file)).toString();
            b64file = b64file.indexOf(',') > -1 ? b64file.split(',')[1] : b64file;

            this.addBase64ImageToList(b64file, file.name, file.size);
        }

        $('#novaFoto').val('');
        $('.uploading-image').hide();
    }

    async uploadIntegracao() {
        this.inputFiles = [];

        this.ngxLoader.startLoader('loader-principal');
        var uploadIntegracao = <any>document.getElementById('uploadIntegracao');
        var file = uploadIntegracao.files[0];
        var b64file = (await this.toBase64(file)).toString();
        b64file = b64file.indexOf(',') > -1 ? b64file.split(',')[1] : b64file;

        var orcamento = { b64file, filename: file.name, time: new Date().getTime(), size: file.size };
        this.inputOrcamento = orcamento;

        this.clearNovaCotacao();

        this.buyerNovaCotacaoService.uploadQuotationFile(b64file, file.name).subscribe({
            next: (response: ApiResponseModel<UploadQuotationFileResponseModel>) => {
                let loadedPhotos = 0;
                let totalPhotos = 0;

                if (!response.success) {
                    this.notificationService.showErrorToastr('buyer.nova-cotacao.mensagens.erro-upload-orcamento', { translateMessage: true });
                    this.ngxLoader.stopLoader('loader-principal');
                    uploadIntegracao.value = "";
                    return;
                }

                const photos = response.result.photos.slice(0, 10);
                totalPhotos = photos.length;

                if (response.result.photos.length > 10)
                    this.notificationService.showWarningToastr('buyer.nova-cotacao.mensagens.imagem-limite-selecao.corpo', { translateMessage: true, messageParameters: { maxUploadImages: this.buyerService.maxUploadImages } });

                if (photos && photos.length > 0) {
                    for (let i = 0; i < photos.length; i++) {
                        const photoUrl = photos[i];
                        const filename = getFilenameFromUrl(photoUrl);

                        this.buyerNovaCotacaoService.getImageBase64FromUrl(photoUrl).subscribe({
                            next: (res: ApiResponseModel<string>) => {
                                loadedPhotos++;

                                if (res && res.success) {
                                    const imageBase64 = res.result;
                                    const fileSize = getBase64ImageSize(imageBase64);
                                    this.addBase64ImageToList(imageBase64, filename, fileSize);
                                }

                                if (loadedPhotos === totalPhotos)
                                    this.ngxLoader.stopLoader('loader-principal');
                            },
                            error: err => {
                                console.error(err);
                                loadedPhotos++;

                                if (loadedPhotos === totalPhotos)
                                    this.ngxLoader.stopLoader('loader-principal');
                            }
                        });
                    }
                }

                $('.painelcomprador.area-cotacao perfect-scrollbar > div').scrollTop(0);
                const cot = response.result;

                const brand_id = cot.brand_id === 0 ? -1 : cot.brand_id;
                this.novaCotacao.insurance_id = cot.insurance_id;
                this.novaCotacao.brand_id = brand_id;
                this.novaCotacao.part_type_genuina = (cot.part_type & 1) === 1;
                this.novaCotacao.part_type_original = (cot.part_type & 2)  == 2;
                this.novaCotacao.part_type_alternativa = (cot.part_type & 3) == 3;
                this.novaCotacao.is_orcamento = false;

                this.novaCotacao.vehicle = cot.vehicle;
                this.novaCotacao.license_plate = cot.license_plate;
                this.novaCotacao.chassi = cot.chassi;
                this.novaCotacao.year = cot.year;
                this.novaCotacao.notes = cot.notes;

                if (!isEmpty(cot.items)) {
                    for (var i = 0; i < cot.items.length; i++) {
                        var item = cot.items[i];
                        this.novaCotacao.pecas.push({ name: item.name, code: item.code, quantity: item.quantity, insurance_price: item.insurance_price, insurance_discount: item.insurance_discount });
                    }
                }

                if (loadedPhotos === totalPhotos)
                    this.ngxLoader.stopLoader('loader-principal');

                uploadIntegracao.value = "";
            },
            error: error => {
                console.log(error);
                uploadIntegracao.value = "";
                this.ngxLoader.stopLoader('loader-principal');
            }
        });
    }

    voltar(errorMessage: { corpo: string, parametro: any } | null)
    {
        if (!isEmpty(errorMessage))
        {
            this.translate.get(errorMessage.corpo).subscribe(corpo =>
            {
                this.notificationService.showErrorToastr(corpo.replace("#xxx", "#" + errorMessage.parametro));
            });
        }

        this.location.back();
    }
}
