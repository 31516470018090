<div class="footer">
    <span class="section">
        @if (selectedHolding) {
            <span class="delivery-date">
                <div>
                    <i-feather name="truck"></i-feather>
                    <p [innerHTML]="'buyer.mercado-partsfy.carrinho.footer.previsao-entrega' | translate: { date: formatDate(selectedHolding.delivery_time), address: shoppingCart.companyAddress }"></p>
                </div>
            </span>
        }
        @if (shoppingCart.itemsPerHolding.length == 1) {
            <button class="btn btn-primary back-to-market" (click)="close();" >
                <i-feather name="arrow-left"></i-feather>
                {{ 'buyer.mercado-partsfy.carrinho.footer.botoes.comprar-mais' | translate }}
            </button>
        }
    </span>
    <span class="section" [ngClass]="{'fill-footer': shoppingCart.itemsPerHolding.length > 1 && !selectedHolding}">
        <ul class="price-informations">
            <span class="base-infos">
                <li>
                    <span>{{ 'buyer.mercado-partsfy.carrinho.footer.informacoes-preco.produtos' | translate }}({{selectedQuantity}}):</span>
                    <span>{{ itemsPrice | currency:'R$' }}</span>
                </li>
                <li>
                    <span>{{ 'buyer.mercado-partsfy.carrinho.footer.informacoes-preco.frete' | translate }}</span>
                    <span>{{ deliveryPrice > 0 ? (deliveryPrice | currency:'R$') : ('buyer.mercado-partsfy.carrinho.footer.informacoes-preco.frete-gratis' | translate) }}</span>
                </li>
            </span>
            <li class="final-price">
                <span>{{ 'buyer.mercado-partsfy.carrinho.footer.informacoes-preco.total' | translate }}</span>
                <span>{{ (deliveryPrice + itemsPrice) | currency:'R$'}}</span>
            </li>
        </ul>
        @if (!selectedHolding || shoppingCart.itemsPerHolding.length == 1) {
            <button type="button" class="btn btn-primary buy" (click)="completePurchase.emit()">
                {{ 'buyer.mercado-partsfy.carrinho.footer.botoes.pagamento' | translate }}
                <i-feather name="arrow-right"></i-feather>
            </button>
        }
    </span>
</div>
