import { Component, OnInit } from '@angular/core';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import { BuyerMeusVendedoresService } from './meus-vendedores.service';
import { NotificationService } from 'app/_services/notification.service';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { HoldingMySeller } from './_models/holdingMySeller';
import { Globals } from 'app/_models/globals';

declare const $: any;
declare const isEmpty: any;

@Component({ selector: 'app-meus-vendedores', templateUrl: './meus-vendedores.component.html', styleUrls: ['./meus-vendedores.component.scss'] })
export class BuyerMeusVendedoresComponent implements OnInit {
    holdings: HoldingMySeller[];
    filteredHoldings: HoldingMySeller[];
    _searchString: string;

    constructor(
        private meusVendedoresService: BuyerMeusVendedoresService,
        private notificationService: NotificationService,
        private globals: Globals,
        private ngxLoader: NgxUiLoaderService) { }

    ngOnInit() {
        this.holdings = [];
        this.filteredHoldings = [];
        this.searchTerm = '';

        this.carregarTela(0);
    }

    alterarVendedor(holding: HoldingMySeller, nAttempts: number) {
        $('.fa-check.holding_' + holding.id).hide();
        $('.fa-times.holding_' + holding.id).hide();
        $('.fa-spinner.holding_' + holding.id).show();

        this.meusVendedoresService.changeSeller(holding.id, holding.selected_seller_company_id).subscribe({
            next: () => {
                this.notificationService.showSuccessToastr(this.globals.translate('buyer.meus-vendedores.mensagens.sucesso.alterar-vendedor.corpo'));

                $('.fa-spinner.holding_' + holding.id).hide();
                $('.fa-check.holding_' + holding.id).fadeIn('slow', function () { $(this).delay(3000).fadeOut('slow'); });
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    $('.fa-spinner.holding_' + holding.id).hide();
                    $('.fa-times.holding_' + holding.id).fadeIn('slow', function () { $(this).delay(3000).fadeOut('slow'); });
                    this.notificationService.showErrorToastr(this.globals.translate('buyer.meus-vendedores.mensagens.erro.alterar-vendedor.corpo'));
                    return;
                }

                this.alterarVendedor(holding, ++nAttempts);
            }
        });
    }

    atualizarHoldingsFiltradas() {
        this.filteredHoldings = this.filterHoldings();
    }

    async bloquearFornecedor(holding: HoldingMySeller) {
        if (!(await this.notificationService.showConfirmationDialog('buyer.meus-vendedores.mensagens.confirm.bloquear-fornecedor')))
            return;

        $('.fa-check.holding_' + holding.id).hide();
        $('.fa-times.holding_' + holding.id).hide();
        $('.fa-spinner.holding_' + holding.id).show();

        this.bloquearFornecedorExecucao(holding, 0);
    }

    bloquearFornecedorExecucao(holding: HoldingMySeller, nAttempts: number) {
        this.meusVendedoresService.blockSupplier(holding.id).subscribe({
            next: () => {
                $('.fa-spinner.holding_' + holding.id).hide();
                $('.fa-check.holding_' + holding.id).fadeIn('slow', function () { $(this).delay(3000).fadeOut('slow'); });
                holding.removed = true;

                this.notificationService.showSuccessToastr(this.globals.translate('buyer.meus-vendedores.mensagens.sucesso.bloquear-fornecedor.corpo'));
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    $('.fa-spinner.holding_' + holding.id).hide();
                    $('.fa-times.holding_' + holding.id).fadeIn('slow', function () { $(this).delay(3000).fadeOut('slow'); });
                    this.notificationService.showErrorToastr(this.globals.translate('buyer.meus-vendedores.mensagens.erro.bloquear-fornecedor.corpo'));
                    return;
                }

                this.bloquearFornecedorExecucao(holding, ++nAttempts);
            }
        });
    }

    carregarTela(nAttempts: number) {
        this.ngxLoader.startLoader('loader-principal');

        this.meusVendedoresService.getAll().subscribe({
            next: (response: ApiResponseModel<{holdings: HoldingMySeller[]}>) => {
                if (response && response.success) {
                    this.holdings = response.result.holdings;

                    this.holdings.forEach(h => {
                        let seller = h.sellers.find(v => v.seller_company_id === h.selected_seller_company_id);
                        h.selected_seller_company_id = seller ? seller.seller_company_id : null
                    });

                    this.atualizarHoldingsFiltradas();
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('buyer.meus-vendedores.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.carregarTela(++nAttempts);
            }
        });
    }

    desbloquearFornecedor(holding: HoldingMySeller, nAttempts: number) {
        $('.fa-check.holding_' + holding.id).hide();
        $('.fa-times.holding_' + holding.id).hide();
        $('.fa-spinner.holding_' + holding.id).show();

        this.meusVendedoresService.unblockSupplier(holding.id).subscribe({
            next: () => {
                $('.fa-spinner.holding_' + holding.id).hide();
                $('.fa-check.holding_' + holding.id).fadeIn('slow', function () { $(this).delay(3000).fadeOut('slow'); });
                holding.removed = false;

                this.notificationService.showSuccessToastr(this.globals.translate('buyer.meus-vendedores.mensagens.sucesso.desbloquear-fornecedor.corpo'));
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    $('.fa-spinner.holding_' + holding.id).hide();
                    $('.fa-times.holding_' + holding.id).fadeIn('slow', function () { $(this).delay(3000).fadeOut('slow'); });
                    this.notificationService.showErrorToastr(this.globals.translate('buyer.meus-vendedores.mensagens.erro.desbloquear-fornecedor.corpo'));
                    return;
                }

                this.desbloquearFornecedor(holding, ++nAttempts);
            }
        });
    }

    filterHoldings() {
        if (isEmpty(this.holdings))
            return this.holdings;

        var term: string = this._searchString.toLowerCase();

        var result = this.holdings.filter(holding => holding.name.toLowerCase().indexOf(term) !== -1);
        return result;
    }



    get searchTerm(): string {
        return this._searchString;
    }

    set searchTerm(value: string) {
        this._searchString = value;
        this.atualizarHoldingsFiltradas();
    }
}
