<ng-template #templatePartDetails>
    <div class="modal-part-details">
        <div class="modal-header">
            <button type="button" data-dismiss="modal" aria-label="Close" class="btn-close" (click)="close();"></button>
        </div>
        <div class="modal-body">
            <div class="image-container">
                <img class="selected-img" [src]="actualImage" alt="">
                <div class="img-list">
                    @for (img of imagesList; track img) {
                        <img [src]="img.url" [ngClass]="{'active': img.isActive}" (click)="changeImage(img.url)" alt="">
                    }
                </div>
            </div>
            <ng-scrollbar>
                <!-- <span class="tag-new">{{ 'buyer.mercado-partsfy.detalhes-peca.tags.novo' | translate }}</span> -->
                <p class="truncate-multiple-lines title" [tooltip]="part.partName" [zIndex]="2000">{{part.partName}}</p>
                <div class="details">
                    <li>{{ 'buyer.mercado-partsfy.detalhes-peca.informacoes.part-number' | translate }} <span>{{part.partNumber}}</span></li>
                    <li>{{ 'buyer.mercado-partsfy.detalhes-peca.informacoes.vendedor' | translate }}<span class="styled-detail">{{part.holdingName}}</span></li>
                </div>
                <div class="price-container">
                    @if (part.price != part.priceWithDiscount) {
                        <span class="full-price">{{ part.price * actualQuantity | currency:'R$' }}</span>
                    }
                    <div class="main-price">
                        <span class="discount-price">{{ part.priceWithDiscount * actualQuantity | currency:'R$' }}</span>
                        @if (part.cashback > 0) {
                            <span class="tag-cashback">{{ 'buyer.mercado-partsfy.detalhes-peca.informacoes.cashback' | translate: { value: cashbackValue | currency:'R$' } }}</span>
                        }
                    </div>
                    @if (installmentsNumber > 1) {
                        <span class="installment">
                            {{ 'buyer.mercado-partsfy.detalhes-peca.informacoes.parcelamento' | translate: { installmentsNumber: installmentsNumber, installmentsPrice: installmentsPrice | currency: 'R$' } }}
                        </span>
                    }
                </div>
            </ng-scrollbar>
        </div>
        <div class="modal-footer">
            <div class="left-section">
                @if (hasFreeDelivery) {
                    <span class="tag-free-delivery">
                        <i-feather name="truck"></i-feather>
                        {{ 'buyer.mercado-partsfy.detalhes-peca.frete.titulo' | translate }}
                    </span>
                }
                <p class="truncate-multiple-lines delivery-date" [tooltip]='( "buyer.mercado-partsfy.detalhes-peca.frete.data-entrega.part-1" | translate ) + "<b>" + delivery_time_formatted + "</b>" + ( "buyer.mercado-partsfy.detalhes-peca.frete.data-entrega.part-2" | translate ) + (companyAddress)' [zIndex]="2000">{{ 'buyer.mercado-partsfy.detalhes-peca.frete.data-entrega.part-1' | translate }}<b> {{delivery_time_formatted}} </b>{{ 'buyer.mercado-partsfy.detalhes-peca.frete.data-entrega.part-2' | translate }}{{companyAddress}}</p>
            </div>
            <div class="right-section">
                <input type="number" min="1" max="999" value="1" name="part-quantity" (change)="validatePartQuantity()">
                <button type="button" class="btn btn-primary" (click)="addToCart(part)">
                    <i-feather name="shopping-cart"></i-feather>
                    {{ 'buyer.mercado-partsfy.detalhes-peca.carrinho' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>
