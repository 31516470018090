<div class="card" (click)="openModal(1, part, maxInstallments)">
    <div class="header">
        @if (part.cashback > 0) {
            <span>{{ 'buyer.mercado-partsfy.peca-card.cashback.part-1' | translate }}{{getCashbackValue() | currency: 'R$'}}{{ 'buyer.mercado-partsfy.peca-card.cashback.part-2' | translate }}</span>
        }
    </div>
    <main>
        <div class="image-container">
            <img [src]="settingService.pathImagesMercadoPartsfy + (part.urlImages != null && part.urlImages.length > 0 ? part.urlImages[0] : '/not-found.png')" [alt]="part.partName">
        </div>
        <div class="info">
            <p class="truncate-multiple-lines" [tooltip]="part.partName">{{part.partName}}</p>
            <div class="price-container">
                @if (part.price != part.priceWithDiscount) {
                    <span class="full-price">{{ part.price | currency:'R$' }}</span>
                }
                <div class="main-price">
                    <span class="discount-price">{{ part.priceWithDiscount | currency:'R$' }}</span>
                    @if (freeShippingMinValue < part.priceWithDiscount) {
                        <span class="tag-free-delivery">{{ 'buyer.mercado-partsfy.peca-card.frete-gratis' | translate }}</span>
                    }
                </div>
                @if (maxInstallments > 0) {
                    <span class="installment">
                        {{maxInstallments}}{{ 'buyer.mercado-partsfy.peca-card.parcelamento.part-1' | translate }}
                        {{ part.priceWithDiscount / maxInstallments | currency: 'R$' }}
                        {{ 'buyer.mercado-partsfy.peca-card.parcelamento.part-2' | translate }}
                    </span>
                }
            </div>
        </div>
    </main>
</div>
