<div class="visao-geral-nova-cotacao">
    <app-header
        [title]="('buyer.nova-cotacao.titulo' | translate)"
        (onGoBack)="voltar($event)"
    >
        <div class="modal-title importar-arquivo">
            <span class="btn btn-primary btn-file">
                <span>{{'buyer.nova-cotacao.botao.audatex_soma.label' | translate}}</span>
                <input type="file" id="uploadIntegracao" (change)="uploadIntegracao()">
            </span>
            <small>{{'buyer.nova-cotacao.botao.audatex_soma.label-menor' | translate}}</small>
        </div>
    </app-header>

    <div class="col-md-12 card card-default mb0 painelcomprador area-cotacao">
        <div class="mb-lg">
            <ng-scrollbar #scrollable="ngScrollbar" class="{{ accountService.obterCssInIframe() }}" height="auto">
                <form name="formCotacao" id="formCotacao" class="form-cotacao form-validate" [formGroup]="cotacaoForm" novalidate="">
                    <fieldset>
                        <div class="row">
                            <div class="col-sm-4 col-md-4 p0 tipo-cotacao">
                                <label>{{ 'buyer.nova-cotacao.tipo-cotacao.label' | translate}}</label>
                                <div class="col-md-12 p0">
                                    <label class="form-check-inline c-checkbox p0 col-xs-12 col-md-4" (click)="alterarTipoCompra(false)">
                                        <input name="tipo_compra" type="checkbox" formControlName="tipo_compra">
                                        <span class="fa fa-check"></span> {{ 'buyer.nova-cotacao.tipo-cotacao.opcoes.compra' | translate}}
                                    </label>
                                    <label class="form-check-inline c-checkbox p0 col-xs-12 col-md-6 is-orcamento" (click)="alterarTipoCompra(true)">
                                        <input name="tipo_orcamento" type="checkbox" formControlName="tipo_orcamento">
                                        <span class="fa fa-check"></span>
                                        <label class="text-normal">{{ 'buyer.nova-cotacao.tipo-cotacao.opcoes.orcamento' | translate}}</label>
                                        <!--<label class="maior-1024 text-normal">{{ 'buyer.nova-cotacao.tipo-cotacao.opcoes.orcamento' | translate}}</label>
                                        <label class="menor-1024 text-normal">{{ 'buyer.nova-cotacao.tipo-cotacao.opcoes.orcamento-menor1024' | translate}}</label>-->
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-4 col-md-4 tipo-pecas">
                                <label>{{ 'elemento.tipo-pecas.titulo' | translate}}</label>
                                <div class="col-md-12" style="margin-bottom: 10px;">
                                    <label class="form-check-inline c-checkbox row col-xs-12 col-md-4">
                                        <input name="tipoPeca" type="checkbox" value="1" [(ngModel)]="novaCotacao.part_type_genuina" formControlName="part_type_genuina">
                                        <span class="fa fa-check"></span> {{ 'elemento.tipo-pecas.genuina-2' | translate}}
                                    </label>
                                    <label class="form-check-inline c-checkbox row col-xs-12 col-md-4">
                                        <input name="tipoPeca" type="checkbox" value="2" [(ngModel)]="novaCotacao.part_type_original" formControlName="part_type_original">
                                        <span class="fa fa-check"></span> {{ 'elemento.tipo-pecas.original-2' | translate}}
                                    </label>
                                    <label class="form-check-inline c-checkbox row col-xs-12 col-md-3" style="white-space: nowrap;">
                                        <input name="tipoPeca" type="checkbox" value="3" [(ngModel)]="novaCotacao.part_type_alternativa" formControlName="part_type_alternativa">
                                        <span class="fa fa-check"></span> {{ 'elemento.tipo-pecas.alternativa-2' | translate}}
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-2 col-md-2">
                                <div class="mb-3 form-group">
                                    <label>{{ 'buyer.nova-cotacao.placa.label' | translate}}</label>
                                    <div class="input-group">
                                        <input name="placa" [(ngModel)]="novaCotacao.license_plate" (keyup)="searchApiPlate()" formControlName="placa" mask="SSS-0A00" restrict="reject" required type="text" class="form-control text-uppercase">
                                        @if (f.placa.enabled) {
                                            <span class="input-group-text"><i class="fa fa-search"></i></span>
                                        }
                                        @if (f.placa.disabled) {
                                            <span (click)="editFieldsApiPlate()" class="input-group-text searchPlate"><i class="fa fa-edit"></i></span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-2 col-md-2 seguradora">
                                <div class="mb-3 form-group">
                                    <label>{{ 'buyer.nova-cotacao.seguradora.label' | translate}}</label>
                                    <select name="seguradora" required class="form-select" [(ngModel)]="novaCotacao.insurance_id" formControlName="seguradora" [disabled]="loading.seguradora">
                                        <option value="-1">{{'buyer.nova-cotacao.seguradora.placeholder' | translate}}</option>
                                        <optgroup label="---------">
                                            @for (seguradora of listas?.seguradoras | filterBy: { is_principal: 1 }; track seguradora) {
                                                <option [value]="seguradora?.id">{{seguradora.name}}</option>
                                            }
                                        </optgroup>
                                        <optgroup label="---------">
                                            @for (seguradora of listas?.seguradoras; track seguradora) {
                                                <option [value]="seguradora?.id">{{seguradora.name}}</option>
                                            }
                                        </optgroup>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="margin-top: 10px;" ngxUiLoaderBlurred [loaderId]="'loader-search-plate'" [blur]="0">
                            <div class="col-sm-4 col-md-4">
                                <div class="mb-3 form-group">
                                    <label>{{ 'buyer.nova-cotacao.veiculo.label' | translate}}</label>
                                    <input name="veiculo" [(ngModel)]="novaCotacao.vehicle" formControlName="veiculo" required maxlength="200" type="text" class="form-control text-capitalize restrict-special-characters" placeholder="{{ 'buyer.nova-cotacao.veiculo.placeholder' | translate}}">
                                </div>
                            </div>
                            <div class="col-sm-2 col-md-2">
                                <div class="mb-3 form-group">
                                    <label>{{ 'buyer.nova-cotacao.ano.label' | translate}}</label>
                                    <input name="ano" [(ngModel)]="novaCotacao.year" formControlName="ano" mask="0000" min="1950" max="CurrentDate.getFullYear() + 1" required type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-sm-2 col-md-2 marca">
                                <div class="mb-3 form-group">
                                    <label>{{ 'buyer.nova-cotacao.marca.label' | translate}}</label>
                                    <select name="marca" required class="form-select" [(ngModel)]="novaCotacao.brand_id" formControlName="marca" [disabled]="loading.marca">
                                        <option value="-1">{{'buyer.nova-cotacao.marca.placeholder' | translate}}</option>
                                        <optgroup label="---------">
                                            @for (marca of listas?.marcas | filterBy: { is_principal: 1 }; track marca) {
                                                <option [value]="marca?.id">{{marca.name}}</option>
                                            }
                                        </optgroup>
                                        <optgroup label="---------">
                                            @for (marca of listas?.marcas; track marca) {
                                                <option [value]="marca?.id">{{marca.name}}</option>
                                            }
                                        </optgroup>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-4 col-md-4">
                                <div class="mb-3 form-group">
                                    <label>{{ 'buyer.nova-cotacao.chassi.label' | translate}}</label>
                                    <input name="chassi" [(ngModel)]="novaCotacao.chassi" formControlName="chassi" mask="AAAAAAAAAAAAAAAAA" restrict="reject" maxlength="17" required type="text" class="form-control text-uppercase">
                                </div>
                            </div>
                            <ngx-ui-loader [loaderId]="'loader-search-plate'" [hasProgressBar]="false" [fgsSize]="45"></ngx-ui-loader>
                        </div>
                    </fieldset>
                    <div class="panel-total-pecas">
                        <div class="panel-pecas row pecas-header vertical-align">
                            <div class="col-sm-5 col-md-5">
                                <div class="mb-3 form-group">
                                    <label>{{ 'buyer.nova-cotacao.pecas.tabela.peca' | translate}}</label>
                                    <input formControlName="peca_nome" name="peca_nome" type="text" maxlength="200" placeholder="{{ 'buyer.nova-cotacao.pecas.dados.descricao.placeholder' | translate}}" class="form-control form-control-sm text-capitalize restrict-special-characters" ng-required="reqPecaNome" [ngClass]="peca_nome_obrigatorio && add_peca_clicado ? 'ng-dirty' : ''" />
                                </div>
                            </div>
                            <div class="col-sm-4 col-md-3">
                                <div class="mb-3 form-group">
                                    <label>{{ 'buyer.nova-cotacao.pecas.tabela.codigo' | translate}}</label>
                                    <input formControlName="peca_codigo" name="peca_codigo" type="text" maxlength="50" placeholder="" class="form-control form-control-sm text-uppercase restrict-special-characters" />
                                </div>
                            </div>
                            <div class="col-sm-2 col-md-2">
                                <div class="mb-3 form-group">
                                    <label>{{ 'buyer.nova-cotacao.pecas.tabela.quantidade' | translate}}</label>
                                    <input formControlName="peca_quantidade" name="peca_quantidade" type="text" placeholder="" class="form-control form-control-sm" ng-required="reqQuantidade" maxlength="2" ui-number-mask="0" min="1" max="99" />
                                </div>
                            </div>
                            <div class="col-sm-1 col-md-1">
                                <div class="mb-3 form-group">
                                    <button class="btn btn-primary btn-sm btn-adicionar-pecas w-100" (click)="adicionarPeca()"><i class="fa fa-plus"></i></button>
                                </div>
                            </div>
                        </div>
                        <fieldset>
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <div class="table-responsive">
                                        @if (novaCotacao?.pecas?.length > 0) {
                                            <table class="table tabela-novacot table-striped table-hover margtabela" style="margin-bottom:0">
                                                <thead>
                                                    <tr class="text-center">
                                                        <th style="width: 42.666667%;"></th>
                                                        <th style="width: 25.7%;"></th>
                                                        <th class="col-sm-2 col-md-2"></th>
                                                        <th class="col-sm-2 col-md-2"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    @for (peca of novaCotacao?.pecas; track peca; let i = $index;) {
                                                        <tr [attr.data-index]="i">
                                                            <td><label ellipsis class="ellipsis6">{{peca.name}}</label></td>
                                                            <td><label ellipsis class="ellipsis3">{{peca.code}}</label></td>
                                                            <td>{{peca.quantity}}</td>
                                                            <td>
                                                                <button class="btn btn-sm btn-light excluirpeca" (click)="removerPeca(i)">
                                                                    <i class="fa fa-trash"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        } @else {
                                            <div class="nenhuma-peca-adicionada">
                                                {{ 'buyer.nova-cotacao.pecas.tabela.vazia' | translate }}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="box-links">
                        <span class="btn btn-light btn-file float-start" [ngClass]="{disabled:inputFiles?.length >= maxUploadImages}">
                            <i class="fa fa-upload"></i> {{ 'buyer.nova-cotacao.links.fotos' | translate}}
                            <input type="file" accept="image/*" id="novaFoto" (change)="uploadImages()" [disabled]="inputFiles?.length >= maxUploadImages ? 'disabled' : null" multiple />
                            <i class="fa fa-refresh text-tertiary uploading-image"></i>
                        </span>
                        @if (inputFiles?.length) {
                            <div class="col-sm-12 col-md-12">
                                <table class="table table-striped table-responsive table-no-bordered">
                                    <tbody>
                                        @for (item of inputFiles; track item) {
                                            <tr>
                                                <td width="75%">{{ item.filename }}</td>
                                                <td width="15%">{{ item.size/1024/1024 | number : '1.2-2' }} MB</td>
                                                <td width="10%">
                                                    <div class="excluirfoto">
                                                        <i class="fa fa-close cursor-pointer" (click)="removerImagem(item)"></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                    <div class="box-observacoes">
                        <label for="">{{ 'buyer.nova-cotacao.observacao.label' | translate}}</label>
                        <textarea class="form-control" [(ngModel)]="novaCotacao.notes" name="notes" formControlName="notes" placeholder="{{ 'buyer.nova-cotacao.observacao.placeholder' | translate}}" cols="100" rows="3"></textarea>
                    </div>
                </form>
            </ng-scrollbar>
        </div>
        <div class="row area-botoes">
            <button id="btnSalvar" type="button" class="btn btn-primary p-lg text-uppercase col-md-4" (click)="enviar()">{{ 'buyer.nova-cotacao.botao.salvar' | translate}}</button>
        </div>
    </div>
</div>
<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
<ng-template #templateSeguradora></ng-template>
<ng-template #templateMarcas></ng-template>
