import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbAccordionDirective } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { AccountService } from 'app/account/account.service';
import { BuyerSemaforoCuradorService } from '../../semaforo.service';
import { NotaFiscalService } from 'app/_componentes/notafiscal/notafiscal.service';
import { NotificationService } from 'app/_services/notification.service';
import { SellerDashboardService } from 'app/seller/dashboard/dashboard.service';

import { AddComplementDataModel } from 'app/buyer/_componentes/adicionar-complemento/_models/addComplementDataModel';
import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { BuyerSemaforoPostAssociarPecaNFResponseModel } from '../../_models/buyerSemaforoPostAssociarPecaNFResponseModel';
import { BuyerSemaforoNotaFiscalConsolidada } from '../../_models/buyerSemaforoNotaFiscalConsolidada';
import { capitalizeFirstLetter } from 'app/_utils/general-utils';
import { CentralSemaforoCotacao } from '../../../semaforo-conversor/_models/cotacao';
import { CentralSemaforoCotacaoInconsistencia } from '../../_models/inconsistencia';
import { EditPartNumberPostModel } from '../../_models/editPartNumberPostModel';
import { Globals } from 'app/_models/globals';
import { LocalStorageUtils } from 'app/_utils/localstorage';
import { SweetAlertParams } from 'app/_models/notificationParams.model';
import { Transportadora } from 'app/_models/transportadora';
import { User } from 'app/_models/user';

import { BuyerSemaforoCuradorModalJuntarPedidoComponent } from '../juntar-pedido-modal/juntar-pedido-modal.component';

declare const $: any;
declare const addTooltip: any;
declare const formataNumeroTelefone: any;
declare const getJavascriptDateFromIsoDate: any;
declare const isEmpty: any;
declare const sort_by: any;

@Component({ selector: 'app-lista-inconsistencias', templateUrl: './lista-inconsistencias.component.html', styleUrls: ['./lista-inconsistencias.component.scss'] })
export class BuyerSemaforoCuradorListaInconsistenciasComponent implements OnInit
{
    @ViewChild(NgbAccordionDirective) accordion: NgbAccordionDirective;
    @ViewChild('modalMergeOrderComponent') modalMergeOrderComponent: BuyerSemaforoCuradorModalJuntarPedidoComponent;

    private localStorageUtils = new LocalStorageUtils();
    capitalizeFirstLetter: any = capitalizeFirstLetter;
    currentUser: User;
    currentLocalUser: User;
    inconsistenciasOrdenadas: CentralSemaforoCotacaoInconsistencia[];
    inconsistenciasSelecionadas: string[];
    pedido: CentralSemaforoCotacao;
    transportadoras: Transportadora[] = [];
    pdfSrc: string;
    visaoPDF: boolean;
    disableMergeOrdersButton: boolean = false;
    showAdicionarComplementoModal: boolean = false;
    addComplementData: AddComplementDataModel;
    deliveryTimes: any[];
    feriados: string[];

    accordionInfos = {
        inconsistencia: {
            collapsed: false,
            id: 'accordionInconsistencias'
        },
        pecas: {
            collapsed: true,
            id: 'accordionPecas'
        },
        vendedor: {
            collapsed: true,
            id: 'accordionVendedor'
        }
    }

    constructor(private accountService: AccountService, public curadorService: BuyerSemaforoCuradorService, private sellerDashboardService: SellerDashboardService, public nfService: NotaFiscalService,
        private notificationService: NotificationService, private globals: Globals,
        public activatedRoute: ActivatedRoute, private ngxLoader: NgxUiLoaderService, private router: Router)
    {
        if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state)
        {
            const currentState = this.router.getCurrentNavigation().extras.state;

            this.pedido = currentState.pedido;
            this.transportadoras = currentState.transportadoras;
        }
    }

    ngOnInit(): void
    {
        if (!this.pedido || !this.transportadoras)
        {
            this.goToSemaforo(null);
            return;
        }

        this.currentUser = this.localStorageUtils.obterUsuario();
        this.currentLocalUser = this.localStorageUtils.obterUsuarioLocal();

        this.inconsistenciasSelecionadas = [];

        this.pedido.vendedor_whatsapp_formatado = formataNumeroTelefone(this.pedido.vendedor_whatsapp);
        this.inconsistenciasOrdenadas = this.pedido.inconsistencias.sort(sort_by([{ name: 'is_tipo_peca' }, { name: 'texto' }]));

        this.calcularTotalPedido();

        this.pdfSrc = this.pedido.notasFiscais[0]?.url;
        this.visaoPDF = this.pedido.notasFiscais.length === 1;

        this.atualizarDadosNF(this.pedido.order_id, 0);

        this.nfService.setPedidoNotaFiscal(this.pedido);
        this.checkDisableMergeOrdersButton(0);
    }

    accordionChange(panelId: string) {
        this.inconsistenciasSelecionadas = [];

        if((!this.accordionInfos.inconsistencia.collapsed && this.accordionInfos.inconsistencia.id != panelId)
            || (!this.accordionInfos.pecas.collapsed && this.accordionInfos.pecas.id != panelId)
            || (!this.accordionInfos.vendedor.collapsed && this.accordionInfos.vendedor.id != panelId))
            return

        var otherPanel = panelId === this.accordionInfos.inconsistencia.id ? this.accordionInfos.pecas.id : this.accordionInfos.inconsistencia.id;
        this.accordion.toggle(otherPanel);
        setTimeout(() => addTooltip(), 200);
    }

    accordionShow(panelId: string) {
        this.accordionInfos.inconsistencia.collapsed = true;
        this.accordionInfos.pecas.collapsed = true;
        this.accordionInfos.vendedor.collapsed = true;

        switch (panelId) {
            case this.accordionInfos.inconsistencia.id:
                this.accordionInfos.inconsistencia.collapsed = false;
                break;
            case this.accordionInfos.pecas.id:
                this.accordionInfos.pecas.collapsed = false;
                break;
            case this.accordionInfos.vendedor.id:
                this.accordionInfos.vendedor.collapsed = false;
                break;
        }
    }

    atualizarDadosNF(orderId: number, nAttempts: number) {
        this.curadorService.getNotaFiscalByOrderId(orderId).subscribe({
            next: async (response: ApiResponseModel<{ notaFiscalConsolidada: BuyerSemaforoNotaFiscalConsolidada }>) => {
                if (response && response.success && response.result?.notaFiscalConsolidada) {
                    let result = response.result;
                    this.pedido.pecas
                        .filter(p => p.part_number)
                        .map(p => p.part_number_nf = result.notaFiscalConsolidada?.pecas.find(pnf => pnf.codigo.toLowerCase() === p.part_number.toLowerCase())?.codigo);

                    if (this.pedido.notasFiscais.length <= 0) {
                        let nota = {
                            url: result.notaFiscalConsolidada?.urls[0].replace('.xml', '.pdf'),
                            numero: result.notaFiscalConsolidada?.numeros[0]
                        }

                        this.pedido.notasFiscais.push(nota);
                        this.nfService.setPedidoNotaFiscal(this.pedido);
                    }
                    return;
                }

                await this.notificationService.showWarning('buyer.curador.inconsistencias.mensagens.erro.inconsistencia-nao-encontrada.corpo', 'buyer.curador.inconsistencias.mensagens.erro.inconsistencia-nao-encontrada.titulo');
                this.router.navigateByUrl('/comprador/curador');
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.notificationService.showErrorToastr(this.globals.translate('buyer.curador.inconsistencias.mensagens.erro.buscar-dados-nf.corpo'));
                    this.ngxLoader.stopLoader('loader-principal');
                    return;
                }

                this.atualizarDadosNF(orderId, ++nAttempts);
            }
        });
    }

    calcularTotalPedido()
    {
        let total = this.pedido.pecas.length > 0 ? this.pedido.pecas.map(p => p.total).reduce((acumulador, valor) => acumulador += valor) : 0;
        this.pedido.total = total;
    }

    closeAdicionarComplemento($event) {
        this.showAdicionarComplementoModal = false;

        setTimeout(() =>
        {
            if ($event.atualizar)
            {
                this.atualizarDadosNF(this.pedido.order_id, 0);

                this.curadorService.obter(this.pedido.quotation_id, false).subscribe({
                    next: (pedido: CentralSemaforoCotacao) => {
                        this.pedido = pedido;
                    }
                })
            }
        }, 200);
    }

    excluirNota($event, pedido)
    {
        this.router.navigateByUrl('/comprador/curador');

        this.sellerDashboardService.excluirNF($event.nota_url, pedido, null);
    }

    async pecaAssociarNF(orderId: number, peca, $event)
    {
        let selectedNF = this.pedido.notasFiscais[0].numero;
        if (this.pedido.notasFiscais && this.pedido.notasFiscais.length > 1)
        {
            let html = '';

            this.pedido.notasFiscais.map(nf =>
            {
                html += `
                        <div class="form-check">
                            <label class="form-check-label swal-label" for="${nf.numero}">
                                <input class="form-check-input" type="radio" name="check-nf" id="${nf.numero}" data-nf="${nf.numero}"> NF número: ${nf.numero}
                            </label>
                        </div>
                        `;
            });

            let params: SweetAlertParams = {
                title: 'buyer.curador.inconsistencias.editar-peca.associar.titulo',
                message: html,
                isHtml: true,
                preConfirm: () => {
                    if (!$('.form-check-input:checked').val()) {
                        this.notificationService.showErrorToastr(this.globals.translate('buyer.curador.inconsistencias.editar-peca.associar.ninguem-selecionado'));
                        return false;
                    }

                    return true;
                },
                cancelButtonText: 'elemento.generico-botao.fechar',
                confirmButtonText: 'buyer.curador.inconsistencias.editar-peca.associar.botao'
            }

            this.notificationService.showConfirm(params).then((result) => {
                if (result.value) {
                    selectedNF = $('.form-check-input:checked').attr('data-nf');
                    this.pecaAssociarNFAcao(orderId, peca.id, selectedNF, 0);
                }
            });

            return;
        }

        if (!(await this.notificationService.showConfirmationDialog('buyer.curador.inconsistencias.editar-peca.associar.confirm')))
            return;

        this.pecaAssociarNFAcao(orderId, peca.item_id, selectedNF, 0);
    }

    private pecaAssociarNFAcao(order_id: number, item_id: number, nro_nota: string, nAttempts: number)
    {
        this.ngxLoader.startLoader('loader-principal');

        this.curadorService.associarPecaNF(item_id, order_id, nro_nota).subscribe({
            next: (response: ApiResponseModel<BuyerSemaforoPostAssociarPecaNFResponseModel>) =>
            {
                if (response != null && response.success)
                {
                    this.pedido.inconsistencias = Object.assign([], response.result.inconsistencias);
                    this.pedido.inconsistencia_tem = response.result.inconsistencias && response.result.inconsistencias.length > 0;
                    this.inconsistenciasOrdenadas = this.pedido.inconsistencias.sort(sort_by([{ name: 'is_tipo_peca' }, { name: 'texto' }]));

                    let peca = this.pedido.pecas.find(p => p.item_id === item_id);
                    peca.order_info_nota_fiscal_id = nro_nota;

                    this.notificationService.showSuccessToastr(this.globals.translate('buyer.curador.inconsistencias.mensagens.sucesso.associar.corpo'));
                    this.ngxLoader.stopLoader('loader-principal');
                    return;
                }
                else
                {
                    this.notificationService.showErrorToastr(this.globals.translate('buyer.curador.inconsistencias.mensagens.erro.associar.corpo'));
                    this.ngxLoader.stopLoader('loader-principal');
                    return;
                }
            },
            error: error =>
            {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5)
                {
                    this.notificationService.showErrorToastr(this.globals.translate('buyer.curador.inconsistencias.mensagens.erro.associar.corpo'));
                    this.ngxLoader.stopLoader('loader-principal');
                    return;
                }

                this.pecaAssociarNFAcao(item_id, order_id, nro_nota, ++nAttempts);
            }
        });
    }

    pecaEditar(orderId: number, peca, $event) {
        let params: SweetAlertParams = {
            title: 'buyer.curador.inconsistencias.editar-peca.editar.titulo',
            input: 'text',
            message: 'buyer.curador.inconsistencias.editar-peca.editar.label',
            inputValidator: (value) => {
                return new Promise((resolve) => {
                    if (!isEmpty(value))
                        resolve('');
                    else
                        resolve(this.globals.translate('buyer.curador.inconsistencias.editar-peca.editar.obrigatorio'));
                })
            },
            cancelButtonText: 'elemento.generico-botao.fechar',
            confirmButtonText: 'elemento.generico-botao.salvar',
        }

        this.notificationService.showConfirm(params).then((result) => {
            if (result.value)
                this.pecaEditarAcao(peca.item_id, orderId, result.value, peca.part_number, 0);
        });
    }

    private pecaEditarAcao(item_id: number, order_id: number, part_number: string, part_number_original: string, nAttempts: number) {
        this.ngxLoader.startLoader('loader-principal');

        const body: EditPartNumberPostModel = { part_number, part_number_original };

        this.curadorService.editPartNumber(order_id, item_id, body).subscribe({
            next: (response: ApiResponseModel<BuyerSemaforoPostAssociarPecaNFResponseModel>) => {
                if (response && response.success) {
                    this.pedido.inconsistencias = Object.assign([], response.result.inconsistencias);
                    this.pedido.inconsistencia_tem = response.result.inconsistencias && response.result.inconsistencias.length > 0;
                    this.inconsistenciasOrdenadas = this.pedido.inconsistencias.sort(sort_by([{ name: 'is_tipo_peca' }, { name: 'texto' }]));

                    let peca = this.pedido.pecas.find(p => p.item_id === item_id);
                    peca.part_number = part_number;
                    peca.part_number_nf = part_number;
                    peca.order_info_nota_fiscal_id = 'ASSOCIADO';
                    peca.nome = `${part_number} - ${peca.part_name}`;

                    this.notificationService.showSuccessToastr(this.globals.translate('buyer.curador.inconsistencias.mensagens.sucesso.editar.corpo'));
                    this.ngxLoader.stopLoader('loader-principal');
                    return;
                }
                else {
                    this.notificationService.showErrorToastr(this.globals.translate('buyer.curador.inconsistencias.mensagens.erro.editar.corpo-partnumber-nao-encontrado'));
                    this.ngxLoader.stopLoader('loader-principal');
                    return;
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.notificationService.showErrorToastr(this.globals.translate('buyer.curador.inconsistencias.mensagens.erro.editar.corpo'));
                    this.ngxLoader.stopLoader('loader-principal');
                    return;
                }

                this.pecaEditarAcao(item_id, order_id, part_number, part_number_original, ++nAttempts);
            }
        });
    }

    goToPedido($event, adicionar_complemento: boolean = false, editar_peca: boolean = false) {
        $event.stopPropagation();

        let func_ver = () => {
            $('.multi-usuario').val(this.pedido.buyer_id);
            let state = adicionar_complemento ? { state: { adicionar_complemento: true, quotation_id: this.pedido.quotation_id } } : {};
            this.router.navigate(['/comprador/visao-geral/pedido/' + this.pedido.transaction_id], state);
        };

        // Se o usuário local logado for diferente do usuário selecionado, faz a troca
        if (this.currentLocalUser.id !== this.pedido.buyer_id)
            this.accountService.trocarUsuario(this.pedido.buyer_id, this.currentUser, func_ver);
        else
            func_ver();
    }

    goToSemaforo($event) {
        if ($event)
            $event.stopPropagation();

        this.router.navigateByUrl('/comprador/curador');
    }

    resolver() {
        let params: SweetAlertParams = {
            title: 'buyer.curador.inconsistencias.resolver.subtitulo',
            input: 'textarea',
            inputPlaceholder: 'buyer.curador.inconsistencias.resolver.placeholder',
            inputValidator: (value) => {
                return new Promise((resolve) => {
                    if (!isEmpty(value))
                        resolve('');
                    else
                        resolve(this.globals.translate('buyer.curador.inconsistencias.resolver.obrigatorio'));
                })
            },
            icon: 'warning',
            cancelButtonText: 'elemento.generico-botao.fechar',
            confirmButtonText: 'buyer.curador.inconsistencias.resolver.botao-resolver',
        }

        this.notificationService.showConfirm(params).then((result) => {
            if (result.value) {
                this.ngxLoader.startLoader('loader-principal');
                this.resolverService(result.value, 0);
            }
        });
    }

    private resolverService(motivo: string, nAttempts: number) {
        this.curadorService.markOrderInconsistencesAsSolved(this.pedido.order_id, this.inconsistenciasSelecionadas, motivo).subscribe({
            next: (response: ApiResponseModel<{ success: boolean, hasInconsistencies: boolean }>) => {
                if (response && response.success) {
                    this.notificationService.showSuccessToastr(this.globals.translate('buyer.curador.inconsistencias.mensagens.sucesso.resolver.corpo'));
                    this.ngxLoader.stopLoader('loader-principal');

                    if (!response.result.hasInconsistencies)
                        this.router.navigateByUrl('/comprador/curador');
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.notificationService.showErrorToastr(this.globals.translate('buyer.curador.inconsistencias.mensagens.erro.resolver.corpo'));
                    this.ngxLoader.stopLoader('loader-principal');
                    return;
                }

                this.resolverService(motivo, ++nAttempts);
            }
        });
    }

    salvarTransportadora($event: any) {
        const dadosTransportadora = {
            shipping_company_id: $event.shipping_company_id,
            shipping_company_name: $event.shipping_company_name,
            shipping_company_tracking_code: $event.shipping_company_tracking_code,
            nota_fiscal: $event.nota_fiscal
        }

        this.salvarTransportadoraExecucao(this.pedido.order_id, dadosTransportadora, 0);
    }

    salvarTransportadoraExecucao(orderId: number, dadosTransportadora: any, nAttempts: number)
    {
        this.curadorService.setShippingCompany(orderId, dadosTransportadora)
            .subscribe({
                next: () => {
                    this.notificationService.showSuccessToastr(this.globals.translate('componente.nota-fiscal.mensagens.sucesso.salvar-transportadora.corpo'));

                    const index = this.pedido.notasFiscais.findIndex(nf => nf.numero === dadosTransportadora.nota_fiscal);
                    let item = Object.assign({}, this.pedido.notasFiscais[index]);
                    item.shipping_company_id = dadosTransportadora.shipping_company_id;
                    item.shipping_company_name = dadosTransportadora.shipping_company_name;
                    item.shipping_company_tracking_code = dadosTransportadora.shipping_company_tracking_code;
                    this.pedido.notasFiscais.splice(index, 1, item);

                    this.nfService.setPedidoNotaFiscal(this.pedido);
                },
                error: error => {
                    nAttempts = nAttempts || 1;
                    console.log(error, nAttempts);

                    if (nAttempts >= 5) {
                        this.notificationService.showErrorToastr(this.globals.translate('componente.nota-fiscal.mensagens.erro.salvar-transportadora.corpo'));
                        this.ngxLoader.stopLoader('loader-principal');
                        return;
                    }

                    this.salvarTransportadoraExecucao(orderId, dadosTransportadora, ++nAttempts);
                }
            });
    }

    selecionarInconsistencia(inconsistencia: CentralSemaforoCotacaoInconsistencia, $event)
    {
        if ($($event.target).is(":checked"))
        {
            this.inconsistenciasSelecionadas.push(inconsistencia.id);
        }
        else
        {
            this.inconsistenciasSelecionadas = this.inconsistenciasSelecionadas.filter(p => p != inconsistencia.id)
        }
    }

    selecionarNota(nota)
    {
        this.pdfSrc = nota.url;
        this.visaoPDF = true;
    }

    selecionarTodasInconsistencias($event)
    {
        let is_checked = $($event.target).is(":checked");

        this.pedido.inconsistencias.filter(i => !i.is_tipo_peca).forEach(inconsistencia =>
        {
            if (is_checked)
            {
                this.inconsistenciasSelecionadas.push(inconsistencia.id);
            }
            else
            {
                this.inconsistenciasSelecionadas = this.inconsistenciasSelecionadas.filter(p => p != inconsistencia.id)
            }
        });

        $('.inconsistencia_notificacao').prop("checked", is_checked);
    }


    get temInconsistenciaSomentePedido()
    {
        return this.pedido.inconsistencias.some(i => !i.is_tipo_peca);
    }

    openAdicionarComplemento($event, order_id: number) {
        $event.stopPropagation();

        this.ngxLoader.startLoader('loader-principal');

        this.curadorService.getAddComplementData(order_id).subscribe({
            next: (response: ApiResponseModel<{ addComplementData: AddComplementDataModel, deliveryTimes: any[], holidays: string[] }>) => {
                if (response && response.success) {
                    this.addComplementData = response.result.addComplementData;

                    this.addComplementData.chosen_date = getJavascriptDateFromIsoDate(response.result.addComplementData.chosen_date);
                    this.addComplementData.confirmation_date = getJavascriptDateFromIsoDate(response.result.addComplementData.confirmation_date);

                    this.deliveryTimes = response.result.deliveryTimes;
                    this.feriados = response.result.holidays;
                    this.showAdicionarComplementoModal = true;

                    this.ngxLoader.stopLoader('loader-principal');
                }
            },
            error: error => {
                this.notificationService.showErrorToastr(this.globals.translate('buyer.curador.inconsistencias.mensagens.erro.resolver.corpo'));
                this.ngxLoader.stopLoader('loader-principal');
                return;
            }
        });
    }

    openMergeOrderModal() {
        this.modalMergeOrderComponent.open(this.pedido.order_id);
    }

    checkDisableMergeOrdersButton(nAttempts: number) {
        this.curadorService.existsPossibleOrdersToMerge(this.pedido.order_id).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                if (response != null) {
                    this.disableMergeOrdersButton = !response.result;
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.notificationService.showErrorToastr(this.globals.translate('buyer.curador.inconsistencias.mensagens.erro.buscar-dados-nf.corpo'));
                    this.ngxLoader.stopLoader('loader-principal');
                    return;
                }

                this.checkDisableMergeOrdersButton(++nAttempts);
            }
        });
    }
}
