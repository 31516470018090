import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MasterPageComponent } from '../general/master-page/master-page.component';

import { BuyerAvaliacoesComponent } from './avaliacoes/avaliacoes.component';
import { BuyerCondicoesComponent } from './condicoes/condicoes.component';
import { BuyerConvidarComponent } from './convidar/convidar.component';
import { BuyerDashboardComponent } from './dashboard/dashboard.component';
import { BuyerHistoricoComponent } from './historico/historico.component';
import { BuyerMeusVendedoresComponent } from './meus-vendedores/meus-vendedores.component';
import { BuyerMercadoPartsfyListComponent } from './mercado-partsfy/list/mercado-partsfy-list.component';
import { BuyerNovaCotacaoComponent } from './novacotacao/novacotacao.component';
import { BuyerPecasCanceladasComponent } from './_componentes/pecas-canceladas/pecas-canceladas.component';
import { BuyerPedidosComponent } from './pedidos/pedidos.component';
import { BuyerSemaforoConversorComponent } from './semaforo-conversor/semaforo-conversor.component';
import { BuyerSemaforoCuradorComponent } from './semaforo-curador/semaforo-curador.component';
import { BuyerSemaforoCuradorListaInconsistenciasComponent } from './semaforo-curador/_componentes/lista-inconsistencias/lista-inconsistencias.component';
import { BuyerVisaoGeralDetalheComponent } from './detalhe/detalhe.component';
import { BuyerVisaoGeralComprarComponent } from './comprar/comprar.component';
import { BuyerVendedoresConvidadosComponent } from './vendedores-convidados/vendedores-convidados.component';
import { RepairerManagerCashbackComponent } from '../repairer-manager/cashback/cashback.component';

import { BuyerSemaforoCuradorListaInconsistenciasResolve } from './semaforo-curador/_services/inconsistencia.resolve';

const buyerRouterConfig: Routes = [
    {
        path: '', component: MasterPageComponent,
        children: [
            { path: 'conversor', component: BuyerSemaforoConversorComponent },
            { path: 'curador', component: BuyerSemaforoCuradorComponent },
            {
                path: 'curador/inconsistencias/:id',
                component: BuyerSemaforoCuradorListaInconsistenciasComponent,
                resolve: { pedido: BuyerSemaforoCuradorListaInconsistenciasResolve }
            },
            { path: 'visao-geral', component: BuyerDashboardComponent },
            { path: 'visao-geral/comprar/:id', component: BuyerVisaoGeralComprarComponent },
            { path: 'visao-geral/pedido/:id', component: BuyerVisaoGeralDetalheComponent },
            { path: 'visao-geral/pedido/:id/condicoes', component: BuyerCondicoesComponent },
            { path: 'visao-geral/nova-cotacao', component: BuyerNovaCotacaoComponent },
            { path: 'avaliacoes', component: BuyerAvaliacoesComponent },
            { path: 'cashback', component: RepairerManagerCashbackComponent },
            { path: 'convidar-vendedor', component: BuyerConvidarComponent },
            { path: 'vendedores-convidados', component: BuyerVendedoresConvidadosComponent },
            { path: 'historico', component: BuyerHistoricoComponent },

            //Se categoria vier vazio, redireciona para uma categoria existente dentro do componente
            { path: 'mercado', component: BuyerMercadoPartsfyListComponent, pathMatch: 'full' },
            { path: 'mercado/:category', component: BuyerMercadoPartsfyListComponent },

            { path: 'meus-vendedores', component: BuyerMeusVendedoresComponent },
            { path: 'pedidos', component: BuyerPedidosComponent },
            { path: 'pecas-canceladas/:id', component: BuyerPecasCanceladasComponent }
        ]
    }
];

@NgModule({
    imports:
        [
            RouterModule.forChild(buyerRouterConfig)
        ],
    exports: [RouterModule]
})
export class BuyerRoutingModule { }

