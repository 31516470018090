<div class="shopping-cart-part-description holdings" (click)="select(holding)">
    <div>
        <p class="holding-name">{{holding.name}}</p>
        <p class="holding-quantity"> {{ 'buyer.mercado-partsfy.carrinho.varios-vendedores.produtos' | translate }}<span>{{holdingQuantity}}</span></p>
    </div>
    <div>
        @if (holding.delivery_price > 0) {
            <p class="delivery-infos" [innerHTML]="'buyer.mercado-partsfy.carrinho.varios-vendedores.previsao-entrega.pagante' | translate: { date: formatDate(holding.delivery_time), price: (holding.delivery_price | currency:'R$') }"></p>
        } @else {
            <p class="delivery-infos free-delivery" [innerHTML]="'buyer.mercado-partsfy.carrinho.varios-vendedores.previsao-entrega.gratis' | translate: { date: formatDate(holding.delivery_time) }"></p>
        }
    </div>
    <div class="prices-info">
        <p class="holding-price">{{holdingPrice | currency: 'R$' }}</p>
        @if (holdingCashback) {
            <p class="cashback">
                <i-feather name="rotate-ccw"></i-feather>
                {{holdingCashback | currency: 'R$' }}
            </p>
        }
    </div>
</div>
