import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { NgScrollbar } from 'ngx-scrollbar';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { CentralSemaforoCotacao } from './_models/cotacao';
import { Comentario } from '../_models/comentario';
import { FilterOption } from 'app/_componentes/header/_models/filterOption';
import { Globals } from 'app/_models/globals';
import { GoogleAnalyticsEventNames } from 'app/_models/googleAnalytics';
import { LocalStorageUtils } from 'app/_utils/localstorage';
import { LocalStorageUtilsBuyer } from '../_utils/localstoragebuyer';
import { StatusIdEnum } from 'app/_models/statusId';
import { SweetAlertParams } from 'app/_models/notificationParams.model';

import { inserirCotacaoNaLista } from 'app/_utils/general-utils';

import { AccountService } from 'app/account/account.service';
import { BuyerSemaforoCuradorService } from '../semaforo-curador/semaforo.service';
import { BuyerSignalRService } from '../_services/buyer.signalr.service';
import { BuyerPurchaseService } from '../comprar/comprar.service';
import { GeneralService } from 'app/_services/general.service';
import { NotificationService } from 'app/_services/notification.service';
import { Subscription } from 'rxjs';

declare const $: any;
declare const compararStringContemString: any;
declare const formatDateToDDMMYYYYHHMM: any;
declare const getJavascriptDateFromCSharpDate: any;
declare const isEmpty: any;
declare const sort_by: any;

@Component({ selector: 'app-semaforo-conversor', templateUrl: './semaforo-conversor.component.html', styleUrls: ['./semaforo-conversor.component.scss'] })
export class BuyerSemaforoConversorComponent implements OnInit, OnDestroy
{
    @ViewChild('scrollable') scrollable: NgScrollbar;

    private subscriptionAtualizarSemaforoCotacaoEvent: Subscription;
    private subscriptionAtualizarSemaforoDataAtualizacaoEvent: Subscription;

    private localStorageUtils = new LocalStorageUtils();
    private localStorageUtilsBuyer = new LocalStorageUtilsBuyer();
    public googleAnalyticsEventNames = new GoogleAnalyticsEventNames();

    inserirCotacaoNaLista: any = inserirCotacaoNaLista;

    _searchString: string;
    _searchFase: string;

    filterOptions: FilterOption[] = [];

    cotacoes: CentralSemaforoCotacao[];
    cotacoesFiltradas: CentralSemaforoCotacao[];
    ultimaAtualizacao: Date;

    ordenar_por: string;
    reverse: boolean;

    window: any;

    listItemHeightCentral: number;

    get StatusIdEnum(): typeof StatusIdEnum {
        return StatusIdEnum;
    }

    constructor(private accountService: AccountService,
        private buyerSignalRService: BuyerSignalRService,
        private generalService: GeneralService, private semaforoService: BuyerSemaforoCuradorService, private buyerVisaoGeralComprarService: BuyerPurchaseService,
        private notificationService: NotificationService, private globals: Globals, private router: Router, private ngxLoader: NgxUiLoaderService, private ngZone: NgZone) { }

    ngOnInit(): void {
        this.registerObservables();

        this.cotacoes = [];
        let dadosLS = this.localStorageUtilsBuyer.obterDadosCentralSemaforo(true);

        if (!isEmpty(dadosLS) && this.localStorageUtilsBuyer.checkIfIsActive(dadosLS)) {
            this._searchString = isEmpty(dadosLS.filtro.texto) ? undefined : dadosLS.filtro.texto;
            this._searchFase = isEmpty(dadosLS.filtro.fase) ? undefined : dadosLS.filtro.fase;
        }

        this.filterOptions = [
            { value: '0', label: this.globals.translate('buyer.semaforo.cabecalho.filtro-fase.todos') },
            { value: '1', label: this.globals.translate('buyer.semaforo.cabecalho.filtro-fase.em-dia') },
            { value: '2', label: this.globals.translate('buyer.semaforo.cabecalho.filtro-fase.alerta') },
            { value: '3', label: this.globals.translate('buyer.semaforo.cabecalho.filtro-fase.atrasada') },
        ]

        this.carregarTela(false, false, 0);

        window['angularComponentReference'] = { component: this, zone: this.ngZone, addComment: (quotation_id: number) => this.ver_comentarios_adicionar(quotation_id), };

        this.listItemHeightCentral = this.semaforoService.listItemHeightCentral;
    }

    ngOnDestroy(): void {
        this.subscriptionAtualizarSemaforoCotacaoEvent.unsubscribe();
        this.subscriptionAtualizarSemaforoDataAtualizacaoEvent.unsubscribe();
    }

    atualizarCotacoes(salvar_estado: boolean, reordenar: boolean = true) {
        if (reordenar)
            this.ordenar(this.cotacoes);

        this.cotacoesFiltradas = this.filtrarCotacoes();

        if (salvar_estado)
            this.salvarEstadoTela();
    }

    carregarTela(ignorar_cache: boolean, ignorar_loading: boolean, nAttempts: number) {
        if (!ignorar_loading)
            this.ngxLoader.startLoader('loader-principal');

        this.semaforoService.listQuotations(true, ignorar_cache).subscribe({
            next: (response: any) => {
                if (response != null)
                {
                    this.ultimaAtualizacao = response.result.dataGravacao;
                    this.definirEstadoTela(response.result.quotations);
                }

                if (ignorar_cache)
                    this.scrollable.scrollTo({ top: 0 })

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                // if (nAttempts >= 5)
                // {
                    this.ngxLoader.stopLoader('loader-principal');
                    //this.notificationService.showErrorToastr(this.globals.translate('buyer.semaforo.mensagens.erro.carregamento.corpo'));
                    return;
                // }

                // this.carregarTela(ignorar_cache, ignorar_loading, ++nAttempts);
            }
        });
    }

    definirEstadoTela(cotacoes: CentralSemaforoCotacao[]) {
        this.cotacoes = cotacoes;

        this.ordenar_por = "data_abertura";
        this.reverse = false;

        this.atualizarCotacoes(true);
    }

    filtrarCotacoes() {
        if (this.searchTerm === undefined)
            this._searchString = '';

        if (this.searchType === undefined)
            this._searchFase = '0';

        if (!this.cotacoes)
            return this.cotacoes;

        let term: string = this.searchTerm.toLowerCase().replace('-', '');
        let filtro_fase: any = [1, 2, 3];

        switch (this.searchType) {
            case '1': // somente em dia
                filtro_fase = [1];
                break;
            case '2': // somente alerta
                filtro_fase = [2];
                break;
            case '3': // somente atrasadas
                filtro_fase = [3];
                break;
        }

        var result = this.cotacoes.filter(cotacao =>
            filtro_fase.some(t => t === cotacao.semaforo) &&
            (
                cotacao.quotation_id.toString().indexOf(term) !== -1 ||
                (!isEmpty(cotacao.veiculo) && compararStringContemString(cotacao.veiculo.replace('-', ''), term)) ||
                compararStringContemString(cotacao.marca, term) ||
                compararStringContemString(cotacao.oficina, term) ||

                (
                    cotacao.semaforo_cor.toLowerCase() === 'warning' && 'laranja'.indexOf(term) !== -1 ||
                    cotacao.semaforo_cor.toLowerCase() === 'danger' && 'vermelho'.indexOf(term) !== -1 ||
                    cotacao.semaforo_cor.toLowerCase() === 'success' && 'verde'.indexOf(term) !== -1
                )
            )
        );

        return result;
    }

    ordenar(cotacoes: any[]) {
        cotacoes.sort(sort_by([{ name: this.ordenar_por, reverse: this.reverse }, { name: 'data_abertura' }, { name: 'data_compra' }, { name: 'data_entrega' }, { name: 'vehicle' }, { name: 'oficina' }, { name: 'quotation_id' }]));
    }

    ordenar_atualizar(coluna) {
        if (this.ordenar_por === coluna)
            this.reverse = !this.reverse;
        else {
            this.ordenar_por = coluna;
            this.reverse = false;
        }

        this.atualizarCotacoes(false);
    }

    salvarEstadoTela() {
        this.localStorageUtilsBuyer.salvarDadosCentralSemaforo({ fase: this.searchType, inconsistencias: false, link_externo: false, aguardando_nota: false,texto: this.searchTerm }, true);
    }

    signalRAtualizarCotacao(cotacao: any) {
        if (this.buyerSignalRService.enableSignalRLog)
            console.log('BuyerSemaforoConversorComponent.signalRAtualizarCotacao', cotacao);

        if(cotacao.order_id != 0) return

        let index = this.cotacoes.findIndex(q => q.quotation_id === cotacao.quotation_id);

        if(cotacao.status_id > 0 && index < 0) return

        if((cotacao.is_active == false || cotacao.status_id > 0) && index >= 0) {
            this.cotacoes.splice(index, 1);
            this.atualizarCotacoes(false);
            return;
        }

        if(cotacao.comentarios) {
            cotacao.comentarios.forEach(c => c.data = getJavascriptDateFromCSharpDate(c.data));
        }

        this.inserirCotacaoNaLista(this.cotacoes, cotacao, index, (cotacoes) => this.ordenar(cotacoes), () => this.atualizarCotacoes(false, false));
    }

    ver_comentarios_adicionar(quotation_id: number) {
        let texto = $('.novo-comentario').val();

        if (!texto || texto.trim().length === 0)
            return;

        const quotation = this.cotacoes.find(c => c.quotation_id === quotation_id);
        const status_id = quotation.status_id;

        const abrev = this.globals.translate(`geral.fase-cotacao.abreviacao.${status_id}`);
        const comentario = `${abrev} - ${texto}`;

        let usuario_nome = this.localStorageUtils.obterUsuario().name;
        let dataFormatada = formatDateToDDMMYYYYHHMM(new Date(), '/');
        let novoComentario: Comentario = { quotation_id: quotation_id, data: new Date(), comentario, usuario: { userCompanyId: this.localStorageUtils.obterUsuario().user_company_id, nome: usuario_nome } };

        let htmlLinhaComentario = this.ver_comentarios_adicionar_linha(usuario_nome, comentario, dataFormatada);

        $('.lista-comentarios').prepend(htmlLinhaComentario);

        $('.novo-comentario').val("");

        this.buyerVisaoGeralComprarService.addComment(novoComentario).subscribe({
            next: () => {
                let cotacaoSelecionada = this.cotacoes.find(c => c.quotation_id === quotation_id);

                if (cotacaoSelecionada) {
                    cotacaoSelecionada.comentarios_tem = true;
                    //cotacaoSelecionada.comentarios.push(novoComentario);
                }
            },
            error: () => { }
        });
    }

    ver_comentarios_adicionar_linha(nome_usuario: string, comentario: string, data: string) : string
    {
        var html = '';

        html += "<div class=\"linha-comentario\">";
        html += "   <div class=\"linha\">";
        html += "       <div class=\"col\">";
        html += `           <label>${nome_usuario} [${data}]</label>`;
        html += "       </div>";
        html += "   </div>";
        html += "   <div class=\"linha\">";
        html += "       <div class=\"col\">";
        html += `           <span>${comentario}</span>`;
        html += "       </div>";
        html += "   </div>";
        html += "</div>";

        return html;
    }

    ver_comentarios($event, cotacao) {
        $event.stopPropagation();

        var html = "<div class='col-md-12 comentarios'>";
        let titulo = this.globals.translate('buyer.semaforo.tabela.comentarios.titulo');

        html += "<div class=\"linha\">";
        html += "   <div class=\"col cabecalho\">";
        html += `       <label class="col-form-label">${titulo}:</label>`;
        html += "   </div>";
        html += "</div>";

        html += `<div class="linha-add-comentario">`;
        html += `   <div>`;
        html += `       <textarea class="form-control novo-comentario"></textarea>`;
        html += `   </div>`;
        html += `   <div>`;
        html += `       <button type="button" class="btn btn-primary btn-novo-comentario" onclick="callAngularFunction('addComment', ${cotacao.quotation_id});">${this.globals.translate('buyer.cotacao.area-comprar.modal-mais-detalhes.comentarios.botao-adicionar')}</button>`;
        html += `   </div>`;
        html += `</div>`;

        html += "<div class=\"lista-comentarios\">";

        const comentarios = cotacao.comentarios.sort(sort_by([{ 'name': 'data', reverse: true }]));

        for (var i = 0; i <= comentarios.length - 1; i++)
        {
            let comentario = comentarios[i];
            let time = new Date(comentario.data + 'Z').toLocaleTimeString();
            let data = new Date(comentario.data + 'Z').toLocaleDateString() + ' ' + time.substring(0, time.length - 3);

            html += this.ver_comentarios_adicionar_linha(comentario.usuario.nome, comentario.comentario, data);
        }

        html += "</div>";
        html += "</div>";

        let params: SweetAlertParams = {
            title: cotacao.veiculo,
            notTranslateTitle: true,
            message: html,
            isHtml: true,
            icon: 'info',
        }

        this.notificationService.showInfoWithoutButtons(params);
    }

    visualizar(event: MouseEvent, cotacao: CentralSemaforoCotacao)
    {
        let func_ver = () =>
        {
            $('.multi-usuario').val(cotacao.buyer_id);

               let isCtrlKey = event.ctrlKey;

                if (cotacao.status_id === StatusIdEnum.Cotacao) {
                    let buyerRoute = '/comprador/visao-geral/comprar/' + cotacao.quotation_id;

                    if (isCtrlKey)
                        window.open(buyerRoute, '_blank');
                    else
                        this.router.navigate([buyerRoute]);
                }
                else {
                    let transactionRoute = '/comprador/visao-geral/pedido/' + cotacao.transaction_id;

                    if (isCtrlKey)
                        window.open(transactionRoute, '_blank');
                    else
                        this.router.navigate([transactionRoute]);
                }
        };

        // Se o usuário local logado for diferente do usuário selecionado, faz a troca
        if (this.localStorageUtils.obterUsuarioLocal().id !== cotacao.buyer_id) {
            this.generalService.closeQuotations()
            this.accountService.trocarUsuario(cotacao.buyer_id, this.localStorageUtils.obterUsuario(), func_ver);
        } else
            func_ver();
    }

    get searchTerm(): string
    {
        return this._searchString;
    }

    set searchTerm(value: string)
    {
        this._searchString = value;
        this.atualizarCotacoes(false);
    }

    get searchType(): string
    {
        return this._searchFase;
    }

    set searchType(value: string)
    {
        this._searchFase = value;
        this.atualizarCotacoes(true);
    }

    registerObservables() {
        this.subscriptionAtualizarSemaforoCotacaoEvent = this.buyerSignalRService.AtualizarSemaforoCotacaoEvent.subscribe(data => {
            if (this.buyerSignalRService.enableSignalRLog)
                console.log('BuyerSemaforoConversorComponent.AtualizarSemaforoCotacaoEvent', data);

            let cotacoes = JSON.parse(data);

            cotacoes.forEach(cotacao => {
                this.signalRAtualizarCotacao(cotacao);
            });
        });

        this.subscriptionAtualizarSemaforoDataAtualizacaoEvent = this.buyerSignalRService.AtualizarSemaforoDataAtualizacaoEvent.subscribe(data => {
            if (this.buyerSignalRService.enableSignalRLog)
                console.log('BuyerSemaforoConversorComponent.AtualizarSemaforoDataAtualizacaoEvent', data);

            let response = JSON.parse(data);
            this.ultimaAtualizacao = response.ultimaAtualizacao;
        });
    }
}
