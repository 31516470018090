import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Carrinho } from '../../../../_models/Carrinho';
import { ItemCarrinho } from '../../../../_models/ItemCarrinho';
import { ItemPorHolding } from '../../../../_models/ItemPorHolding';

import { BuyerMercadoPartsfyListService } from '../../../../mercado-partsfy-list.service';
import { SettingsService } from 'app/_services/settings.service';
import { RelationshipsList } from 'app/buyer/mercado-partsfy/list/_models/RelationshipsList';

declare const $: any;

@Component({ selector: 'app-selected-holding', templateUrl: './selected-holding.component.html', styleUrls: ['./selected-holding.component.scss'] })
export class BuyerMercadoPartsfyListShoppingCartSelectedHoldingComponent {
    @Input() companyRelationship: RelationshipsList;
    @Input() item: ItemCarrinho;
    @Input() shoppingCart: Carrinho;
    @Input() selectedHolding: ItemPorHolding;

    @Output() backToHoldings = new EventEmitter<any>();
    @Output() saveCart = new EventEmitter<any>();
    @Output() updateItemsInfo = new EventEmitter<any>();

    public cashbackValue: number;

    constructor(private buyerMercadoPartsfyListService: BuyerMercadoPartsfyListService, public settingService: SettingsService) {}

    ngOnInit() {
        this.updateCashbackValue()
    }

    updateCashbackValue() {
        this.cashbackValue = this.buyerMercadoPartsfyListService.getCashbackValue(this.item.part, this.item.quantity)
    }

    updatePartQuantity($event: any, part_id: string) {
        const quantity = $event.target.value;

        this.selectedHolding.items.map((item) => {
            item.part.partId === part_id ? (
                item.quantity = this.validatePartQuantity(Number(quantity)),
                $('input[name="' + part_id + '"]').val(item.quantity)
            ) : null
        })

        this.updateShipping()
        this.updateCashbackValue()

        this.updateItemsInfo.emit(this.selectedHolding)

        this.saveCart.emit(this.shoppingCart)
    }

    updateShipping() {
        let relationship = this.companyRelationship.fretes.find((frete) => frete.holding_id === this.item.part.holdingId)

        this.selectedHolding.delivery_price =
            this.selectedHolding.items.reduce((total, item) => {
                return total += item.quantity * item.part.priceWithDiscount
            }, 0) < relationship.min_value ? relationship.shipping_cost : 0
    }

    removeItemFromCart(part_id: string) {
        this.selectedHolding.items.filter(item => {
            if (item.part.partId === part_id) {
                this.selectedHolding.items.splice(this.selectedHolding.items.indexOf(item), 1)
                return true
            }
            return false
        })

        if(this.selectedHolding.items.length == 0) {
            this.shoppingCart.itemsPerHolding.splice(this.shoppingCart.itemsPerHolding.indexOf(this.selectedHolding), 1)

            this.saveCart.emit(this.shoppingCart)
            this.backToHoldings.emit()

            return
        }

        this.updateShipping()

        this.updateItemsInfo.emit(this.selectedHolding)

        this.saveCart.emit(this.shoppingCart)
    }

    validatePartQuantity(quantity: number) {
        if(quantity <= 0) return 1
        if(quantity > 999) return 999

        return quantity
    }
}
