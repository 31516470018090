@if (inconsistenciasSelecionadas) {
    <div class="curador-inconsistencias" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
        <app-header
            [title]="('buyer.curador.inconsistencias.titulo' | translate) + ' ' + pedido?.marca + ' - ' + pedido?.veiculo + ' - ' + pedido?.oficina"
            (onGoBack)="goToSemaforo($event)"
        >
            <input class="btn btn-light mt0 col" value="{{ 'buyer.curador.inconsistencias.botao.ver-pedido' | translate}}" (click)="goToPedido($event)" />
        </app-header>
        <div class="card card-default d-flex flex-wrap">
            <div class="col-md-6">
                <div ngbAccordion accordion="NgbAccordion" class="ngbAccordion" [closeOthers]="true" (hide)="accordionChange($event)" (show)="accordionShow($event)">
                    <div [ngbAccordionItem]="accordionInfos.inconsistencia.id" [collapsed]="accordionInfos.inconsistencia.collapsed">
                        <div ngbAccordionHeader>
                            <button ngbAccordionButton>
                                {{ 'buyer.curador.inconsistencias.panel.titulo.inconsistencia' | translate}}
                            </button>
                        </div>
                        <div ngbAccordionCollapse>
                            <section ngbAccordionBody class="d-flex flex-wrap striped bordered inconsistencia">
                                <ng-template>
                                    <header>
                                        <div class="col-0-5">
                                            @if (temInconsistenciaSomentePedido) {
                                                <div class="form-check c-checkbox needsclick checkboxcot">
                                                    <label class="needsclick">
                                                        <input type="checkbox" class="needsclick" (click)="selecionarTodasInconsistencias($event);">
                                                        <span class="fa fa-check"></span>
                                                    </label>
                                                </div>
                                            } @else {
                                                <div class="inconsistencia-sem-checkbox">
                                                    <i class="fa fa-transparent"></i>
                                                </div>
                                            }
                                        </div>
                                        <div class="col-5">{{ 'buyer.curador.inconsistencias.panel.inconsistencias.texto' | translate}}</div>
                                    </header>
                                    <ng-scrollbar class="corpo">
                                        @for (inconsistencia of pedido.inconsistencias; track inconsistencia) {
                                            <div class="linha">
                                                <div class="col-0-5">
                                                    @if (!inconsistencia.is_tipo_peca) {
                                                        <div class="form-check c-checkbox needsclick checkboxcot">
                                                            <label class="needsclick">
                                                                <input type="checkbox" class="needsclick inconsistencia_notificacao" (click)="selecionarInconsistencia(inconsistencia, $event);">
                                                                <span class="fa fa-check"></span>
                                                            </label>
                                                        </div>
                                                    } @else {
                                                        <div class="inconsistencia-sem-checkbox">
                                                            <i class="fa fa-transparent"></i>
                                                        </div>
                                                    }
                                                </div>
                                                <div class="col-5 truncate">{{inconsistencia.texto}}</div>
                                            </div>
                                        }
                                    </ng-scrollbar>
                                    <footer class="linha">
                                        <div class="linha">
                                            <div class="col">
                                                <button class="btn btn-sm btn-light" [disabled]="disableMergeOrdersButton" (click)="openMergeOrderModal()">{{ 'buyer.curador.juntar-pedidos.juntar-pedidos' | translate}}</button>
                                                &nbsp;&nbsp;
                                                <button class="btn btn-sm btn-light" [disabled]="inconsistenciasSelecionadas.length === 0 ? 'disabled' : null" (click)="resolver()">{{ 'buyer.curador.inconsistencias.panel.inconsistencias.resolver' | translate}}</button>
                                            </div>
                                        </div>
                                    </footer>
                                </ng-template>
                            </section>
                        </div>
                    </div>
                    <div [ngbAccordionItem]="accordionInfos.pecas.id" [collapsed]="accordionInfos.pecas.collapsed">
                        <div ngbAccordionHeader>
                            <button ngbAccordionButton>
                                {{ 'buyer.curador.inconsistencias.panel.titulo.peca' | translate}}
                            </button>
                        </div>
                        <div ngbAccordionCollapse>
                            <section ngbAccordionBody class="d-flex flex-wrap striped bordered">
                                <ng-template>
                                    <header>
                                        <div class="col-0-5"></div>
                                        <div class="col-2-5">{{ 'buyer.curador.inconsistencias.panel.pecas.nome' | translate}}</div>
                                        <div class="col">{{ 'buyer.curador.inconsistencias.panel.pecas.entrega' | translate}}</div>
                                        <div class="col-0-5">{{ 'buyer.curador.inconsistencias.panel.pecas.quantidade' | translate}}</div>
                                        <div class="col-1-5">{{ 'buyer.curador.inconsistencias.panel.pecas.seguradora-preco' | translate}}</div>
                                        <div class="col-1-5">{{ 'buyer.curador.inconsistencias.panel.pecas.vendedor-preco' | translate:{ system: ('geral.sistema' | translate) } }}</div>
                                        <div class="col">{{ 'buyer.curador.inconsistencias.panel.pecas.vendedor-total' | translate}}</div>
                                    </header>
                                    <ng-scrollbar class="corpo">
                                        @for (peca of pedido.pecas; track peca) {
                                            <div class="linha">
                                                <div class="col-0-5 acoes">
                                                    @if (peca.part_number !== peca.part_number_nf) {
                                                        <i tooltip="{{ 'buyer.curador.inconsistencias.editar-peca.editar.tooltip' | translate}}" class="fa fa-edit" (click)="pecaEditar(pedido.order_id, peca, $event);"></i>
                                                    }
                                                    @if (!peca.order_info_nota_fiscal_id) {
                                                        <i tooltip="{{ 'buyer.curador.inconsistencias.editar-peca.associar.tooltip' | translate}}" class="fa fa-link" (click)="pecaAssociarNF(pedido.order_id, peca, $event);"></i>
                                                    }
                                                </div>
                                                <div class="col-2-5 truncate">{{peca.nome}}</div>
                                                <div class="col">{{peca.data_entrega}}</div>
                                                <div class="col-0-5">{{peca.quantity}}</div>
                                                <div class="col-1-5 text-end">{{peca.preco_seguradora}}</div>
                                                <div class="col-1-5 text-end">{{peca.preco_vendedor}}</div>
                                                <div class="col text-end">{{peca.total | currency:' '}}</div>
                                            </div>
                                        }
                                    </ng-scrollbar>
                                    <footer class="linha">
                                        <div class="linha">
                                            <div class="col-4"><a href="javascript:void(0)" (click)="openAdicionarComplemento($event, pedido.order_id);">{{ 'buyer.curador.inconsistencias.editar-peca.adicionar' | translate}}</a></div>
                                            <div class="col">{{ 'buyer.curador.inconsistencias.panel.total-pedido' | translate}}: </div>
                                            <div class="col text-end">{{pedido?.total | currency:' '}}</div>
                                        </div>
                                    </footer>
                                </ng-template>
                            </section>
                        </div>
                    </div>
                    <div [ngbAccordionItem]="accordionInfos.vendedor.id" [collapsed]="accordionInfos.vendedor.collapsed">
                        <div ngbAccordionHeader>
                            <button ngbAccordionButton>
                                {{ 'buyer.curador.inconsistencias.panel.titulo.dados-vendedor' | translate}}
                            </button>
                        </div>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody class="row">
                                <ng-template>
                                    <div class="row">
                                        <div class="col-md-2 text-bold text-end">
                                            {{ 'buyer.curador.inconsistencias.panel.dados-vendedor.empresa' | translate}}:
                                        </div>
                                        <div class="col-md-10">
                                            {{pedido.fornecedor}}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-2 text-bold text-end">
                                            {{ 'buyer.curador.inconsistencias.panel.dados-vendedor.nome' | translate}}:
                                        </div>
                                        <div class="col-md-10">
                                            {{pedido.vendedor}}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-2 text-bold text-end">
                                            {{ 'buyer.curador.inconsistencias.panel.dados-vendedor.whatsapp.label' | translate}}:
                                        </div>
                                        <div class="col-md-10">
                                            @if (pedido.vendedor_whatsapp.length > 0) {
                                                <span>{{pedido.vendedor_whatsapp_formatado}}</span>
                                            } @else {
                                                <span class="text-italic">{{ 'buyer.curador.inconsistencias.panel.dados-vendedor.whatsapp.nao-tem' | translate}}</span>
                                            }
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 lista-notas-fiscais">
                @if (pedido.notasFiscais.length > 0) {
                    <app-nota-fiscal [pedido]="nfService.pedidosNotaFiscal$ | async" [is_central]="true" [is_seller]="false" [transportadoras]="transportadoras" (excluirNota)="excluirNota($event, pedido)" (salvarTransportadora)="salvarTransportadora($event)" ></app-nota-fiscal>
                }
            </div>
        </div>
    </div>
}
<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
<app-merge-order-modal #modalMergeOrderComponent></app-merge-order-modal>
<dados-pedido-adicionar-complemento [cotacaoSelecionada]="addComplementData" [deliveryTimes]="deliveryTimes" [feriados]="feriados" [visivel]="showAdicionarComplementoModal" (fechar)="closeAdicionarComplemento($event)"></dados-pedido-adicionar-complemento>
