import { NgModule } from '@angular/core';

import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { BuyerRoutingModule } from './buyer.route';
import { SharedModule } from '../_modules/shared.module';
import { FeatherModule } from 'angular-feather'

import { BuyerAdicionarComplementoComponent } from './comprar/_componentes/adicionar-complemento.component';
import { BuyerAvaliacoesComponent } from './avaliacoes/avaliacoes.component';
import { BuyerComprarCabecalhoRespostasComponent } from './comprar/_componentes/cabecalho-comprar/cabecalho-respostas.component';
import { BuyerComprarCheckoutModalComponent } from './comprar/_componentes/checkout-modal/checkout-modal.component';
import { BuyerComprarRespostaRodapeComponent } from './comprar/_componentes/resposta-rodape/resposta-rodape.component';
import { BuyerComprarRespostaAvaliacaoModalComponent } from './comprar/_componentes/resposta-avaliacao-modal/resposta-avaliacao-modal.component';
import { BuyerComprarVerMaisDetalhesModalComponent } from './comprar/_componentes/ver-mais-detalhes-modal/ver-mais-detalhes-modal.component';
import { BuyerCondicoesComponent } from './condicoes/condicoes.component';
import { BuyerCondicoesListaRespostasComponent } from './condicoes/_componentes/lista-respostas/lista-respostas.component';
import { BuyerConvidarComponent } from './convidar/convidar.component';
import { BuyerDadosConvitesComponent } from './_componentes/dados-convite/dados-convite.component';
import { BuyerDadosPedidoComponent } from './_componentes/dados-pedido/dados-pedido.component';
import { BuyerPedidoAdicionarComplementoComponent } from './_componentes/adicionar-complemento/adicionar-complemento.component';

import { BuyerDashboardComponent } from './dashboard/dashboard.component';
import { BuyerDashboardModalAvaliacaoComponent } from './dashboard/_components/modal-avaliacao/modal-avaliacao.component';
import { BuyerEditarCotacaoComponent } from './comprar/_componentes/editar-cotacao.component';
import { BuyerEditarPecaComponent } from './comprar/_componentes/editar-peca.component';
import { BuyerHistoricoComponent } from './historico/historico.component';
import { BuyerMeusVendedoresComponent } from './meus-vendedores/meus-vendedores.component';
import { BuyerMercadoPartsfyListComponent } from './mercado-partsfy/list/mercado-partsfy-list.component';
import { BuyerMercadoPartsfyListCardComponent } from './mercado-partsfy/list/_components/card/card.component';
import { BuyerMercadoPartsfyListFilterComponent } from './mercado-partsfy/list/_components/filter/filter.component';
import { BuyerMercadoPartsfyListPaginationComponent } from './mercado-partsfy/list/_components/pagination/pagination.component';
import { BuyerMercadoPartsfyListPartDetailsComponent } from './mercado-partsfy/list/_components/part-details/part-details.component';
import { BuyerMercadoPartsfyListShoppingCartComponent } from './mercado-partsfy/list/_components/shopping-cart/shopping-cart.component';
import { BuyerMercadoPartsfyListShoppingCartFooterComponent } from './mercado-partsfy/list/_components/shopping-cart/_components/footer/footer.component';
import { BuyerMercadoPartsfyListShoppingCartMultipleHoldingsComponent } from './mercado-partsfy/list/_components/shopping-cart/_components/multiple-holdings/multiple-holdings.component';
import { BuyerMercadoPartsfyListShoppingCartSelectedHoldingComponent } from './mercado-partsfy/list/_components/shopping-cart/_components/selected-holding/selected-holding.component';
import { BuyerNovaCotacaoComponent } from './novacotacao/novacotacao.component';
import { BuyerPedidosComponent } from './pedidos/pedidos.component';
import { BuyerSemaforoCuradorComponent } from './semaforo-curador/semaforo-curador.component';
import { BuyerSemaforoCuradorListaInconsistenciasComponent } from './semaforo-curador/_componentes/lista-inconsistencias/lista-inconsistencias.component';
import { BuyerSemaforoCuradorModalListaDevolucaoComponent } from './semaforo-curador/_componentes/modal-lista-devolucao/modal-lista-devolucao.component';
import { BuyerSemaforoConversorComponent } from './semaforo-conversor/semaforo-conversor.component';
import { BuyerVisaoGeralDetalheComponent } from './detalhe/detalhe.component';
import { BuyerVisaoGeralComprarComponent } from './comprar/comprar.component';
import { BuyerVendedoresConvidadosComponent } from './vendedores-convidados/vendedores-convidados.component';
import { BuyerSemaforoCuradorModalJuntarPedidoComponent } from './semaforo-curador/_componentes/juntar-pedido-modal/juntar-pedido-modal.component';
import { BuyerReviewService } from './avaliacoes/avaliacoes.service';
import { BuyerCondicoesService } from './condicoes/condicoes.service';
import { BuyerDadosConviteService } from './_componentes/dados-convite/dados-convite.service';
import { BuyerDadosPedidoService } from './_componentes/dados-pedido/dados-pedido.service';
import { BuyerDashboardService } from './dashboard/dashboard.service';
import { BuyerMeusVendedoresService } from './meus-vendedores/meus-vendedores.service';
import { BuyerNovaCotacaoService } from './novacotacao/novacotacao.service';
import { BuyerPedidosService } from './pedidos/pedidos.service';
import { BuyerVisaoGeralDetalheService } from './detalhe/detalhe.service';
import { BuyerPurchaseService } from './comprar/comprar.service';
import { BuyerSemaforoCuradorService } from './semaforo-curador/semaforo.service';
import { BuyerSignalRService } from './_services/buyer.signalr.service';
import { BuyerPecasCanceladasService } from './_componentes/pecas-canceladas/pecas-canceladas.service';
import { BuyerVendedoresConvidadosService } from './vendedores-convidados/vendedores-convidados.service';

import { BuyerSemaforoCuradorListaInconsistenciasResolve } from './semaforo-curador/_services/inconsistencia.resolve';

@NgModule({
    declarations: [
        BuyerAdicionarComplementoComponent,
        BuyerAvaliacoesComponent,
        BuyerComprarCabecalhoRespostasComponent,
        BuyerComprarCheckoutModalComponent,
        BuyerComprarRespostaRodapeComponent,
        BuyerComprarRespostaAvaliacaoModalComponent,
        BuyerComprarVerMaisDetalhesModalComponent,
        BuyerCondicoesComponent,
        BuyerCondicoesListaRespostasComponent,
        BuyerConvidarComponent,
        BuyerDadosConvitesComponent,
        BuyerDadosPedidoComponent,
        BuyerPedidoAdicionarComplementoComponent,
        BuyerDashboardComponent,
        BuyerDashboardModalAvaliacaoComponent,
        BuyerEditarCotacaoComponent,
        BuyerEditarPecaComponent,
        BuyerHistoricoComponent,
        BuyerMercadoPartsfyListComponent,
        BuyerMercadoPartsfyListCardComponent,
        BuyerMercadoPartsfyListFilterComponent,
        BuyerMercadoPartsfyListPaginationComponent,
        BuyerMercadoPartsfyListPartDetailsComponent,
        BuyerMercadoPartsfyListShoppingCartComponent,
        BuyerMercadoPartsfyListShoppingCartFooterComponent,
        BuyerMercadoPartsfyListShoppingCartMultipleHoldingsComponent,
        BuyerMercadoPartsfyListShoppingCartSelectedHoldingComponent,
        BuyerMeusVendedoresComponent,
        BuyerNovaCotacaoComponent,
        BuyerPedidosComponent,
        BuyerSemaforoConversorComponent,
        BuyerSemaforoCuradorComponent,
        BuyerSemaforoCuradorListaInconsistenciasComponent,
        BuyerSemaforoCuradorModalListaDevolucaoComponent,
        BuyerVisaoGeralDetalheComponent,
        BuyerVisaoGeralComprarComponent,
        BuyerVendedoresConvidadosComponent,
        BuyerSemaforoCuradorModalJuntarPedidoComponent
    ],
    imports: [
        BuyerRoutingModule,
        SharedModule,
        NgxMaskDirective,
        NgxMaskPipe
    ],
    providers: [
        BuyerReviewService,
        BuyerCondicoesService,
        BuyerDadosConviteService,
        BuyerDadosPedidoService,
        BuyerDashboardService,
        BuyerMeusVendedoresService,
        BuyerNovaCotacaoService,
        BuyerPedidosService,
        BuyerVisaoGeralDetalheService,
        BuyerPurchaseService,
        BuyerPecasCanceladasService,
        BuyerSemaforoCuradorService,
        BuyerSignalRService,
        BuyerVendedoresConvidadosService,
        BuyerSemaforoCuradorListaInconsistenciasResolve,
        provideNgxMask()
    ],
    exports: [
        FeatherModule
    ],
    bootstrap: []
})
export class BuyerModule { }
