import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ItemPorHolding } from '../../../../_models/ItemPorHolding';

import { BuyerMercadoPartsfyListService } from '../../../../mercado-partsfy-list.service';
import { SettingsService } from 'app/_services/settings.service';

declare const formatDateToDDMMYYYY: any;

@Component({ selector: 'app-multiple-holdings', templateUrl: './multiple-holdings.component.html', styleUrls: ['./multiple-holdings.component.scss'] })
export class BuyerMercadoPartsfyListShoppingCartMultipleHoldingsComponent implements OnInit {
    @Input() holding: ItemPorHolding;
    @Output() selectHolding = new EventEmitter<any>();

    holdingCashback: number
    holdingQuantity: number
    holdingPrice: number

    constructor(private buyerMercadoPartsfyListService: BuyerMercadoPartsfyListService, public settingService: SettingsService) {}

    ngOnInit() {
        this.setHoldingInfo()
    }

    formatDate(date: string) {
        return formatDateToDDMMYYYY(new Date(date));
    }

    setHoldingInfo() {
        this.holdingCashback = this.holding.items.reduce((total, item) => total + this.buyerMercadoPartsfyListService.getCashbackValue(item.part, item.quantity), 0)
        this.holdingQuantity = this.holding.items.reduce((total, item) => total + item.quantity, 0)
        this.holdingPrice = this.holding.items.reduce((total, item) => total + item.quantity * item.part.priceWithDiscount, 0)
    }

    select(holding: ItemPorHolding) {
        this.selectHolding.emit(holding)
    }
}
