import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgScrollbar } from 'ngx-scrollbar';

import { BuyerMercadoPartsfyListService } from './mercado-partsfy-list.service';

import { BuyerMercadoPartsfyListFilterComponent } from './_components/filter/filter.component';
import { BuyerMercadoPartsfyListPartDetailsComponent } from './_components/part-details/part-details.component';
import { BuyerMercadoPartsfyListShoppingCartComponent } from './_components/shopping-cart/shopping-cart.component';

import { ApiResponseModel } from '../../../_models/apiResponseModel';
import { Brand } from './_models/Brand';
import { Carrinho } from './_models/Carrinho';
import { Categoria } from './_models/Categoria';
import { FilterTypeEnum } from './_models/filter-type.enum';
import { GetAllPartsResponse } from './_models/GetAllPartsResponse';
import { LocalStorageUtilsBuyer } from '../../_utils/localstoragebuyer';
import { Peca } from './_models/Peca';
import { RelationshipsList } from './_models/RelationshipsList';

declare const $: any;
declare const isEmpty: any;
declare const sort_by: any;

@Component({ selector: 'app-mercado-partsfy', templateUrl: './mercado-partsfy-list.component.html', styleUrls: ['./mercado-partsfy-list.component.scss'] })
export class BuyerMercadoPartsfyListComponent {
    @ViewChild('filterComponent') private filterComponent!: BuyerMercadoPartsfyListFilterComponent;
    @ViewChild('templatePartDetails') private partDetailsModal: BuyerMercadoPartsfyListPartDetailsComponent;
    @ViewChild('templateShoppingCart') private shoppingCartModal: BuyerMercadoPartsfyListShoppingCartComponent;
    @ViewChild('scrollable') scrollable: NgScrollbar;

    categories: Categoria[] = [];
    companyAddress: string;
    companyRelationship: RelationshipsList;
    selectedPart: any;
    loading: boolean = false;
    maxInstallments: number
    montadoras: Brand[] = [];

    private localStorageUtilsBuyer = new LocalStorageUtilsBuyer;

    public selectedCategory: number;

    public parts: Peca[];

    public currentPage: number;
    public totalPages: number;
    public totalItems: number;

    public filterGlobal: any  = {
        montadora: '',
        page: ''
    }
    
    private _montadoraFilterName: string = FilterTypeEnum[FilterTypeEnum.Montadora].toLowerCase();

    public shoppingCart: Carrinho
    public shoppingCartTotalQuantity: number

    constructor(
        private buyerMercadoPartsfyListService: BuyerMercadoPartsfyListService,
        private ngxLoader: NgxUiLoaderService,
        private route: ActivatedRoute,
        private router: Router) {}

    ngOnInit() {
        let dadosLS = this.localStorageUtilsBuyer.getShoppingCartData();

        if (!isEmpty(dadosLS)) {
            this.shoppingCart = dadosLS.shoppingCart;

            this.setShoppingCartTotalQuantity(
                this.shoppingCart.itemsPerHolding.reduce((total, holding) => {
                    return total + holding.items.reduce((itemTotal, item) => {
                        return itemTotal + item.quantity
                    }, 0)
                }, 0)
            );
        } else {
            this.shoppingCart = {
                itemsPerHolding: [],
                companyAddress: ''
            }

            this.setShoppingCartTotalQuantity(0);
        }

        this.loadCategories();

        this.buyerMercadoPartsfyListService.getCompanyAddress().subscribe({
            next: (response: ApiResponseModel<{address: string}>) => {
                this.companyAddress = response.result.address;
            }
        })
    }

    public changePage(page: number) {
        this.loading = true;

        let montadoras = this.filterGlobal.montadora?.split(',')

        if (montadoras[0] == '') montadoras = []

        montadoras = montadoras.map(Number)

        this.buyerMercadoPartsfyListService.getParts(this.selectedCategory, false, montadoras, page).subscribe({
            next: (response: ApiResponseModel<GetAllPartsResponse>) => {
                this.parts = response.result.parts;
                this.currentPage = response.result.currentPage;
                this.totalItems = response.result.totalItems;
                this.totalPages = response.result.totalPages;

                $('.mercado-scrollable .ng-scroll-viewport').scrollTop(0);

                this.loading = false;
            },
            error: (err) => {
                this.loading = false;
                console.log(err);
            }
        });
    }

    public deleteShoppingCartItems() {
        this.shoppingCart.itemsPerHolding = [];

        this.localStorageUtilsBuyer.deleteLocalStorageShoppingCartData();
    }

    public filter({filterName, globalObject}: {filterName: string, globalObject: any[]}) {
        this.loading = true;

        if (filterName === this._montadoraFilterName) {
            this.filterPartsByMontadora(globalObject);
        }
    }

    public filterPartsByMontadora(ids: number[]) {
        this.buyerMercadoPartsfyListService.getParts(this.selectedCategory, false, ids).subscribe({
            next: (response: ApiResponseModel<GetAllPartsResponse>) => {
                this.parts = response.result.parts;
                this.currentPage = response.result.currentPage;
                this.totalItems = response.result.totalItems;
                this.totalPages = response.result.totalPages;

                $(`.fa-spinner[class*="${this._montadoraFilterName}_"]`).hide();
                this.loading = false;
            },
            error: (err) => {
                $(`.fa-spinner[class*="${this._montadoraFilterName}_"]`).hide();
                this.loading = false;
            }
        })
    }

    getRouteParams() {
        this.route.params.subscribe(routeParams => {
            this.ngxLoader.startLoader('loader-principal');

            if(routeParams.category == undefined)
                this.router.navigate(['/comprador/mercado', this.categories[0].name]);

            this.selectedCategory = this.categories.find(c => c.name === routeParams.category && c.isActive)?.categoryId;

            this.loadMontadoras(this.selectedCategory);

            this.route.queryParams.subscribe(params => {
                let filtros = Object.keys(params);
                let montadorasArray = [];
                let actualPage = undefined;

                this.filterGlobal = Object.assign(this.filterGlobal, params);

                filtros.forEach(filtro => {
                    if (filtro === this._montadoraFilterName) {
                        montadorasArray = params[filtro].split(',');
                    } else if (filtro == 'page') {
                        actualPage = Number(params[filtro]);
                    }
                });

                this.filterComponent.loadFilters();

                this.buyerMercadoPartsfyListService.getCompanyRelationship().subscribe({
                    next: (response: ApiResponseModel<{relationshipsList: RelationshipsList}>) => {
                        this.companyRelationship = response.result.relationshipsList;

                        this.buyerMercadoPartsfyListService.getParts(this.selectedCategory, false, montadorasArray, actualPage).subscribe({
                            next: (response: ApiResponseModel<GetAllPartsResponse>) => {
                                this.parts = response.result.parts;
                                this.currentPage = response.result.currentPage;
                                this.totalItems = response.result.totalItems;
                                this.totalPages = response.result.totalPages;

                                this.ngxLoader.stopLoader('loader-principal');
                            },
                            error: (error) => {
                                this.ngxLoader.stopLoader('loader-principal');
                            }
                        });
                    }
                })
            });
        })
    }

    public loadCategories() {
        this.ngxLoader.startLoader('loader-principal');

        this.buyerMercadoPartsfyListService.getCategories().subscribe({
            next: (response: ApiResponseModel<{categories: Categoria[]}>) => {
                this.categories = response.result.categories.sort(sort_by([{ name: 'name' }]));
                this.getRouteParams();
            },
            error: (error) => {
                console.log(error)
                this.ngxLoader.stopLoader('loader-principal');
            }
        })
    }

    public loadMontadoras(categoryId) {
        this.buyerMercadoPartsfyListService.getMontadoras(categoryId).subscribe({
            next: (response: ApiResponseModel<{brands: Brand[]}>) => {
                this.montadoras = response.result.brands

                this.montadoras.sort((a, b) =>  b.totalItems - a.totalItems );
            },
            error: (error) => {
                console.log(error)
            }
        });
    }

    public async openModal({ modalId, part, maxInstallments }: { modalId: number, part?: Peca, maxInstallments?: number }) {
        switch (modalId) {
            case 1:
                this.partDetailsModal.part = part
                this.partDetailsModal.installmentsNumber = maxInstallments
                await this.partDetailsModal.open()
                break;
            case 2:
                await this.shoppingCartModal.open()
                break;
        }
    }

    async saveShoppingCart(shoppingCart: Carrinho)
    {
        this.localStorageUtilsBuyer.saveShoppingCartData(shoppingCart);
    }

    public setShoppingCartTotalQuantity(quantity: number) {
        this.shoppingCartTotalQuantity = quantity
    }
}
