import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { CentralSemaforoCotacao } from '../../semaforo-conversor/_models/cotacao';
import { BuyerSemaforoCuradorService } from '../semaforo.service';

declare const isEmpty: any;

@Injectable()
export class BuyerSemaforoCuradorListaInconsistenciasResolve  {

    constructor(private curadorService: BuyerSemaforoCuradorService) { }

    resolve(route: ActivatedRouteSnapshot)
    {
        let quotation_id = parseInt(route.params['id']);
        let listaCotacoes = this.curadorService.listaCotacoes;

        let pedido = isEmpty(listaCotacoes) ? null : listaCotacoes.find(c => c.quotation_id === quotation_id);

        if (isEmpty(listaCotacoes) || isEmpty(pedido))
            return this.curadorService.obter(quotation_id, false);

        return pedido;
    }
}
