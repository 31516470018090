import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

import { BuyerMercadoPartsfyListService } from '../../mercado-partsfy-list.service';
import { NotificationService } from 'app/_services/notification.service';
import { SettingsService } from 'app/_services/settings.service';

import { Carrinho } from '../../_models/Carrinho';
import { Peca } from '../../_models/Peca';
import { RelationshipsList } from '../../_models/RelationshipsList';
import { ApiResponseModel } from 'app/_models/apiResponseModel';

declare const $: any;
declare const formatDateToDDMMYYYY: any;
declare const sort_by: any;

@Component({ selector: 'app-part-details', templateUrl: './part-details.component.html', styleUrls: ['./part-details.component.scss'] })
export class BuyerMercadoPartsfyListPartDetailsComponent
{
    @Input() companyAddress: string;
    @Input() companyRelationship: RelationshipsList;
    @Input() part: Peca;
    @Input() shoppingCart: Carrinho;
    @Output() saveCart = new EventEmitter<any>();
    @Output() setShoppingCartTotalQuantity = new EventEmitter<any>();
    @ViewChild('templatePartDetails') template: TemplateRef<any>;
    modalRef: BsModalRef;

    actualImage: string;
    actualQuantity: number = 1;
    cashbackValue: number;
    delivery_time_id: number;
    delivery_time: string;
    delivery_time_formatted: string;
    imagesList: { url: string, isActive: boolean }[];
    installmentsNumber: number;
    installmentsPrice: number;
    hasFreeDelivery: boolean = false;

    constructor(
        private modalService: BsModalService,
        private notificationService: NotificationService,
        public settingService: SettingsService,
        private buyerMercadoPartsfyListService: BuyerMercadoPartsfyListService)
    {}

    async close(): Promise<void> {
        this.modalRef.hide()
    }

    addToCart(part: Peca) {
        let quantity = Number($('input[name="part-quantity"]').val());

        this.shoppingCart.companyAddress = this.companyAddress;

        let holding = this.shoppingCart.itemsPerHolding.find((holding) => holding.id === part.holdingId)

        if(!holding) {
            this.shoppingCart.itemsPerHolding.push({
                id: part.holdingId,
                name: part.holdingName,
                delivery_time_id: this.delivery_time_id,
                delivery_time: this.delivery_time,
                delivery_price: this.hasFreeDelivery ? 0 : this.companyRelationship.fretes.find((frete) => frete.holding_id === part.holdingId).shipping_cost,
                items: []
            })

            holding = this.shoppingCart.itemsPerHolding.find((holding) => holding.id === part.holdingId)
        }

        if(holding.items.find((item) => item.part.partId === part.partId)){
            holding.items.map((item) => {
                item.part.partId === part.partId ? item.quantity += quantity : null
            })
        } else {
            holding.items.push({ part, quantity })
        }

        let relationship = this.companyRelationship.fretes.find((frete) => frete.holding_id === part.holdingId)

        holding.delivery_price =
            holding.items.reduce((total, item) => {
                return total += item.quantity * item.part.priceWithDiscount
            }, 0) < relationship.min_value ? relationship.shipping_cost : 0

        this.shoppingCart.itemsPerHolding.sort(sort_by([{ name: 'name' }]))

        this.close();

        let totalQuantity = this.shoppingCart.itemsPerHolding.reduce((total, holdings) => {
            return total + holdings.items.reduce((holdingTotal, items) => {
                return holdingTotal += items.quantity
            }, 0)
        }, 0)

        this.setShoppingCartTotalQuantity.emit(totalQuantity)

        this.saveCart.emit(this.shoppingCart)
        this.notificationService.showSuccessToastr('Peça adicionada ao carrinho com sucesso!')
    }

    changeImage(url: string) {
        this.imagesList.map((img) => {
            img.url === url ? (this.actualImage = img.url, img.isActive = true) : img.isActive = false;
        })
    }

    open(): Promise<boolean> {
        return new Promise<boolean>(() => {
            this.imagesList = this.part.urlImages.map((url) => {
                return { url: this.settingService.pathImagesMercadoPartsfy + url, isActive: false }
            })

            if(this.imagesList.length == 0) {
                this.imagesList = [{ url: `${this.settingService.pathImagesMercadoPartsfy}/not-found.png`, isActive: true }]
                this.actualImage = this.imagesList[0].url
            }

            this.imagesList[0].isActive = true
            this.actualImage = this.imagesList[0].url

            this.actualQuantity = 1;
            this.updateFreeDelivery(this.actualQuantity)
            this.updateInstallments(this.actualQuantity)
            this.updateCashbackValue(this.actualQuantity)

            if(this.companyRelationship.fretes.find((frete) => frete.holding_id === this.part.holdingId).shipping_cost == 0) {
                this.hasFreeDelivery = true
            } else {
                this.hasFreeDelivery = this.companyRelationship.fretes.find((frete) => frete.holding_id === this.part.holdingId).min_value < this.part.priceWithDiscount
            }

            let relationship = this.companyRelationship.prazosEntrega.find((holding) => holding.holding_id === this.part.holdingId)

            this.delivery_time_id = relationship ? relationship.delivery_time_id : 99

            let deliveryTime = relationship ? relationship.delivery_time_name.replace('dia útil', '').replace('dias úteis', '').replace(',', '.') : '99'

            this.buyerMercadoPartsfyListService.getDeliveryDate(deliveryTime).subscribe({
                next: (response: ApiResponseModel<{deliveryDate: Date}>) => {
                    this.delivery_time = response.result.deliveryDate.toString()
                    this.delivery_time_formatted = formatDateToDDMMYYYY(new Date(response.result.deliveryDate))
                },
                error: (err) => {
                    console.log(err)
                }
            })

            let options: ModalOptions = { class: 'custom-modal', backdrop: 'static', keyboard: false };
            this.modalRef = this.modalService.show(this.template, options);
        })
    }

    updateCashbackValue(quantity: number) {
        this.cashbackValue = this.buyerMercadoPartsfyListService.getCashbackValue(this.part, quantity)
    }

    updateFreeDelivery(quantity: number) {
        const frete = this.companyRelationship.fretes.find((frete) => frete.holding_id === this.part.holdingId)

        this.hasFreeDelivery = frete.shipping_cost <= 0 || (frete.min_value < this.part.priceWithDiscount * quantity)
    }

    updateInstallments(quantity: number) {
        const currentPrice = this.part.priceWithDiscount * quantity;
        const paymentTypes = this.companyRelationship.formasPagamento
                                .filter(pt => pt.holding_id === this.part.holdingId && pt.start_value <= currentPrice)
                                .map(pt => (pt.nroX));

        this.installmentsNumber = Math.max.apply(Math, paymentTypes.length == 0 ? [0] : paymentTypes);
        this.installmentsPrice = (this.part.priceWithDiscount * quantity) / this.installmentsNumber
    }

    validatePartQuantity() {
        let quantity = $('input[name="part-quantity"]').val();

        if (quantity <= 0) {
            quantity = 1;
        } else if (quantity > 999) {
            quantity = 999;
        }

        $('input[name="part-quantity"]').val(quantity)

        this.updateFreeDelivery(quantity)
        this.updateInstallments(quantity)
        this.updateCashbackValue(quantity)

        this.actualQuantity = Number(quantity)
    }
}
