import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbAccordionDirective } from '@ng-bootstrap/ng-bootstrap';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import { BuyerReviewService } from './avaliacoes.service';
import { NotificationService } from 'app/_services/notification.service';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { AddReviewPostModel } from './_models/addReviewPostModel';
import { CompradorAvaliacao } from '../_models/avaliacao';
import { Globals } from 'app/_models/globals';
import { SweetAlertParams } from 'app/_models/notificationParams.model';

declare const $: any;
declare const getJavascriptDateFromCSharpDate: any;
declare const isEmpty: any;
declare const sort_by: any;

@Component({ selector: 'app-avaliacoes', templateUrl: './avaliacoes.component.html', styleUrls: ['./avaliacoes.component.scss'] })
export class BuyerAvaliacoesComponent implements OnInit {
    @ViewChild(NgbAccordionDirective) accordion: NgbAccordionDirective;

    avaliacoes: CompradorAvaliacao[];
    avaliacoes_pendentes: CompradorAvaliacao[];
    avaliacoes_realizadas: CompradorAvaliacao[];

    constructor(private avaliacoesService: BuyerReviewService, private notificationService: NotificationService, private globals: Globals, private ngxLoader: NgxUiLoaderService) { }

    ngOnInit(): void {
        this.avaliacoes = [];
        this.avaliacoes_pendentes = [];
        this.avaliacoes_realizadas = [];
        this.carregarTela(0);
    }

    accordionChange(panelId: string) {
        var otherPanel = panelId === 'panelNaoRealizadas' ? 'panelJaRealizadas' : 'panelNaoRealizadas';
        this.accordion.toggle(otherPanel);
    }

    alterarOpcao($event, avaliacao: CompradorAvaliacao) {
        $event.stopPropagation();

        if (avaliacao.situacao_parcial == 1)
            avaliacao.foi_bom_local = "1";
        else
            avaliacao.foi_bom_local = "-1";
    }

    async acao_confirmar(avaliacao: CompradorAvaliacao) {
        let continuar = true;
        let motivo = null;

        if (avaliacao.foi_bom_local === '0') {
            const params: SweetAlertParams = {
                input: 'textarea',
                title: 'buyer.avaliacoes.motivo.titulo',
                inputPlaceholder: 'buyer.avaliacoes.motivo.opcional'
            }

            await this.notificationService.showConfirm(params).then((result) => {
                continuar = (result.isConfirmed);

                if (!continuar)
                    return;

                motivo = $('.swal2-textarea').val();
            });
        }

        if (!continuar)
            return;

        this.acao_confirmar_interno(avaliacao, motivo, 0);
    }

    private acao_confirmar_interno(avaliacao: CompradorAvaliacao, reason: string, nAttempts: number) {
        $('.fa-check.avaliacao_' + avaliacao.id).hide();
        $('.fa-times.avaliacao_' + avaliacao.id).hide();
        $('.fa-spinner.avaliacao_' + avaliacao.id).show();

        let state = avaliacao.situacao_parcial;
        let was_good = avaliacao.foi_bom_local === '1';

        let body: AddReviewPostModel = {
            avaliacao_id: avaliacao.id,
            state: state,
            was_good: was_good,
            reason: reason
        };

        this.avaliacoesService.addReview(body).subscribe({
            next: () => {
                avaliacao.situacao = state;
                avaliacao.foi_bom = was_good;
                avaliacao.avaliacao_texto = state == 1
                    ? (
                        was_good
                            ? this.globals.translate('buyer.avaliacoes.situacao.positiva')
                            : this.globals.translate('buyer.avaliacoes.situacao.negativa')
                    )
                    : this.globals.translate('buyer.avaliacoes.situacao.ignorada');
                avaliacao.comentario = reason;
                avaliacao.feedback_date_js = new Date();

                $('.fa-spinner.avaliacao_' + avaliacao.id).hide();
                $('.fa-check.avaliacao_' + avaliacao.id).fadeIn('slow', function () { $(this).delay(3000).fadeOut('slow'); });

                this.filtrarAvaliacoes();

                this.notificationService.showSuccessToastr(this.globals.translate('buyer.avaliacoes.mensagens.sucesso.avaliar.corpo'));
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    $('.fa-spinner.avaliacao_' + avaliacao.id).hide();
                    $('.fa-times.avaliacao_' + avaliacao.id).fadeIn('slow', function () { $(this).delay(3000).fadeOut('slow'); });
                    this.notificationService.showErrorToastr(this.globals.translate('buyer.avaliacoes.mensagens.erro.avaliar.corpo'));
                    return;
                }

                this.acao_confirmar_interno(avaliacao, reason, ++nAttempts);
            }
        });
    }

    carregarTela(nAttempts: number) {
        this.ngxLoader.startLoader('loader-principal');

        this.avaliacoesService.getAllByUserCompanyId().subscribe({
            next: (response: ApiResponseModel<{reviews : CompradorAvaliacao[]}>) => {
                if (response != null && !isEmpty(response.result.reviews)) {
                    this.avaliacoes = response.result.reviews;

                    this.avaliacoes.map(a => {
                        a.entregou_no_prazo = a.pecas.every(p => p.entrega_no_prazo);

                        a.foi_bom_local = (a.situacao === 0) ? '-1' : (a.foi_bom ? '1' : '0');
                        a.situacao_parcial = (a.entregou_no_prazo && a.situacao === 0) ? 1 : a.situacao;

                        if (a.entregou_no_prazo)
                            a.foi_bom_local = '1';

                        a.data_pedido_js = getJavascriptDateFromCSharpDate(a.data_pedido);
                        a.feedback_date_js = getJavascriptDateFromCSharpDate(a.feedback_date);
                    });

                    this.filtrarAvaliacoes();
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('buyer.avaliacoes.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.carregarTela(++nAttempts);
            }
        });
    }

    async exibirPecas(avaliacao: CompradorAvaliacao) {
        let html = "<div class='col-md-12 text-start veiculo-pecas'>";

        html += '<section class="d-flex flex-wrap">';
        html += '<header class="linha">';
        html += '<div class="col-2 table-cell">Peça</div>';
        html += '<div class="col table-cell">Situação</div>';
        html += '<div class="col table-cell">Data Prevista</div>';
        html += '<div class="col table-cell">Data Entrega</div>';
        html += '</header>';

        html += '<div class="corpo">';

        for (var i = 0; i <= avaliacao.pecas.length - 1; i++) {
            let peca = avaliacao.pecas[i];

            html += '<div class="linha">';

            html += '<div class="col-2 table-cell truncate">' + peca.nome + '</div>';
            html += '<div class="col table-cell">' + peca.situacao + '</div>';
            html += '<div class="col table-cell text-center">' + peca.data_prevista + '</div>';
            html += '<div class="col table-cell text-center">' + peca.data_entrega + '</div>';

            html += '</div>';
        }

        html += '</div>';

        html += '</section>';

        const params: SweetAlertParams = {
            bigSwall: true,
            isHtml: true,
            message: html,
            title: '#' + avaliacao.order_id + ' - ' + avaliacao.veiculo,
            notTranslateTitle: true
        }

        this.notificationService.showInfoWithoutButtons(params);
    }

    filtrarAvaliacoes() {
        this.avaliacoes_pendentes = this.avaliacoes.filter(a => a.situacao === 0).sort(sort_by([{ name: 'data_pedido_js', reverse: true }]));
        this.avaliacoes_realizadas = this.avaliacoes.filter(a => a.situacao > 0).sort(sort_by([{ name: 'feedback_date_js', reverse: true }]));
    }
}
