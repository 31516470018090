<div class="comprador-pedidos col-md-12 d-flex flex-wrap">
    <div class="col-md-2 pt0">
        <div class="card card-default lista mb0">
            <div class="card-header">
                <div class="area-filtros">
                    <div class="mb-3 form-group">
                        <label>{{ 'buyer.pedidos.filtro.situacao.titulo' | translate}}</label>
                        <select class="form-select" [(ngModel)]="filtroStatus">
                            <option value="0" selected>{{ 'buyer.pedidos.filtro.situacao.opcoes.todos' | translate}}</option>
                            <option value="1">{{ 'buyer.pedidos.filtro.situacao.opcoes.em-analise' | translate}}</option>
                            <option value="2">{{ 'buyer.pedidos.filtro.situacao.opcoes.aguardando-entrega' | translate}}</option>
                            <option value="3">{{ 'buyer.pedidos.filtro.situacao.opcoes.entregue' | translate}}</option>
                            <option value="4">{{ 'buyer.pedidos.filtro.situacao.opcoes.rejeitado' | translate}}</option>
                        </select>
                    </div>
                    <div class="mb-3 form-group">
                        <label>{{ 'buyer.pedidos.filtro.pesquisa.titulo-1' | translate}} <br class="d-lg-none" /> {{ 'buyer.pedidos.filtro.pesquisa.titulo-2' | translate}}</label>
                        <input type="text" placeholder="{{ 'buyer.pedidos.filtro.pesquisa.placeholder' | translate}}" maxlength="100" [(ngModel)]="searchTerm" class="form-control" />
                    </div>
                </div>
            </div>
            <div class="card-body">
                <ng-scrollbar>
                    @if (filteredPedidos.length === 0) {
                        <div class="vazio">
                            {{ 'buyer.pedidos.mensagens.sem-pedido' | translate }}
                        </div>
                    } @else {
                        <ul class="list-unstyled">
                            @for (pedido of filteredPedidos; track pedido) {
                                <li (click)="selecionarPedido(pedido)" [ngClass]="pedido.transaction_id === pedidoSelecionado.transaction_id ? 'selecionado' : ''">
                                    <i class="rounded-circle marca {{pedido.marca}}"></i>
                                    <span class="truncate">{{pedido.veiculo_resumido}}</span>
                                </li>
                            }
                        </ul>
                    }
                </ng-scrollbar>
            </div>
        </div>
    </div>
    <div class="col-md-10 pt0">
        <div class="card card-default detalhe mb0">
            <div class="card-header">
                <div>
                    <i class="rounded-circle marca {{pedidoSelecionado.marca}}"></i>
                    <span>{{pedidoSelecionado.veiculo}}</span>
                </div>
            </div>
            <ng-scrollbar>
                <app-dados-pedido [agrupamento]="pedidoSelecionado" [deliveryTimes]="deliveryTimes" [feriados]="feriados" [transportadoras]="transportadoras" [voltarHome]="false" (atualizarPedido)="atualizarPedidoSelecionado($event)"></app-dados-pedido>
            </ng-scrollbar>
        </div>
    </div>
</div>

<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
