import { Component, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CentralSemaforoCotacaoPeca } from '../../_models/centralSemaforoCotacaoPeca';
import { BuyerSemaforoCuradorService } from '../../semaforo.service';
import { NotificationService } from '../../../../_services/notification.service';
import { Globals } from '../../../../_models/globals';
import { NgxUiLoaderService } from 'ngx-ui-loader';

declare const $: any;

@Component({ selector: 'app-modal-lista-devolucao-component', templateUrl: './modal-lista-devolucao.component.html', styleUrls: ['./modal-lista-devolucao.component.scss'] })
export class BuyerSemaforoCuradorModalListaDevolucaoComponent
{
    @ViewChild('templateModalListaDevolucao') template: TemplateRef<any>;
    modalRef: BsModalRef;

    orderId: number;
    quotationId: number;
    pecasEmDevolucao: CentralSemaforoCotacaoPeca[] = [];
    pecasEscolhidasId: number[] = [];

    constructor(private curadorService: BuyerSemaforoCuradorService, private modalService: BsModalService, private notificationService: NotificationService, private ngxLoader: NgxUiLoaderService, private globals: Globals) { }

    async close(): Promise<void> {
        this.modalRef.hide()
    }

    confirmarDevolucao() {
        this.ngxLoader.startLoader('loader-lista-devolucao');

        this.curadorService.markOrderAsSolved(this.orderId, this.pecasEscolhidasId).subscribe({
            next: () => {
                this.notificationService.showSuccessToastr(this.globals.translate('buyer.curador.lista-devolucao.mensagens.sucesso.corpo'));

                this.ngxLoader.stopLoader('loader-lista-devolucao');
                this.close();
            },
            error: () => {
                this.notificationService.showErrorToastr(this.globals.translate('buyer.curador.lista-devolucao.mensagens.erro.corpo'));

                this.ngxLoader.stopLoader('loader-principal');
            }
        })
    }

    selecionarTodas($event) {
        var isChecked = $event.target.checked;
        this.pecasEmDevolucao.forEach(peca => {
            if((isChecked && this.pecasEscolhidasId.includes(peca.item_id)) || (!isChecked && !this.pecasEscolhidasId.includes(peca.item_id))) return;

            $(`#${peca.item_id}`).click();
        })
    }

    selecionarPeca(peca: CentralSemaforoCotacaoPeca) {
        if (this.pecasEscolhidasId.includes(peca.item_id))
            this.pecasEscolhidasId = this.pecasEscolhidasId.filter(p => p != peca.item_id)
        else
            this.pecasEscolhidasId.push(peca.item_id)

        if (this.pecasEmDevolucao.length == this.pecasEscolhidasId.length || this.pecasEscolhidasId.length == 0) $('#toggleAll').click()
    }

    open(orderId: number, quotationId: number, pecas: CentralSemaforoCotacaoPeca[]): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            this.orderId = orderId;
            this.quotationId = quotationId;
            this.pecasEmDevolucao = pecas;
            this.modalRef = this.modalService.show(this.template, { backdrop: 'static', class: 'gray modal-lg', keyboard: false });
            resolve(true);
        })
    }
}
