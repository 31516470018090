import { Component, OnInit } from '@angular/core';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import { BuyerPedidosService } from './pedidos.service';
import { BuyerService } from '../_services/buyer.service';
import { NotificationService } from 'app/_services/notification.service';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { BuyerOrdersGetOrdersLast30DaysResponseModel } from './_models/buyerOrdersGetOrdersLast30DaysResponseModel';
import { BuyerOrdersGetByQuotationId } from '../_models/buyerOrdersGetByQuotationId';
import { Globals } from 'app/_models/globals';
import { Transportadora } from 'app/_models/transportadora';
import { StatusIdEnum, isOldStatusConfirmado } from 'app/_models/statusId';

declare const compararStringContemString: any;
declare const isEmpty: any;
declare const sort_by: any;

@Component({ selector: 'app-pedidos', templateUrl: './pedidos.component.html', styleUrls: ['./pedidos.component.scss'] })
export class BuyerPedidosComponent implements OnInit {
    _filtro_status: string;
    _searchString: string;
    filteredPedidos: any;
    pedidos: any;
    pedidoSelecionado: any;
    deliveryTimes: any[];
    feriados: string[];
    transportadoras: Transportadora[] = [];

    constructor(private buyerService: BuyerService, private pedidosService: BuyerPedidosService, private notificationService: NotificationService, private globals: Globals, private ngxLoader: NgxUiLoaderService) { }

    ngOnInit() {
        this.filtroStatus = '0';
        this.searchTerm = '';
        this.filteredPedidos = [];
        this.pedidoSelecionado = {};
        this.pedidos = [];

        this.carregarTela(0);
    }

    atualizarPedidosFiltrados() {
        var pedidosFiltrados = this.filtrarPedidos();

        this.filteredPedidos = pedidosFiltrados;
        this.sortPedidos();
    }

    atualizarPedidoSelecionado(param) {
        this.ngxLoader.startLoader('loader-principal');

        this.pedidosService.getByQuotationId(param.quotation_id).subscribe({
            next: (response: ApiResponseModel<BuyerOrdersGetByQuotationId>) => {
                if (response && response.success) {
                    const result = response.result;

                    result.order.fotos = result.photos;

                    this.pedidoSelecionado = result.order;
                    this.pedidoSelecionado.fotos = result.photos;

                    this.deliveryTimes = result.deliveryTimes;

                    let holidays = result.holidays;
                    this.feriados = holidays.map(h => h.replace('T05:00:00', ''));
                    this.transportadoras = result.transportadoras;

                    if (!isEmpty(this.pedidoSelecionado)) {
                        for (let pedido of this.pedidoSelecionado.pedidos) {
                            pedido = this.buyerService.atualizarTotais(pedido);
                            pedido.min_status = Math.min.apply(Math, pedido.pecas.map(p => p.status));
                            pedido.isCashbackCustomizado = pedido.had_cashback && pedido.pecas.some(p => p.desconto_original != p.desconto);
                            pedido.isCashbackPadronizado = pedido.had_cashback && !pedido.isCashbackCustomizado;
                        }
                    }

                    this.buyerService.handleEditionInformationsLoad(this.pedidoSelecionado, this.deliveryTimes, this.feriados);
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                console.log(error);
                this.ngxLoader.stopLoader('loader-principal');
            }
        });
    }

    carregarTela(nAttempts) {
        this.ngxLoader.startLoader('loader-principal');

        this.pedidosService.getAllLast30Days().subscribe({
            next: (response: ApiResponseModel<BuyerOrdersGetOrdersLast30DaysResponseModel>) => {
                if (response && response.success) {
                    this.pedidos = response.result.orders.map(o => {
                        return {
                            marca: o.marca,
                            transaction_id: o.transaction_id,
                            veiculo_resumido: o.veiculo_resumido,
                            veiculo: o.veiculo,
                            order_ids: o.pedidos.map(p => p.order_id),
                            pecas: o.pedidos.map(pe => { return pe.pecas.map(peca => { return { status: peca.status } }) }).flat(1)
                        }
                    });

                    this.atualizarPedidosFiltrados();

                    if (!isEmpty(this.filteredPedidos) && this.filteredPedidos.length > 0)
                        this.selecionarPedido(this.filteredPedidos[0]);
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('buyer.pedidos.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.carregarTela(++nAttempts);
            }
        });
    }

    filtrarPedidos() {
        if (isEmpty(this.pedidos))
            return this.pedidos;

        var pedidosFiltrados =
            this.pedidos.filter(p =>
                p.pecas.filter(pe => {
                    switch (this._filtro_status) {
                        case '0':
                            return true;
                        case '1': // em análise
                            return pe.status === StatusIdEnum.EmAnalise;
                        case '2': // aguardando entrega
                            return isOldStatusConfirmado(pe.status);
                        case '3': // entregue
                            return pe.status === StatusIdEnum.Entregue;
                        case '4': // rejeitado
                            return pe.status >= StatusIdEnum.CotacaoCanceladaComprador;
                        default:
                            return false;
                    }
                }).length > 0);

        if (!this._searchString)
            return pedidosFiltrados;

        var term: string = this._searchString.toLowerCase().replace('-', '');

        var result = pedidosFiltrados.filter(pedido =>
            pedido.transaction_id.toString().indexOf(term) !== -1 ||
            pedido.order_ids.some(oi => oi.toString().indexOf(term) !== -1) ||
            //(pedido.status_entrega && pedido.status_entrega.toLowerCase().indexOf(term) !== -1) ||
            (!isEmpty(pedido.veiculo) && compararStringContemString(pedido.veiculo.replace('-', ''), term)) ||
            compararStringContemString(pedido.marca, term)
        );

        return result;
    }

    selecionarPedido(pedido) {
        this.atualizarPedidoSelecionado({ quotation_id: pedido.transaction_id });
    }

    sortPedidos() {
        if (isEmpty(this.filteredPedidos))
            return;

        this.filteredPedidos.sort(sort_by([{ name: 'order_date_js', reverse: true }]));
    }



    get filtroStatus(): string {
        return this._filtro_status;
    }

    set filtroStatus(value: string) {
        this._filtro_status = value;
        this.atualizarPedidosFiltrados();
    }

    get searchTerm(): string {
        return this._searchString;
    }

    set searchTerm(value: string) {
        this._searchString = value;
        this.atualizarPedidosFiltrados();
    }
}
