<div class="vendedores-convidados col-md-12" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [hasBackButton]="false"
        [hasButtons]="false"
        [hasSearchButton]="false"
        [searchPlaceholder]="'buyer.convidados.pesquisar.placeholder' | translate"
        [searchInput]="searchTerm"
        [title]="'buyer.convidados.titulo' | translate"
        (onSearch)="searchTerm = $event"
    ></app-header>
    <div class="card card-default col-md-12">
        <div class="form-check c-checkbox needsclick apenas_nao_aceitos">
            <label class="needsclick">
                <input type="checkbox" value="" class="needsclick" [(ngModel)]="apenas_nao_aceitos">
                <span class="fa fa-check"></span> {{ 'buyer.convidados.pesquisar.filtro' | translate}}
            </label>
        </div>
        <ng-scrollbar class="pb0">
            @for (convite of convitesFiltrados; track convite) {
                <div>
                    <div class="row">
                        @switch (convite.status) {
                            @case (1) {
                                <div class="col-md-2 text-center">
                                    <i class="fa-4x fa fa-check text-success"></i>
                                    <br />
                                    <span><b>{{ 'buyer.convidados.dados-convite.aceito' | translate}}</b></span>
                                </div>
                            }
                            @case (0) {
                                <div class="col-md-2 text-center">
                                    <i class="fa-4x fa fa-spinner"></i>
                                    <br>
                                    <span><b>{{ 'buyer.convidados.dados-convite.aguardando' | translate}}</b></span>
                                </div>
                            }
                        }
                        <div class="col-md-5">
                            <b>{{convite.seller_name}}</b><br />
                            <span class="company_name"><small>{{convite.company_name}}</small></span>
                            <ul class="list-unstyled">
                                <li><b>{{ 'buyer.convidados.dados-vendedor.email' | translate}}:</b> {{convite.seller_email}}</li>
                                <li><b>{{ 'buyer.convidados.dados-vendedor.fone-radio' | translate}}:</b> {{convite.seller_phone_radio}}</li>
                                <li><b>{{ 'buyer.convidados.dados-vendedor.ultimo-convite' | translate}}:</b> {{convite.creation_date_formatada}}</li>
                            </ul>
                        </div>
                        <div class="col-md-5 coluna5 text-center botoes">
                            <button type="button" class="btn btn-option btn-primary" [disabled]="convite.status == 1 ? 'disabled' : null" (click)="openConfirmResend(convite)">
                                <span class="btn-label"><i class="fa fa-reply"></i></span>
                                <span>{{ 'buyer.convidados.botao.reenviar' | translate}}</span>
                            </button>
                            &nbsp;
                            <button type="button" class="btn btn-option btn-light" (click)="openConfirmRemove(convite)">
                                <span class="btn-label"><i class="fa fa-trash"></i></span>
                                <span>{{ 'buyer.convidados.botao.excluir' | translate}}</span>
                            </button>
                        </div>
                    </div>
                </div>
            }
        </ng-scrollbar>
    </div>
</div>
<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
