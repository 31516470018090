import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { BuyerVendedoresConvidadosService } from './vendedores-convidados.service';
import { NotificationService } from 'app/_services/notification.service';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { Globals } from 'app/_models/globals';
import { Invite } from './_models/invite';
import { SweetAlertParams } from 'app/_models/notificationParams.model';

declare const isEmpty: any;
declare const findInArrayIndex: any;

@Component({ selector: 'app-vendedores-convidados', templateUrl: './vendedores-convidados.component.html', styleUrls: ['./vendedores-convidados.component.scss'] })
export class BuyerVendedoresConvidadosComponent implements OnInit
{
    convites: Invite[];
    convitesFiltrados: Invite[];
    _apenas_nao_aceitos: boolean;
    _searchString: string;
    Swal: any;

    constructor(
        private vendedoresConvidadosService: BuyerVendedoresConvidadosService,
        private notificationService: NotificationService,
        private globals: Globals,
        private ngxLoader: NgxUiLoaderService) { }

    ngOnInit() {
        this.Swal = require('sweetalert2');
        this.apenas_nao_aceitos = false;
        this.convites = [];
        this.convitesFiltrados = [];
        this.searchTerm = '';

        this.carregarTela(1);
    }

    atualizarConvitesFiltrados() {
        this.convitesFiltrados = this.filtrarConvites();
    }

    carregarTela(nAttempts: number) {
        this.ngxLoader.startLoader('loader-principal');
        this.vendedoresConvidadosService.getAllByBuyerCompanyId().subscribe({
            next: (response: ApiResponseModel<{ invites: Invite[]}>) => {
                if (response && response.result) {
                    this.convites = response.result.invites;

                    this.atualizarConvitesFiltrados();
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('buyer.convites.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.carregarTela(++nAttempts);
            }
        });
    }

    filtrarConvites() {
        if (isEmpty(this.convites))
            return this.convites;

        var term: string = this._searchString.toLowerCase();
        var result = this.convites.filter(x => (x.company_name.toLowerCase().indexOf(term) !== -1 || x.seller_name.toLowerCase().indexOf(term) !== -1 || x.seller_email.toLowerCase().indexOf(term) !== -1)
                                            && (!this.apenas_nao_aceitos || x.status == 0)
                                            );
        return result;
    }

    openConfirmResend(convite) {
        let params: SweetAlertParams = {
            title: 'buyer.convidados.confirmacao.reenviar',
            message: this.globals.translate('buyer.convidados.confirmacao.ultimo-convite') + ' ' + convite.creation_date_formatada,
            notTranslateMessage: true,
            confirmButtonText: 'buyer.convidados.botao.reenviar',
            cancelButtonText: 'buyer.convidados.botao.fechar',
        }

        this.notificationService.showConfirm(params).then((result) => {
            if (result.value) {
                this.ngxLoader.startLoader('loader-principal');
                this.vendedoresConvidadosService.resendInvite(convite.id).subscribe({
                    next: (response: ApiResponseModel<Invite>) => {
                        var indice = findInArrayIndex(this.convites, "id", convite.id);
                        this.convites[indice] = response.result;
                        this.atualizarConvitesFiltrados();

                        this.notificationService.showSuccessToastr(this.globals.translate('buyer.convidados.mensagens.reenviar.sucesso.corpo'));

                        this.ngxLoader.stopLoader('loader-principal');
                    },
                    error: error => {
                        console.log(error);
                        this.ngxLoader.stopLoader('loader-principal');
                    }
                });
            }
        });
    }

    openConfirmRemove(convite) {
        let params: SweetAlertParams = {
            title: 'buyer.convidados.confirmacao.excluir',
            confirmButtonText: 'buyer.convidados.botao.excluir',
            cancelButtonText: 'buyer.convidados.botao.fechar',
        }

        this.notificationService.showConfirm(params).then((result) => {
            if (result.value) {
                this.ngxLoader.startLoader('loader-principal');
                this.vendedoresConvidadosService.archiveInvite(convite.id).subscribe({
                    next: () => {
                        const indice = findInArrayIndex(this.convites, "id", convite.id);
                        this.convites.splice(indice, 1);
                        this.atualizarConvitesFiltrados();

                        this.notificationService.showSuccessToastr(this.globals.translate('buyer.convidados.mensagens.excluir.sucesso.corpo'));

                        this.ngxLoader.stopLoader('loader-principal');
                    },
                    error: error => {
                        console.log(error);
                        this.ngxLoader.stopLoader('loader-principal');
                    }
                });
            }
        });
    }

    get searchTerm(): string {
        return this._searchString;
    }

    set searchTerm(value: string) {
        this._searchString = value;
        this.atualizarConvitesFiltrados();
    }

    get apenas_nao_aceitos(): boolean {
        return this._apenas_nao_aceitos;
    }

    set apenas_nao_aceitos(value: boolean) {
        this._apenas_nao_aceitos = value;
        this.atualizarConvitesFiltrados();
    }
}
