<ng-template #templateModalListaDevolucao>
    <div class="modal-lista-devolucao">
        <div class="modal-header">
            <section class="modal-title">
                {{ 'buyer.curador.lista-devolucao.titulo' | translate }}
            </section>
            <button type="button" data-dismiss="modal" aria-label="Close" class="btn-close" (click)="close();"></button>
        </div>
        <div class="modal-body">
            <section class="d-flex flex-wrap bordered">
                <header>
                    <div class="col-2">{{ 'buyer.curador.lista-devolucao.label-peca' | translate }}</div>
                    <div class="col-0-25">
                        <div class="form-check c-checkbox needsclick checkboxcot">
                            <label class="needsclick">
                                <input id="toggleAll" type="checkbox" class="needsclick ng-pristine ng-untouched ng-valid" (change)="selecionarTodas($event)">
                                <span class="fa fa-check"></span>
                            </label>
                        </div>
                    </div>
                </header>
                <ng-scrollbar class="corpo">
                    @for (peca of pecasEmDevolucao; track peca; let i = $index) {
                        <div class="linha">
                            <div class="col-2">{{peca.part_name}}{{peca.part_number ? (" - " + peca.part_number) : ''}}</div>
                            <div class="col-0-25">
                                <div class="form-check c-checkbox needsclick checkboxcot">
                                    <label class="needsclick">
                                        <input [id]="peca.item_id" type="checkbox" class="needsclick ng-pristine ng-untouched ng-valid" (click)="selecionarPeca(peca)">
                                        <span class="fa fa-check"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                </ng-scrollbar>
            </section>
        </div>
        <div class="modal-footer">
            <button class="btn btn-primary" (click)="confirmarDevolucao()">{{ 'buyer.curador.lista-devolucao.botao' | translate }}</button>
        </div>
    </div>
    <ngx-ui-loader [loaderId]="'loader-lista-devolucao'"></ngx-ui-loader>
</ng-template>
