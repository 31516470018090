import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { FilterTypeEnum } from '../../_models/filter-type.enum';

declare const $: any;

@Component({ selector: 'app-filter', templateUrl: './filter.component.html', styleUrls: ['./filter.component.scss'] })
export class BuyerMercadoPartsfyListFilterComponent {
    @Input() filterGlobal: any
    @Input() montadoras: { brandId: number; brandName: string, totalItems: number }[];

    @Output() filterChanged = new EventEmitter<{filterName: string, globalObject: any[]}>();
    @ViewChildren('montadoras') montadorasView: QueryList<any>;
    filterList: string[] = [];
    filterSelectedMontadoras: number[] = []
    isSectionColapsed: { [key: string]: boolean } = { 'montadora': false }

    private _montadoraFilterName: string = FilterTypeEnum[FilterTypeEnum.Montadora].toLowerCase();

    constructor(private _location: Location, private route: ActivatedRoute) { }

    isElementLoaded = async selector => {
        while ( $(selector).length == 0 ) {
            await new Promise( resolve =>  requestAnimationFrame(resolve) )
        }
        return $(selector);
    };

    getUniqueValuesNumber(globalObject: any[]): number[] {
        return globalObject
                .map(item => item.toString().trim())
                .filter(value => !isNaN(Number(value)) && Number.isInteger(Number(value)) && Number(value) > 0)
                .map(value => Number(value))
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort();
    }

    loadFilters() {
        let filtros = Object.keys(this.filterGlobal);
        
        if (filtros.length > 0) {
            filtros.forEach(filtro => {
                if (filtro === this._montadoraFilterName) {
                    let paramArray = this.getUniqueValuesNumber(this.filterGlobal[filtro].split(','));
                    this.filterGlobal[filtro] = paramArray.join(',');

                    paramArray.forEach(id => {
                        this.isElementLoaded(`input[name="${this._montadoraFilterName}"][value="${id}"]`).then((selector) => {
                            selector.prop({'checked': true});
                            this.filterList.push(selector.attr('data-name'));
                        })
                    });
                } else {
                    this.isElementLoaded('input[name="' + filtro + '"][value="' + filtro + '"]').then((selector) => {
                        selector.prop({'checked': this.filterGlobal[filtro] == true});
                    })
                }
            });
        }

        if (this.filterGlobal.montadora === '')
            return;

        this.setFilterSelectedMontadoras();
    }

    removeFilter(filterName: string): void {
        $(`input[name="${this._montadoraFilterName}"][data-name="${filterName}"]`).trigger('click');
    }

    setFilterSelectedMontadoras() {
        this.filterSelectedMontadoras = this.filterGlobal.montadora.split(',');
    }

    updateFilter(paramName: string, paramValues: any[] = [], value: number, filterValue: string, isList: boolean = false) {
        if (paramName === this._montadoraFilterName) {
            paramValues = this.getUniqueValuesNumber(paramValues);
            $(`.fa-spinner.${this._montadoraFilterName}_${value}`).show();
        }

        if (isList) {
            this.updateUrlFilterList(paramName, paramValues, value);
        } else {
            this.updateUrlFilter({[paramName]: value});
        }

        if (this.filterList.includes(filterValue)) {
            this.filterList.splice(this.filterList.indexOf(filterValue), 1);
        } else {
            this.filterList.push(filterValue);
        }

        this.setFilterSelectedMontadoras();
        this.filterChanged.emit({filterName: paramName, globalObject: paramValues});
    }

    updateUrlFilterList(paramName: string, paramValues: any[], value: number) {
        if (paramValues.includes(value)) {
            paramValues.splice(paramValues.indexOf(value), 1);
        } else {
            paramValues.push(value);
        }

        this.updateUrlFilter({[paramName]: paramValues.toString()});
    }

    updateUrlFilter(filter: any) {
        filter.page = ''

        this.filterGlobal = Object.assign(this.filterGlobal, filter);
        let filterKeys = Object.keys(this.filterGlobal);
        
        const queryParams = filterKeys.map(filterKey => {
            if (this.filterGlobal[filterKey] && this.filterGlobal[filterKey].length > 0)
                return `${filterKey}=${this.filterGlobal[filterKey]}`;
        }).filter((filter) => filter !== undefined).join('&');

        const baseUrl = this._location.path().split('?')[0];
        const pageUrl = queryParams?.length > 0 ? `${baseUrl}?${queryParams}` : baseUrl;

        this._location.go(pageUrl);
    }
}
