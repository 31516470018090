<div class="avaliacoes" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [title]="'buyer.avaliacoes.titulo.tela' | translate"
        [hasBackButton]="false"
    ></app-header>
    <div class="corpo-pagina">
        <div class="card card-default col-md-12">
            <div ngbAccordion #accordion="ngbAccordion" class="ngbAccordion" [closeOthers]="true" (hide)="accordionChange($event)">
                <div ngbAccordionItem="panelNaoRealizadas" [collapsed]="this.avaliacoes_pendentes.length == 0">
                    <div ngbAccordionHeader>
                        <button ngbAccordionButton>
                            {{'buyer.avaliacoes.titulo.nao-realizadas' | translate}}
                        </button>
                    </div>
                    <div ngbAccordionCollapse>
                        <div ngbAccordionBody class="nao-realizadas card-body pb0">
                            <ng-template>
                                @if (avaliacoes_pendentes?.length <= 0) {
                                    <h4 class="vazio">{{ 'buyer.avaliacoes.vazio.nao-realizadas' | translate }}</h4>
                                } @else {
                                    <section>
                                        <header>
                                            <div class="col">{{'buyer.avaliacoes.cabecalho.pedido' | translate}}</div>
                                            <div class="col">{{'buyer.avaliacoes.cabecalho.veiculo' | translate}}</div>
                                            <div class="col">{{'buyer.avaliacoes.cabecalho.vendedor' | translate}}</div>
                                            <div class="col d-none">{{'buyer.avaliacoes.cabecalho.cashback' | translate}}</div>
                                            <div class="col text-center">{{'buyer.avaliacoes.cabecalho.opcao' | translate}}</div>
                                            <div class="col text-center">{{'buyer.avaliacoes.cabecalho.avaliacao' | translate}}</div>
                                            <div class="col"></div>
                                        </header>
                                        <ng-scrollbar>
                                            @for (avaliacao of avaliacoes_pendentes; track avaliacao) {
                                                <div class="linha">
                                                    <div class="col" (click)="exibirPecas(avaliacao)">{{avaliacao.order_id}} <i class="fa fa-info-circle ml5"></i></div>
                                                    <div class="col">{{avaliacao.veiculo}}</div>
                                                    <div class="col">{{avaliacao.vendedor}} ({{avaliacao.fornecedor}})</div>
                                                    <div class="col text-end d-none" [ngClass]="{ 'text-success' : avaliacao.cashback > 0 }">{{avaliacao.cashback | currency:' '}}</div>
                                                    <div class="col">
                                                        <select class="form-select form-select-sm" [(ngModel)]="avaliacao.situacao_parcial" (change)="alterarOpcao($event, avaliacao)">
                                                            <option value="0" [attr.selected]="avaliacao.situacao_parcial === 0">{{'buyer.avaliacoes.opcao.pendente' | translate}}</option>
                                                            <option value="1" [attr.selected]="avaliacao.situacao_parcial === 1">{{'buyer.avaliacoes.opcao.avaliar' | translate}}</option>
                                                            <option value="2" [attr.selected]="avaliacao.situacao_parcial === 2">{{'buyer.avaliacoes.opcao.nao-avaliar' | translate}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="col">
                                                    <select class="form-select form-select-sm" [(ngModel)]="avaliacao.foi_bom_local" [disabled]="avaliacao.situacao_parcial != 1 ? 'disabled' : null">
                                                        @if (avaliacao.situacao_parcial != 1) {
                                                            <option value="-1" [attr.selected]="avaliacao.foi_bom_local === '-1'"> --- </option>
                                                        }
                                                        <option value="1" [attr.selected]="avaliacao.foi_bom_local === '1'">{{'buyer.avaliacoes.avaliacao.positiva' | translate}}</option>
                                                        <option value="0" [attr.selected]="avaliacao.foi_bom_local === '0'">{{'buyer.avaliacoes.avaliacao.negativa' | translate}}</option>
                                                    </select>
                                                    </div>
                                                    <div class="col">
                                                        <button class="btn btn-primary btn-sm" [disabled]="avaliacao.situacao_parcial == 0 ? 'disabled' : null" (click)="acao_confirmar(avaliacao)">{{'elemento.generico-botao.confirmar' | translate}}</button>
                                                        <div class="fa fa-spinner spin avaliacao_{{avaliacao.id}}" style="display: none;"><div></div></div>
                                                        <i class="fa fa-check text-success avaliacao_{{avaliacao.id}}" style="display: none;"></i>
                                                        <i class="fa fa-times text-danger avaliacao_{{avaliacao.id}}" style="display: none;"></i>
                                                    </div>
                                                </div>
                                            }
                                        </ng-scrollbar>
                                    </section>
                                }
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div ngbAccordionItem="panelJaRealizadas" [collapsed]="this.avaliacoes_pendentes.length != 0">
                    <div ngbAccordionHeader>
                        <button ngbAccordionButton>
                            {{'buyer.avaliacoes.titulo.ja-realizadas' | translate}}
                        </button>
                    </div>
                    <div ngbAccordionCollapse>
                        <div ngbAccordionBody class="ja-realizadas card-body pb0">
                            <ng-template>
                                @if (avaliacoes_realizadas?.length <= 0) {
                                    <h4 class="vazio">{{ 'buyer.avaliacoes.vazio.ja-realizadas' | translate }}</h4>
                                } @else {
                                    <section>
                                        <header>
                                            <div class="col">{{'buyer.avaliacoes.cabecalho.pedido' | translate}}</div>
                                            <div class="col">{{'buyer.avaliacoes.cabecalho.veiculo' | translate}}</div>
                                            <div class="col">{{'buyer.avaliacoes.cabecalho.vendedor' | translate}}</div>
                                            <div class="col">{{'buyer.avaliacoes.cabecalho.avaliacao' | translate}}</div>
                                        </header>
                                        <ng-scrollbar>
                                            @for (avaliacao of avaliacoes_realizadas; track avaliacao) {
                                                <div class="linha">
                                                    <div class="col" (click)="exibirPecas(avaliacao)">{{avaliacao.order_id}} <i class="fa fa-info-circle ml5"></i></div>
                                                    <div class="col">{{avaliacao.veiculo}}</div>
                                                    <div class="col">{{avaliacao.vendedor}} ({{avaliacao.fornecedor}})</div>
                                                    <div class="col">
                                                        {{avaliacao.avaliacao_texto}}
                                                        @if (avaliacao.situacao == 1 && !avaliacao.foi_bom) {
                                                            <i class="fa fa-info-circle ml5" tooltip="{{avaliacao.comentario}}"></i>
                                                        }
                                                    </div>
                                                </div>
                                                }
                                        </ng-scrollbar>
                                    </section>
                                }
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
