import { Component, OnInit } from '@angular/core';

@Component({ selector: 'app-convidar', templateUrl: './convidar.component.html', styleUrls: ['./convidar.component.scss'] })
export class BuyerConvidarComponent implements OnInit
{
    constructor() { }

    ngOnInit()
    { }
}
