import { Component, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { BuyerSemaforoCuradorService } from '../../semaforo.service';
import { NotificationService } from 'app/_services/notification.service';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { BuyerDashboardGetAllMergeOrdersResponseModel } from '../../_models/buyerDashboardGetAllMergeOrdersResponseModel';
import { Globals } from 'app/_models/globals';
import { OrdersMerge } from '../../_models/ordersMerge';
import { SweetAlertParams } from 'app/_models/notificationParams.model';

@Component({ selector: 'app-merge-order-modal', templateUrl: 'juntar-pedido-modal.component.html', styleUrls: ['juntar-pedido-modal.component.scss'] })
export class BuyerSemaforoCuradorModalJuntarPedidoComponent
{
    @ViewChild('templateModalMergeOrder') template: TemplateRef<any>;
    modalRef: BsModalRef;
    orders: OrdersMerge[] = [];
    orderId: number = 0;
    orderIdSelected: number = null;
    description: string;
    Swal: any;

    constructor(private globals: Globals, private curadorService: BuyerSemaforoCuradorService, private notificationService: NotificationService, private modalService: BsModalService, private ngxLoader: NgxUiLoaderService) { }

    async close(): Promise<void> {
        this.modalRef.hide()
        this.orderIdSelected = null;
    }

    open(orderId: number): Promise<boolean> {
        this.orderId = orderId;
        return new Promise<boolean>(resolve => {
            this.modalRef = this.modalService.show(this.template, { class: 'custom-modal' });

            this.ngxLoader.startLoader('loader-merge-orders');

            this.curadorService.getOrdersForMerge(orderId).subscribe({
                next: (response: ApiResponseModel<BuyerDashboardGetAllMergeOrdersResponseModel>) => {
                    this.orders = response.result.orders;

                    if (this.orders && this.orders.length > 0){
                        this.description = this.orders[0].description;
                    }

                    this.ngxLoader.stopLoader('loader-merge-orders');
                },
                error: () => {
                    this.notificationService.showErrorToastr(this.globals.translate('buyer.cotacao.area-respostas.modal-avaliacao.erro.abrir'))
                    this.ngxLoader.stopLoader('loader-merge-orders');
                    this.close();
                }
            });

            resolve(true);
        })
    }

    selectOrder(orderId: number) {
        this.orderIdSelected = this.orderIdSelected == orderId ? null : orderId;
    }

    mergeOrder() {
        let params: SweetAlertParams = {
            title: 'buyer.curador.juntar-pedidos.juntar-pedido',
            message: 'buyer.curador.juntar-pedidos.confirmar-juntar-pedidos',
            icon: 'warning',
            confirmButtonText: 'buyer.curador.juntar-pedidos.botoes.sim',
            cancelButtonText: 'buyer.curador.juntar-pedidos.botoes.nao',
        }

        this.notificationService.showConfirm(params).then((result) => {
            if (result.value) {
                this.ngxLoader.startLoader('loader-merge-orders');
                this.curadorService.mergeOrder(this.orderId, this.orderIdSelected).subscribe({
                    next: (response: ApiResponseModel<boolean>) => {
                        this.ngxLoader.stopLoader('loader-merge-orders');

                        if (response !== null && response.result) {
                            this.notificationService.showSuccessToastr(this.globals.translate('buyer.curador.juntar-pedidos.mensagem-acoes-modal.sucesso.corpo'));
                        } else {
                            this.notificationService.showErrorToastr(this.globals.translate('buyer.curador.juntar-pedidos.mensagem-acoes-modal.erro.corpo'))
                        }

                        this.close();
                    },
                    error: () => {
                        this.notificationService.showErrorToastr(this.globals.translate('buyer.curador.juntar-pedidos.mensagem-acoes-modal.erro.corpo'))
                        this.ngxLoader.stopLoader('loader-merge-orders');
                        this.close();
                    }
                })
            }
        });
    }

    disableButtonMergeOrder() {
        return this.orderIdSelected === null;
    }
}
