import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';

import { AccountService } from 'app/account/account.service';
import { BaseService } from 'app/_services/base.service';
import { SettingsService } from 'app/_services/settings.service';

declare const isEmpty: any;

@Injectable({ providedIn: 'root' })
export class BuyerMeusVendedoresService extends BaseService {
    private _domain: string = '/buyer/my-sellers';

    constructor(private accountService: AccountService, private config: SettingsService) {
        super();
    }

    public changeSeller(supplier_holding_id: number, seller_company_id: number): Observable<any> {
        if (isEmpty(this.usuarioLocal)) {
            this.accountService.logout();
            return EMPTY;
        }

        const buyer_company_id = this.usuarioLocal.user_company_id;
        const body = { supplier_holding_id, buyer_company_id, seller_company_id };

        return this.config.executePut({ url: `${this._domain}/change`, body });
    }

    public blockSupplier(supplier_holding_id: number): Observable<any> {
        if (isEmpty(this.usuarioLocal)) {
            this.accountService.logout();
            return EMPTY;
        }

        const repairer_holding_id = this.usuarioLocal.holding_id;

        return this.config.executePut({ url: `${this._domain}/${repairer_holding_id}/sellers/${supplier_holding_id}/block` });
    }

    public getAll(): Observable<any> {
        if (isEmpty(this.usuarioLocal)) {
            this.accountService.logout();
            return EMPTY;
        }

        const buyer_company_id = this.usuarioLocal.user_company_id;

        return this.config.executeGet({ url: `${this._domain}/${buyer_company_id}` });
    }

    public unblockSupplier(supplier_holding_id): Observable<any> {
        if (isEmpty(this.usuarioLocal)) {
            this.accountService.logout();
            return EMPTY;
        }

        const repairer_holding_id = this.usuarioLocal.holding_id;

        return this.config.executePut({ url: `${this._domain}/${repairer_holding_id}/sellers/${supplier_holding_id}/unblock` });
    }
}
