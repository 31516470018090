import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';

import { AccountService } from 'app/account/account.service';
import { BaseService } from 'app/_services/base.service';
import { SettingsService } from 'app/_services/settings.service';

declare const isEmpty: any;

@Injectable({ providedIn: 'root' })
export class BuyerNovaCotacaoService extends BaseService {
    private _domain: string = '/buyer/quotations';

    constructor(private accountService: AccountService, private config: SettingsService) {
        super();
    }

    public getImageBase64FromUrl(imageUrl: string): Observable<any> {
        return this.config.executeGet({ url: `${this._domain}/images?url=${imageUrl}` });
    }

    public getVehicleByLicensePlate(licensePlate: string): Observable<any> {
        return this.config.executeGet({ url: `${this._domain}/vehicles/${licensePlate}` });
    }

    public newQuotation(cotacao: any): Observable<any> {
        return this.config.executePost({ url: `${this._domain}`, body: cotacao });
    }

    public uploadQuotationFile(b64file: string | Blob, filename: string | Blob): Observable<any> {
        if (isEmpty(this.usuarioLocal)) {
            this.accountService.logout();
            return EMPTY;
        }

        var companyId = <any>this.usuarioLocal.company_id;
        var userId = <any>this.usuarioLocal.id;

        var formData = new FormData();
        formData.append("b64file", b64file);
        formData.append("filename", filename);
        formData.append("companyId", companyId);
        formData.append("userId", userId);

        return this.config.executePost({ url: `${this._domain}/upload-quotation-file`, body: formData, isJson: false });
    }
}
