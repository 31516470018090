import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { BuyerSemaforoGetListQuotationsResponseModel } from './_models/buyerSemaforoGetListQuotationsResponseModel';
import { CentralSemaforoCotacao } from '../semaforo-conversor/_models/cotacao';
import { Comentario } from '../_models/comentario';
import { FilterOption } from 'app/_componentes/header/_models/filterOption';
import { Globals } from 'app/_models/globals';
import { GoogleAnalyticsEventNames } from 'app/_models/googleAnalytics';
import { inDelay } from 'app/_utils/general-utils';
import { StatusIdEnum } from 'app/_models/statusId';
import { SweetAlertParams } from 'app/_models/notificationParams.model';
import { Transportadora } from 'app/_models/transportadora';
import { User } from 'app/_models/user';
import { WhatsAppMessage } from 'app/_models/whatsAppMessage';

import { BuyerSemaforoCuradorModalListaDevolucaoComponent } from './_componentes/modal-lista-devolucao/modal-lista-devolucao.component';

import { LocalStorageUtils } from 'app/_utils/localstorage';
import { LocalStorageUtilsBuyer } from '../_utils/localstoragebuyer';
import { inserirCotacaoNaLista } from 'app/_utils/general-utils';

import { AccountService } from 'app/account/account.service';
import { BuyerService } from '../_services/buyer.service';
import { BuyerSemaforoCuradorService } from './semaforo.service';
import { BuyerSignalRService } from '../_services/buyer.signalr.service';
import { BuyerPurchaseService } from '../comprar/comprar.service';
import { GeneralService } from 'app/_services/general.service';
import { NotificationService } from 'app/_services/notification.service';
import { Subscription } from 'rxjs';

declare const $: any;
declare const compararStringContemString: any;
declare const formatDateToDDMMYYYY: any;
declare const formatDateToDDMMYYYYHHMM: any;
declare const getJavascriptDateFromIsoDate: any;
declare const getJavascriptDateFromCSharpDate: any;
declare const isToday: any;
declare const isTomorrow: any;
declare const isEmpty: any;
declare const sort_by: any;

@Component({ selector: 'app-curador', templateUrl: './semaforo-curador.component.html', styleUrls: ['./semaforo-curador.component.scss'] })
export class BuyerSemaforoCuradorComponent implements OnInit, OnDestroy
{
    @ViewChild('modalListaDevolucao') modalListaDevolucao: BuyerSemaforoCuradorModalListaDevolucaoComponent
    modalRef: BsModalRef;

    private subscriptionAtualizarSemaforoCotacaoEvent: Subscription;
    private subscriptionAtualizarSemaforoDataAtualizacaoEvent: Subscription;

    private localStorageUtils = new LocalStorageUtils();
    private localStorageUtilsBuyer = new LocalStorageUtilsBuyer;
    public googleAnalyticsEventNames = new GoogleAnalyticsEventNames();

    public faseTroca = 'TRO';

    _searchFase: string;
    _searchInconsistencia: boolean;
    _searchLinkExterno: boolean;
    _searchAguardandoNota: boolean;
    _searchString: string;

    filterOptions: FilterOption[] = [];

    cotacoes: CentralSemaforoCotacao[];
    cotacoesFiltradas: CentralSemaforoCotacao[];
    currentUser: User;
    currentLocalUser: User;
    transportadoras: Transportadora[] = [];
    ultimaAtualizacao: Date;

    ordenar_por: string;
    reverse: boolean;

    Swal: any;

    inserirCotacaoNaLista: any = inserirCotacaoNaLista;
    listItemHeightCentral: number;

    get StatusIdEnum(): typeof StatusIdEnum {
        return StatusIdEnum;
    }

    constructor(private accountService: AccountService,
        private buyerService: BuyerService,
        private buyerSignalRService: BuyerSignalRService,
        private curadorService: BuyerSemaforoCuradorService, private buyerVisaoGeralComprarService: BuyerPurchaseService, private generalService: GeneralService,
        private notificationService: NotificationService, private globals: Globals, private router: Router, private ngxLoader: NgxUiLoaderService, private ngZone: NgZone) { }

    ngOnInit(): void
    {
        this.registerObservables();

        this.Swal = require('sweetalert2');
        this.currentUser = this.localStorageUtils.obterUsuario();
        this.currentLocalUser = this.localStorageUtils.obterUsuarioLocal();

        this.cotacoes = [];

        let dadosLS = this.localStorageUtilsBuyer.obterDadosCentralSemaforo(false);

        if (!isEmpty(dadosLS) && this.localStorageUtilsBuyer.checkIfIsActive(dadosLS))
        {
            this._searchInconsistencia = isEmpty(dadosLS.filtro.inconsistencias) ? undefined : dadosLS.filtro.inconsistencias;
            this._searchLinkExterno = isEmpty(dadosLS.filtro.link_externo) ? undefined : dadosLS.filtro.link_externo;
            this._searchAguardandoNota = isEmpty(dadosLS.filtro.aguardando_nota) ? undefined : dadosLS.filtro.aguardando_nota;
            this._searchString = isEmpty(dadosLS.filtro.texto) ? undefined : dadosLS.filtro.texto;
            this._searchFase = isEmpty(dadosLS.filtro.fase) ? undefined : dadosLS.filtro.fase;
        }

        this.filterOptions = [
            { value: "0", label: this.globals.translate('buyer.curador.cabecalho.filtro-fase.todos') },
            { value: "2", label: this.globals.translate('buyer.curador.cabecalho.filtro-fase.confirmacao') },
            { value: "3", label: this.globals.translate('buyer.curador.cabecalho.filtro-fase.entrega') },
            { value: "5", label: this.globals.translate('buyer.curador.cabecalho.filtro-fase.devolucao') }
        ]


        this.carregarTela(false, false, 0);

        window['angularComponentReference'] = { component: this, zone: this.ngZone, addComment: (order_id: number) => this.ver_comentarios_adicionar(order_id), };
        this.listItemHeightCentral = this.curadorService.listItemHeightCentral;
    }

    ngOnDestroy(): void {
        this.subscriptionAtualizarSemaforoCotacaoEvent.unsubscribe();
        this.subscriptionAtualizarSemaforoDataAtualizacaoEvent.unsubscribe();
    }

    async atualizarCotacoes(salvar_estado: boolean, reordenar: boolean = true)
    {
        if (reordenar)
            this.ordenar(this.cotacoes);

        this.cotacoesFiltradas = await this.filtrarCotacoes();

        if (salvar_estado)
            this.salvarEstadoTela();
    }

    atualizarDadosCotacao() {
        this.cotacoes.map(cotacao =>
        {
            // Atualiza os dados das datas
            cotacao.data_abertura_js = !isEmpty(cotacao.data_abertura) ? getJavascriptDateFromIsoDate(cotacao.data_abertura) : null;
            cotacao.data_compra_js = !isEmpty(cotacao.data_compra) ? getJavascriptDateFromIsoDate(cotacao.data_compra) : null;
            cotacao.data_entrega_js = !isEmpty(cotacao.data_entrega) ? getJavascriptDateFromIsoDate(cotacao.data_entrega) : null;
            cotacao.is_in_delay = inDelay(cotacao.data_entrega_js);

            // Verifica se deve liberar o envio de msg via whatsapp (se está aguardando confirmação ou está sem nota e faltam 2 dias para a entrega da peça)
            cotacao.tem_whatsapp = this.setarTemWhatsApp(cotacao);

            // Atualiza os dados de inconsistencia baseado no fato de ser urgente ou não
            cotacao.inconsistencia_urgente = cotacao.inconsistencia_tem && cotacao.inconsistencias.some(i => i.tipo === 1); // Somente inconsistencias do tipo cnpj são urgentes

            // Atualiza as informações referentes as NFs
            if (cotacao.status_id >= StatusIdEnum.Confirmado)
            {
                if (cotacao.nota_tem)
                    cotacao.nota_cor = 'success';
                else
                {
                    // Caso haja algum erro em cotação que já foi entregue
                    if (!isEmpty(cotacao.data_entrega_js))
                    {
                        let hoje = new Date();
                        let dias_para_entrega = (cotacao.data_entrega_js.getTime() - hoje.getTime()) / (1000 * 3600 * 24);

                        if (dias_para_entrega > 2)
                            cotacao.nota_cor = 'transparent-partsfy';
                        else if (dias_para_entrega === 2)
                            cotacao.nota_cor = 'warning';
                        else
                            cotacao.nota_cor = 'danger';
                    }
                }
            }
        });
    }

    carregarTela(ignorar_cache: boolean, ignorar_loading: boolean, nAttempts: number) {
        if (!ignorar_loading)
            this.ngxLoader.startLoader('loader-principal');

        this.curadorService.listQuotations(false, ignorar_cache).subscribe({
            next: (response: ApiResponseModel<BuyerSemaforoGetListQuotationsResponseModel>) => {

                if (response != null)
                    this.definirEstadoTela(response.result.quotations);

                if (ignorar_cache)
                    $('.scrollable .ng-scroll-viewport').scrollTop(0);

                this.ultimaAtualizacao = response.result.dataGravacao;
                this.transportadoras = response.result.transportadoras;

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: (error) => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);
                this.ngxLoader.stopLoader('loader-principal');
                return;
            }
        });
    }

    definirEstadoTela(cotacoes: CentralSemaforoCotacao[])
    {
        this.cotacoes = cotacoes;

        this.atualizarDadosCotacao();

        this.ordenar_por = "status_id";
        this.reverse = false;

        this.atualizarCotacoes(true);
    }

    async filtrarCotacoes()
    {
        if (this.searchInconsistencia === undefined)
            this._searchInconsistencia = false;

        if (this.searchLinkExterno === undefined)
            this._searchLinkExterno = false;

        if (this.searchAguardandoNota === undefined)
            this._searchAguardandoNota = false;

        if (this.searchTerm === undefined)
            this._searchString = '';

        if (this.searchType === undefined)
            this._searchFase = '0';

        if (!this.cotacoes)
            return this.cotacoes;

        let term: string = this.searchTerm.toLowerCase().replace('-', '');
        let filtro_fase: any = [1, 2, 3, 5, 6, 7, 8];

        switch (this.searchType)
        {
            case '2': // somente aguardando confirmação
                filtro_fase = [1, 2];
                break;
            case '3': // somente aguardando entrega
                filtro_fase = [3];
                break;
            case '5': // somente devolvidos
                filtro_fase = [5, 6, 7, 8];
                break;
        }

        var result = this.cotacoes.filter(cotacao =>
            (!this._searchInconsistencia || cotacao.inconsistencia_tem) &&

            (!this._searchLinkExterno || cotacao.is_link_externo) &&

            (!this._searchAguardandoNota || (!cotacao.nota_tem && cotacao.nota_cor == 'danger')) &&

            filtro_fase.some(t => t === cotacao.status_id) &&

            (
                cotacao.quotation_id.toString().indexOf(term) !== -1 ||
                cotacao.order_id.toString().indexOf(term) !== -1 ||
                (!isEmpty(cotacao.veiculo) && compararStringContemString(cotacao.veiculo.replace('-', ''), term)) ||
                compararStringContemString(cotacao.marca, term) ||
                compararStringContemString(cotacao.oficina, term) ||
                compararStringContemString(cotacao.comprador, term) ||
                compararStringContemString(cotacao.fornecedor, term) ||
                compararStringContemString(cotacao.vendedor, term) ||
                cotacao.notasFiscais.some(nf => compararStringContemString(nf.numero, term)) ||
                (
                    cotacao.semaforo_cor.toLowerCase() === 'warning' && 'laranja'.indexOf(term) !== -1 ||
                    cotacao.semaforo_cor.toLowerCase() === 'danger' && 'vermelho'.indexOf(term) !== -1 ||
                    cotacao.semaforo_cor.toLowerCase() === 'success' && 'verde'.indexOf(term) !== -1
                )
            )
        );

        return result;
    }

    private goToCotacao(event: MouseEvent, cotacao: CentralSemaforoCotacao)
    {
        let func_ver = () =>
        {
            $('.multi-usuario').val(cotacao.buyer_id);

               let isCtrlKey = event.ctrlKey;

                if (cotacao.status_id === StatusIdEnum.Cotacao) {
                    let buyerRoute = '/comprador/visao-geral/comprar/' + cotacao.quotation_id;

                    if (isCtrlKey)
                        window.open(buyerRoute, '_blank');
                    else
                        this.router.navigate([buyerRoute]);
                }
                else {
                    let transactionRoute = '/comprador/visao-geral/pedido/' + cotacao.transaction_id;

                    if (isCtrlKey)
                        window.open(transactionRoute, '_blank');
                    else
                        this.router.navigate([transactionRoute]);
                }
        };

        // Se o usuário local logado for diferente do usuário selecionado, faz a troca
        if (this.currentLocalUser.id !== cotacao.buyer_id) {
            this.generalService.closeQuotations()
            this.accountService.trocarUsuario(cotacao.buyer_id, this.currentUser, func_ver);
        } else
            func_ver();
    }

    private goToInconsistencias(cotacao: CentralSemaforoCotacao)
    {
        this.router.navigateByUrl('/comprador/curador/inconsistencias/' + cotacao.quotation_id, { state: { pedido: cotacao, transportadoras: this.transportadoras } });
    }

    private obterTextoDiaEntrega(data_entrega: Date): string
    {
        // Se a data for hoje
        if (isToday(data_entrega))
            return this.globals.translate('geral.dias-semana.hoje');

        // Se a data for amanhã
        if (isTomorrow(data_entrega))
            return this.globals.translate('geral.dias-semana.amanha');

        // Se for algum outro dia da semana
        switch (data_entrega.getDay())
        {
            case 0: // domingo
                return this.globals.translate('geral.dias-semana.domingo');
            case 1: // segunda
                return this.globals.translate('geral.dias-semana.segunda');
            case 2: // terça
                return this.globals.translate('geral.dias-semana.terca');
            case 3: // quarta
                return this.globals.translate('geral.dias-semana.quarta');
            case 4: // quinta
                return this.globals.translate('geral.dias-semana.quinta');
            case 5: // sexta
                return this.globals.translate('geral.dias-semana.sexta');
            case 6: // sabado
                return this.globals.translate('geral.dias-semana.sabado');
        }

        return '';
    }

    ordenar(cotacoes: any[]) {
        cotacoes.sort(sort_by([{ name: this.ordenar_por, reverse: this.reverse }, { name: 'fase' }, { name: 'data_entrega_js' }, { name: 'data_compra_js' }, { name: 'data_abertura_js' }, { name: 'vehicle' }, { name: 'oficina' }, { name: 'quotation_id' }]));
    }

    ordenar_atualizar(coluna)
    {
        if (this.ordenar_por === coluna)
            this.reverse = !this.reverse;
        else
        {
            this.ordenar_por = coluna;
            this.reverse = false;
        }

        this.atualizarCotacoes(false);
    }

    openPickedUpList($event, cotacao: CentralSemaforoCotacao){
        $event.stopPropagation();
        const pecas = cotacao.pecas.filter((peca => peca.status_id == StatusIdEnum.AguardandoDevolucao || peca.status_id == StatusIdEnum.AguardandoConfirmacaoDevolucao));
        this.modalListaDevolucao.open(cotacao.order_id, cotacao.quotation_id, pecas);
    }

    redirecionar($event: MouseEvent, cotacao: CentralSemaforoCotacao)
    {
        $event.stopPropagation();

        if (!cotacao.inconsistencia_tem)
            this.goToCotacao($event, cotacao);
        else
            this.goToInconsistencias(cotacao);
    }

    async salvarEstadoTela()
    {
        this.localStorageUtilsBuyer.salvarDadosCentralSemaforo({ fase: this.searchType, inconsistencias: this.searchInconsistencia, link_externo: this.searchLinkExterno, aguardando_nota: this.searchAguardandoNota, texto: this.searchTerm }, false);
    }

    signalRAtualizarCotacao(cotacao: CentralSemaforoCotacao)
    {
        if (this.buyerSignalRService.enableSignalRLog)
            console.log('BuyerSemaforoConversorComponent.signalRAtualizarCotacao', cotacao);

        if(cotacao.order_id == 0) return

        let index = this.cotacoes.findIndex(q => q.order_id === cotacao.order_id);

        if(cotacao.status_id <= 0 && index < 0) return

        if (!cotacao.is_active && index >= 0) {
            this.cotacoes.splice(index, 1);
            this.atualizarCotacoes(false, false);
            return;
        }

        // Verifica se deve liberar o envio de msg via whatsapp (se está aguardando confirmação ou está sem nota e faltam 2 dias para a entrega da peça)
        cotacao.tem_whatsapp = this.setarTemWhatsApp(cotacao);

        // Atualiza os dados de inconsistencia baseado no fato de ser urgente ou não
        cotacao.inconsistencia_urgente = cotacao.inconsistencia_tem && cotacao.inconsistencias.some(i => i.tipo === 1); // Somente inconsistencias do tipo cnpj são urgentes

        cotacao.data_abertura_js = !isEmpty(cotacao.data_abertura) ? new Date(cotacao.data_abertura) : null;
        cotacao.data_compra_js = !isEmpty(cotacao.data_compra) ? new Date(cotacao.data_compra) : null;
        cotacao.data_entrega_js = !isEmpty(cotacao.data_entrega) ? new Date(cotacao.data_entrega) : null;
        cotacao.is_in_delay = inDelay(cotacao.data_entrega_js);

        // Atualiza as informações referentes as NFs
        if (cotacao.status_id >= 3) {
            if (cotacao.nota_tem)
                cotacao.nota_cor = 'success';
            else
            {
                // Caso haja algum erro em cotação que já foi entregue
                if (!isEmpty(cotacao.data_entrega_js))
                {
                    let hoje = new Date();
                    let dias_para_entrega = (cotacao.data_entrega_js.getTime() - hoje.getTime()) / (1000 * 3600 * 24);

                    if (dias_para_entrega > 2)
                        cotacao.nota_cor = 'transparent-partsfy';
                    else if (dias_para_entrega === 2)
                        cotacao.nota_cor = 'warning';
                    else
                        cotacao.nota_cor = 'danger';
                }
            }
        }

        if(cotacao.comentarios) {
            cotacao.comentarios.forEach(c => c.data = getJavascriptDateFromCSharpDate(c.data));
        }

        this.inserirCotacaoNaLista(this.cotacoes, cotacao, index, (cotacoes) => this.ordenar(cotacoes), () => this.atualizarCotacoes(false, false));
    }

    setarTemWhatsApp(cotacao: CentralSemaforoCotacao): boolean {
        return cotacao.status_id < StatusIdEnum.Confirmado || (cotacao.status_id >= StatusIdEnum.Confirmado && (!cotacao.nota_tem || !cotacao.todas_pecas_associadas_nf) && ((cotacao.semaforo_cor === 'warning') || (cotacao.semaforo_cor === 'danger')));
    }

    ver_comentarios_adicionar(order_id: number) {
        let texto = $('.novo-comentario').val();

        if (!texto || texto.trim().length === 0)
            return;

        const pedido = this.cotacoes.find(o => o.order_id === order_id);
        let status_id = pedido.status_id.toString();

        if (pedido.status_id === 3) {
            status_id = pedido.is_troca ? `${status_id}.troca` : `${status_id}.entrega`;
        }

        const abrev = this.globals.translate(`geral.fase-cotacao.abreviacao.${status_id}`);
        const comentario = `${abrev} - ${texto}`;

        let usuario_nome = this.currentUser.name;
        let dataFormatada = formatDateToDDMMYYYYHHMM(new Date(), '/');
        let novoComentario: Comentario = { quotation_id: pedido.quotation_id, order_id: order_id, data: new Date(), comentario, usuario: { userCompanyId: this.currentUser.user_company_id, nome: usuario_nome } };

        let htmlLinhaComentario = this.ver_comentarios_adicionar_linha(usuario_nome, comentario, dataFormatada);

        $('.lista-comentarios').prepend(htmlLinhaComentario);

        $('.novo-comentario').val("");

        this.buyerVisaoGeralComprarService.addComment(novoComentario).subscribe({
            next: () => {
                let pedidoSelecionado = this.cotacoes.find(o => o.order_id === order_id);

                if (pedidoSelecionado)
                {
                    pedidoSelecionado.comentarios_tem = true;
                    pedidoSelecionado.comentarios.push(novoComentario);
                }
            },
            error: () => { }
        });
    }

    ver_comentarios_adicionar_linha(nome_usuario: string, comentario: string, data: string): string
    {
        var html = '';

        html += "<div class=\"linha-comentario\">";
        html += "   <div class=\"linha\">";
        html += "       <div class=\"col\">";
        html += `           <label>${nome_usuario} [${data}]</label>`;
        html += "       </div>";
        html += "   </div>";
        html += "   <div class=\"linha\">";
        html += "       <div class=\"col\">";
        html += `           <span>${comentario}</span>`;
        html += "       </div>";
        html += "   </div>";
        html += "</div>";

        return html;
    }

    ver_comentarios($event, cotacao: CentralSemaforoCotacao)
    {
        $event.stopPropagation();

        var html = "<div class='col-md-12 comentarios'>";
        let titulo = this.globals.translate('buyer.semaforo.tabela.comentarios.titulo');

        html += "<div class=\"linha\">";
        html += "   <div class=\"col cabecalho\">";
        html += `       <label class="col-form-label">${titulo}:</label>`;
        html += "   </div>";
        html += "</div>";

        html += `<div class="linha-add-comentario">`;
        html += `   <div>`;
        html += `       <textarea class="form-control novo-comentario"></textarea>`;
        html += `   </div>`;
        html += `   <div>`;
        html += `       <button type="button" class="btn btn-primary btn-novo-comentario" onclick="callAngularFunction('addComment', ${cotacao.order_id});">${this.globals.translate('buyer.cotacao.area-comprar.modal-mais-detalhes.comentarios.botao-adicionar')}</button>`;
        html += `   </div>`;
        html += `</div>`;

        html += "<div class=\"lista-comentarios\">";

        const comentarios = cotacao.comentarios.filter(c => c.order_id == null || c.order_id == cotacao.order_id).sort(sort_by([{ 'name': 'data', reverse: true }]));

        for (var i = 0; i <= comentarios.length - 1; i++)
        {
            let comentario = comentarios[i];
            let time = new Date(comentario.data + 'Z').toLocaleTimeString();
            let data = new Date(comentario.data + 'Z').toLocaleDateString() + ' ' + time.substring(0, time.length - 3);

            html += this.ver_comentarios_adicionar_linha(comentario.usuario.nome, comentario.comentario, data);
        }

        html += "</div>";
        html += "</div>";

        let params: SweetAlertParams = {
            title: cotacao.veiculo,
            notTranslateTitle: true,
            message: html,
            isHtml: true,
            icon: 'info',
        }

        this.notificationService.showInfoWithoutButtons(params);
    }

    whatsapp_enviar($event, cotacao: CentralSemaforoCotacao)
    {
        $event.stopPropagation();

        let urlPartsfy = this.generalService.getPartsfyUrl();

        let urlCotacao = urlPartsfy + '/vendedor/visao-geral/' + (cotacao.status_id < StatusIdEnum.Confirmado ? 'confirmar-pedido' : 'detalhe') + '/' + cotacao.order_id;
        let oficina = cotacao.oficina;
        let veiculo = cotacao.marca + ' - ' + cotacao.veiculo;
        let vendedor_nome = cotacao.vendedor;
        let whatsapp = cotacao.vendedor_whatsapp;
        let vendedor_email = cotacao.vendedor_email;
        let vendedor_id = cotacao.seller_id;

        let whatsapp_message: WhatsAppMessage = { is_from_support: false, chassi: '', oficina: oficina, urlPartsfy: urlPartsfy, urlCTA: urlCotacao, veiculo: veiculo, vendedor_nome: vendedor_nome, vendedor_whatsapp: whatsapp, vendedor_email, vendedor_id };


        if (cotacao.status_id < StatusIdEnum.Confirmado)
            this.generalService.whatsapp_enviar_aguardando_confirmacao(whatsapp_message, 0);
        else {
            let data_entrega = formatDateToDDMMYYYY(cotacao.data_entrega_js, '/');
            let tipo = inDelay(cotacao.data_entrega_js) ? 'atraso' : (isToday(cotacao.data_entrega_js) ? 'dia' : 'antes');
            let texto_dia_entrega = (tipo === 'antes') ? this.obterTextoDiaEntrega(cotacao.data_entrega_js) : '';
            whatsapp_message.is_from_support = true;

            if (tipo === 'atraso')
                this.generalService.whatsapp_enviar_atrasado(whatsapp_message, data_entrega, tipo, 0);
            else
                this.generalService.whatsapp_enviar_cobrar_nota_fiscal(whatsapp_message, texto_dia_entrega, tipo, 0);
        }
    }




    get searchInconsistencia(): boolean
    {
        return this._searchInconsistencia;
    }

    set searchInconsistencia(value: boolean)
    {
        this._searchInconsistencia = value;
        this.atualizarCotacoes(true);
    }

    get searchLinkExterno(): boolean
    {
        return this._searchLinkExterno;
    }

    set searchLinkExterno(value: boolean)
    {
        this._searchLinkExterno = value;
        this.atualizarCotacoes(true);
    }

    get searchAguardandoNota(): boolean
    {
        return this._searchAguardandoNota;
    }

    set searchAguardandoNota(value: boolean)
    {
        this._searchAguardandoNota = value;
        this.atualizarCotacoes(true);
    }

    get searchTerm(): string
    {
        return this._searchString;
    }

    set searchTerm(value: string)
    {
        this._searchString = value;
        this.atualizarCotacoes(false);
    }

    get searchType(): string
    {
        return this._searchFase;
    }

    set searchType(value: string)
    {
        this._searchFase = value;
        this.atualizarCotacoes(true);
    }

    registerObservables() {
        this.subscriptionAtualizarSemaforoCotacaoEvent = this.buyerSignalRService.AtualizarSemaforoCotacaoEvent.subscribe(data => {
            if (this.buyerSignalRService.enableSignalRLog)
                console.log('BuyerSemaforoConversorComponent.AtualizarSemaforoCotacaoEvent', data);

            let cotacoes = JSON.parse(data);

            cotacoes.forEach(cotacao => {
                this.signalRAtualizarCotacao(cotacao);
            });
        });

        this.subscriptionAtualizarSemaforoDataAtualizacaoEvent = this.buyerSignalRService.AtualizarSemaforoDataAtualizacaoEvent.subscribe(data => {
            if (this.buyerSignalRService.enableSignalRLog)
                console.log('BuyerSemaforoConversorComponent.AtualizarSemaforoDataAtualizacaoEvent', data);

            let response = JSON.parse(data);
            this.ultimaAtualizacao = response.ultimaAtualizacao;
        });
    }
}
