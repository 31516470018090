import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from 'app/_services/base.service';
import { SettingsService } from 'app/_services/settings.service';
import { Peca } from './_models/Peca';

@Injectable({ providedIn: 'root' })
export class BuyerMercadoPartsfyListService extends BaseService {
    private _domain: string = '/buyer/mercado-partsfy';

    constructor(private config: SettingsService) {
        super();
    }

    public getParts(categoryId: number, onlyWithCashback: boolean, brandList: number[] = [], actualPage: number = 1): Observable<any> {
        const brandListString = brandList.join('&brandList=');
        const brandParameter = brandListString.length > 0 ? '&brandList=' + brandListString : '';
        const repairerCompanyId = this.usuarioLocal.company_id;
        return this.config.executeGet({ url: `${this._domain}?repairerCompanyId=${repairerCompanyId}&categoryId=${categoryId}${brandParameter}&onlyWithCashback=${onlyWithCashback}&currentPage=${actualPage}` });
    }

    public getCashbackValue(part: Peca, quantity: number) {
        return (part.cashback / 100) * part.priceWithDiscount * this.config.defaultPartsfyCashbackPercentage * quantity
    }

    public getCategories(): Observable<any> {
        return this.config.executeGet({ url: `${this._domain}/categories` });
    }

    public getCompanyAddress(): Observable<any> {
        return this.config.executeGet({ url: `${this._domain}/company-address/${this.usuarioLocal.company_id}` });
    }

    public getCompanyRelationship(): Observable<any> {
        return this.config.executeGet({ url: `${this._domain}/company-relationship/${this.usuarioLocal.company_id}` });
    }

    public getDeliveryDate(delivery_time_name: string): Observable<any> {
        return this.config.executeGet({ url: `/general/common/get-delivery-date-based-on-today/${delivery_time_name}` });
    }

    public getMontadoras(categoryId: number): Observable<any> {
        return this.config.executeGet({ url: `${this._domain}/brands?repairerCompanyId=${this.usuarioLocal.company_id}&categoryId=${categoryId}` });
    }

    public purchase(userCompanyId: any, purchases: any): Observable<any> {
        const body = { userCompanyId, purchases }
        return this.config.executePost({ url: `${this._domain}`, body });
    }
}
