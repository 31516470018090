import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ItemPorHolding } from '../../../../_models/ItemPorHolding';
import { Carrinho } from '../../../../_models/Carrinho';

declare const formatDateToDDMMYYYY: any;

@Component({ selector: 'app-footer', templateUrl: './footer.component.html', styleUrls: ['./footer.component.scss'] })
export class BuyerMercadoPartsfyListShoppingCartFooterComponent implements OnInit {
    @Input() shoppingCart: Carrinho
    @Input() selectedHolding: ItemPorHolding

    @Output() completePurchase = new EventEmitter<any>();
    @Output() closeModal = new EventEmitter<any>();

    @Input() deliveryPrice: number;
    @Input() itemsPrice: number;
    @Input() selectedQuantity: number;

    ngOnInit(): void { }

    close() {
        this.closeModal.emit()
    }

    formatDate(date: string) {
        return formatDateToDDMMYYYY(new Date(date));
    }
}
