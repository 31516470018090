<div class="central-semaforo" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [filterInput]="searchType"
        [filterOptions]="filterOptions"
        [hasBackButton]="false"
        [hasSearchButton]="false"
        [searchPlaceholder]="'buyer.semaforo.cabecalho.placeholder' | translate"
        [searchInput]="searchTerm"
        (onFilter)="searchType = $event"
        (onSearch)="searchTerm = $event"
    >
        <div class="col-md-4 filtros">
            <div class="input-group">
                <div class="form-check c-checkbox needsclick checkboxcot">
                    <label class="needsclick">
                        <input type="checkbox" class="needsclick" [(ngModel)]="searchInconsistencia" (blur)="salvarEstadoTela()" />
                        <span class="fa fa-check"></span> {{'buyer.curador.cabecalho.filtro-inconsistencias' | translate}}
                    </label>
                </div>
            </div>
            <div class="input-group">
                <div class="form-check c-checkbox needsclick checkboxcot">
                    <label class="needsclick">
                        <input type="checkbox" class="needsclick" [(ngModel)]="searchLinkExterno">
                        <span class="fa fa-check"></span> {{'buyer.curador.cabecalho.filtro-link-externo' | translate}}
                    </label>
                </div>
            </div>
            <div class="input-group">
                <div class="form-check c-checkbox needsclick checkboxcot">
                    <label class="needsclick">
                        <input type="checkbox" class="needsclick" [(ngModel)]="searchAguardandoNota">
                        <span class="fa fa-check"></span> {{'buyer.curador.cabecalho.filtro-aguardando-nota' | translate}}
                    </label>
                </div>
            </div>
        </div>
        <div class="atualizar-tela">
            <button [eventTracker]='{"event_category": googleAnalyticsEventNames.atualizarTelaCentral, "page": "curador"}' class="btn btn-primary btn-sm mt0" (click)="carregarTela(true, false, 0)"><i class="fa fa-refresh"></i> {{'buyer.semaforo.cabecalho.atualizar' | translate}}</button>
            <small>{{'buyer.semaforo.cabecalho.ultima-atualizacao' | translate}}: {{ ultimaAtualizacao | date:'dd/MM/yyyy HH:mm' }}</small>
        </div>
    </app-header>

    <div class="cotacoes">

        <div class="card card-default">
            @if ((cotacoesFiltradas)?.length == 0) {
                <div class="text-center vazio">
                    <p class="mb0">{{'buyer.curador.mensagens.nenhuma-cotacao-encontrada' | translate}}</p>
                </div>
            } @else {
                <section class="d-flex flex-wrap striped bordered">
                    <header>
                        <div (click)="ordenar_atualizar('semaforo')" class="col-0-5">
                            {{ 'buyer.curador.tabela.semaforo' | translate}}
                            @if (ordenar_por == 'semaforo') {
                                <span><em class="fa fa-caret-{{reverse ? 'down' : 'up'}}"></em></span>
                            }
                        </div>
                        <div (click)="ordenar_atualizar('status_id')" class="col text-center">
                            {{ 'buyer.curador.tabela.fase' | translate}}
                            @if (ordenar_por == 'status_id') {
                                <span><em class="fa fa-caret-{{reverse ? 'down' : 'up'}}"></em></span>
                            }
                        </div>
                        <div (click)="ordenar_atualizar('oficina')" class="col-2">
                            <span>{{ 'buyer.curador.tabela.oficina' | translate}}</span>
                            @if (ordenar_por == 'oficina') {
                                <span><em class="fa fa-caret-{{reverse ? 'down' : 'up'}}"></em></span>
                            }
                        </div>
                        <div (click)="ordenar_atualizar('order_id')" class="col text-center">
                            {{ 'buyer.curador.tabela.id' | translate}}
                            @if (ordenar_por == 'order_id') {
                                <span><em class="fa fa-caret-{{reverse ? 'down' : 'up'}}"></em></span>
                            }
                        </div>
                        <div (click)="ordenar_atualizar('veiculo')" class="col-4">
                            <span>{{ 'buyer.curador.tabela.veiculo' | translate}}</span>
                            @if (ordenar_por == 'veiculo') {
                                <span><em class="fa fa-caret-{{reverse ? 'down' : 'up'}}"></em></span>
                            }
                        </div>
                        <div (click)="ordenar_atualizar('fornecedor')" class="col-2">
                            <span>{{ 'buyer.curador.tabela.fornecedor' | translate}}</span>@if (ordenar_por == 'fornecedor') {
                                <span><em class="fa fa-caret-{{reverse ? 'down' : 'up'}}"></em></span>
                            }
                        </div>
                        <div class="col-5">{{ 'buyer.curador.tabela.detalhes' | translate}}</div>
                        <div class="col"></div>
                    </header>
                    <ng-scrollbar class="corpo scrollable" externalViewport="cdk-virtual-scroll-viewport">
                        <cdk-virtual-scroll-viewport [itemSize]="listItemHeightCentral">
                            <div *cdkVirtualFor="let cotacao of cotacoesFiltradas" class="linha cursor-pointer" (click)="redirecionar($event, cotacao)" [ngClass]="{ 'inconsistencia': cotacao.inconsistencia_tem, 'urgente': cotacao.inconsistencia_urgente, 'normal': !cotacao.inconsistencia_urgente }">
                                <div class="col-0-5 text-center">
                                    @if (cotacao.status_id < StatusIdEnum.Confirmado || (cotacao.status_id >= StatusIdEnum.Confirmado && cotacao.semaforo === 1)) {
                                        <i class="fa fa-file-text-o text-transparent-partsfy"></i>
                                    }
                                    @if (cotacao.status_id >= StatusIdEnum.Confirmado && cotacao.semaforo >= 2) {
                                        <i class="fa fa-file-text-o text-{{cotacao.nota_cor}}"></i>
                                    }
                                    <i class="fa fa-hourglass-half text-{{cotacao.semaforo_cor}}"></i>
                                </div>
                                <div class="col text-center" tooltip="{{cotacao.motivo}}">{{cotacao.fase}}</div>
                                <div class="col-2 truncate">{{cotacao.oficina}}</div>
                                <div class="col text-center">{{cotacao.order_id}}</div>
                                <div class="col-4 truncate"><i class="rounded-circle marca {{cotacao.marca}} float-start"></i> {{cotacao.veiculo}}</div>
                                <div class="col-2 truncate">{{cotacao.fornecedor}} ({{cotacao.vendedor}})</div>
                                <div class="col-5">
                                    @if (cotacao.status_id > StatusIdEnum.Cotacao && cotacao.status_id < StatusIdEnum.Confirmado) {
                                        <div>
                                            <span>{{ 'buyer.curador.tabela.dados.data-compra' | translate}}: {{cotacao.data_compra | date:'dd/MM/yyyy HH:mm'}}</span>
                                            @if (cotacao.status_id === StatusIdEnum.EmAnalise) {
                                                <span>| {{ 'buyer.curador.tabela.dados.em-analise' | translate}}</span>
                                            }
                                        </div>
                                    }
                                    @if (cotacao.status_id >= StatusIdEnum.Confirmado) {
                                        <div>
                                            @if (cotacao.status_id == StatusIdEnum.AguardandoConfirmacaoDevolucao || cotacao.status_id == StatusIdEnum.AguardandoConfirmacaoTroca) {
                                                <span>{{ 'buyer.curador.tabela.dados.aguardando-confirmacao' | translate}}</span>
                                            } @else {
                                                <span>{{ 'buyer.curador.tabela.dados.data-entrega' | translate}}: {{cotacao.data_entrega | date:'dd/MM/yyyy'}}</span>
                                            }
                                            |
                                            <span>{{ 'buyer.curador.tabela.dados.total-pecas-entregues' | translate}}: {{cotacao.total_pecas_entregues}}/{{cotacao.total_pecas}}</span>
                                        </div>
                                    }
                                </div>
                                <div class="col action-icons">
                                    <div (click)="ver_comentarios($event, cotacao)">
                                        <i class="fa fa-comments-o popup fa-1x" [ngClass]="{ 'color-grey': !cotacao.comentarios_tem, 'color-link': cotacao.comentarios_tem }"></i>
                                    </div>
                                    @if (cotacao.tem_whatsapp && cotacao.fase != faseTroca && (cotacao.tem_nota_pendente || cotacao.is_in_delay || cotacao.status_id < 3)) {
                                        <div (click)="whatsapp_enviar($event, cotacao)">
                                            <i [ngClass]="{'is-cobranca-nota': cotacao.tem_nota_pendente}" class="fa fa-whatsapp popup fa-1x text-success"></i>
                                        </div>
                                    }
                                    @if (cotacao.status_id == StatusIdEnum.AguardandoDevolucao) {
                                        <div (click)="openPickedUpList($event, cotacao)">
                                            <i-feather class="popup text-danger check-square-icon" name="check-square"
                                                container="body" ngbTooltip="{{ 'buyer.dados-pedido.botoes.marcar-devolvido' | translate}}">
                                            </i-feather>
                                        </div>
                                    }
                                </div>
                            </div>
                        </cdk-virtual-scroll-viewport>
                    </ng-scrollbar>
                </section>
            }
        </div>
    </div>
</div>

<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>

<app-modal-lista-devolucao-component #modalListaDevolucao></app-modal-lista-devolucao-component>
