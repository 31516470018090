<div class="shopping-cart-part-description">
    <div class="left-section">
        <img [src]="settingService.pathImagesMercadoPartsfy + (item.part.urlImages[0] ? item.part.urlImages[0] : '/not-found.png')" alt="">
        <div class="left-section-details">
            <p class="truncate-multiple-lines" [tooltip]="item.part.partName" [zIndex]="2000">{{item.part.partName}}</p>
        </div>
    </div>
    <div class="right-section">
        <div class="shopping-cart-controls">
            <input type="number" min="1" max="999" [value]="item.quantity" [name]="item.part.partId" (change)="updatePartQuantity($event, item.part.partId)">
            <i-feather name="trash-2" (click)="removeItemFromCart(item.part.partId)"></i-feather>
        </div>
        <div class="prices-info">
            <p>{{item.part.priceWithDiscount * item.quantity | currency:'R$' }}</p>
            @if (item.part.cashback) {
                <div class="cashback">
                    <i-feather name="rotate-ccw"></i-feather>
                    {{ cashbackValue    | currency: 'R$' }}
                </div>
            }
        </div>
    </div>
</div>
