import { Component, EventEmitter, Input, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { SettingsService } from 'app/_services/settings.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { Carrinho } from '../../_models/Carrinho';
import { Compras } from '../../_models/Compras';
import { Globals } from 'app/_models/globals';
import { ItemPorHolding } from '../../_models/ItemPorHolding';
import { RelationshipsList } from '../../_models/RelationshipsList';
import { SweetAlertParams } from 'app/_models/notificationParams.model';

import { BuyerMercadoPartsfyListService } from '../../mercado-partsfy-list.service';
import { NotificationService } from 'app/_services/notification.service';

declare const $: any;
declare const formatDateToDDMMYYYY: any;

@Component({ selector: 'app-shopping-cart', templateUrl: './shopping-cart.component.html', styleUrls: ['./shopping-cart.component.scss'] })
export class BuyerMercadoPartsfyListShoppingCartComponent
{
    pricePerHolding: any[] = [];

    @Input() visible: boolean;
    @Input() shoppingCart: Carrinho;
    @Input() companyRelationship: RelationshipsList;
    @Output() deleteCartItems = new EventEmitter<any>();
    @Output() saveCart = new EventEmitter<any>();
    @Output() setShoppingCartTotalQuantity = new EventEmitter<any>();
    @ViewChild('templateShoppingCart') template: TemplateRef<any>;
    modalRef: BsModalRef;

    selectedHolding: ItemPorHolding;

    Swal: any;

    deliveryPrice: number;
    itemsPrice: number;
    totalQuantity: number;
    selectedQuantity: number;

    constructor(
        private buyerMercadoPartsfyListService: BuyerMercadoPartsfyListService,
        private modalService: BsModalService, private ngxLoader: NgxUiLoaderService,
        private notificationService: NotificationService, public settingService: SettingsService,
         private globals: Globals)
    {
        this.Swal = require('sweetalert2');
        this.visible = false;
    }

    ngOnInit() { }

    public backToHoldings(holding: ItemPorHolding = null) {
        this.selectedHolding = null;
        this.updateItemsInfo(holding)
    }

    async close(): Promise<void> {
        this.modalRef.hide()
    }

    confirmPurchase() {
        let params: SweetAlertParams = {
            icon: 'warning',
            confirmButtonText: 'buyer.mercado-partsfy.carrinho.confirmacao.sim',
            cancelButtonText: 'buyer.mercado-partsfy.carrinho.confirmacao.nao',
            title: 'buyer.mercado-partsfy.carrinho.confirmacao.titulo',
            message: 'buyer.mercado-partsfy.carrinho.confirmacao.mensagem',
            customClass: { validationMessage: 'swal-hidden' }
        }

        this.notificationService.showConfirm(params).then((result) => {
            if (result.isConfirmed)
                this.completePurchase()
        });
    }

    completePurchase() {
        this.ngxLoader.startLoader('loader-shopping-cart');

        let purchases: Compras[] = [];

        this.shoppingCart.itemsPerHolding.forEach(holding => {
            const paymentTypesId = this.companyRelationship.formasPagamento
                                    .filter(pt => pt.holding_id === holding.id && pt.start_value <= holding.items.reduce((total, item) => total + item.part.priceWithDiscount * item.quantity, 0))
                                    .map(pt => (pt.payment_type_id));

            let installments = Math.max.apply(Math, paymentTypesId.length == 0 ? [0] : paymentTypesId);

            let holdingObject: Compras = {
                selectedPaymentType: installments,
                deliveryTimeId: holding.delivery_time_id,
                holdingId: holding.id,
                brandId: holding.items[0].part.brandId,
                rebatePercentage: holding.items[0].part.cashback,
                shippingCost: holding.delivery_price,
                parts: holding.items.map(item => {
                    return {
                        partId: item.part.partId,
                        partName: item.part.partName,
                        partNumber: item.part.partNumber,
                        quantity: item.quantity,
                        price: item.part.price,
                        discount: item.part.discount,
                        deliveryDate: holding.delivery_time
                    }
                })
            }

            purchases.push(holdingObject)
        })

        let userCompanyId = this.buyerMercadoPartsfyListService.usuarioLocal.user_company_id

        this.buyerMercadoPartsfyListService.purchase(userCompanyId, purchases).subscribe({
            next: (response) => {
                this.ngxLoader.stopLoader('loader-shopping-cart');
                if(!response.success) this.notificationService.showErrorToastr(this.globals.translate('buyer.mercado-partsfy.carrinho.mensagem.finalizar-compra.erro'), { override: { timeOut: 3000 } })

                this.close();

                this.notificationService.showSuccessToastr(this.globals.translate('buyer.mercado-partsfy.carrinho.mensagem.finalizar-compra.sucesso'))
                this.deleteCartItems.emit();
                this.totalQuantity = 0;
                this.setShoppingCartTotalQuantity.emit(this.totalQuantity)
            },
            error: (error) => {
                this.ngxLoader.stopLoader('loader-shopping-cart');
                this.notificationService.showErrorToastr(this.globals.translate('buyer.mercado-partsfy.carrinho.erro.finalizar-compra.corpo'), { override: { timeOut: 3000 } })
                console.log(error);
            }
        })
    }

    formatDate(date: string) {
        return formatDateToDDMMYYYY(new Date(date));
    }

    getDeliveryPrice() {
        return this.shoppingCart.itemsPerHolding.reduce((total, holding) => total + holding.delivery_price, 0)
    }

    getQuantityPerHolding(holding: ItemPorHolding) {
        return holding.items.reduce((total, item) => total + item.quantity, 0)
    }

    open(): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            this.backToHoldings(this.shoppingCart.itemsPerHolding.length == 1 ? this.shoppingCart.itemsPerHolding[0] : null)
            this.modalRef = this.modalService.show(this.template, { class: 'custom-modal', backdrop: 'static', keyboard: false  })

            if(this.shoppingCart.itemsPerHolding.length > 0) {
                this.ngxLoader.startLoader('loader-shopping-cart');

                this.shoppingCart.itemsPerHolding.forEach(shoppingCartHolding => {
                    let relationship = this.companyRelationship.prazosEntrega.find((holding) => holding.holding_id === shoppingCartHolding.id)
                    shoppingCartHolding.delivery_time_id = relationship ? relationship.delivery_time_id : 99

                    let deliveryTime = relationship ? relationship.delivery_time_name.replace('dia útil', '').replace('dias úteis', '').replace(',', '.') : '99'

                    this.buyerMercadoPartsfyListService.getDeliveryDate(deliveryTime).subscribe({
                        next: (response: ApiResponseModel<{deliveryDate: Date}>) => {
                            shoppingCartHolding.delivery_time = response.result.deliveryDate.toString();
                        },
                        error: (err) => {
                            console.log(err)
                        }
                    })
                })

                this.saveCartIntern(this.shoppingCart)

                this.ngxLoader.stopLoader('loader-shopping-cart');
            }
        })
    }

    saveCartIntern(shoppingCart: Carrinho) {
        this.saveCart.emit(shoppingCart)
    }

    selectHolding(holding: ItemPorHolding) {
        this.selectedHolding = holding;
        this.updateItemsInfo(holding)
    }

    updateItemsInfo(holding: ItemPorHolding = null) {
        this.totalQuantity = this.shoppingCart.itemsPerHolding.reduce((total, holdings) => {
            return total + holdings.items.reduce((holdingTotal, items) => {
                return holdingTotal += items.quantity
            }, 0)
        }, 0)

        if (holding) {
            this.deliveryPrice = holding.delivery_price;
            this.itemsPrice = holding.items.reduce((total, item) => total + item.quantity * item.part.priceWithDiscount, 0)
            this.selectedQuantity = holding.items.reduce((total, item) => total + item.quantity, 0)
        } else {
            this.deliveryPrice = this.shoppingCart.itemsPerHolding.reduce((total, holding) => total + holding.delivery_price, 0)

            this.itemsPrice = this.shoppingCart.itemsPerHolding.reduce((total, holdings) => {
                return total + holdings.items.reduce((holdingTotal, items) => {
                    return holdingTotal += items.part.priceWithDiscount * items.quantity
                }, 0)
            }, 0)

            this.selectedQuantity = this.totalQuantity
        }

        this.setShoppingCartTotalQuantity.emit(this.totalQuantity)
    }
}
