<ng-template #templateModalMergeOrder>
    <div class="modal-juntar-pedido">
        <div class="modal-header">
            <div class="d-flex flex-column">
                <h4 class="modal-title">{{ 'buyer.curador.juntar-pedidos.juntar-pedidos' | translate}} - {{description}}</h4>
                <span class="ps-4">{{ 'buyer.curador.juntar-pedidos.modal-subtitulo' | translate}}</span>
            </div>

            <button type="button" data-dismiss="modal" aria-label="Close" class="btn-close" (click)="close();"></button>
        </div>
        <div class="modal-body">
            <ng-scrollbar>
                <div class="content">
                    <div class="orders">
                        <div class="cards-container">
                            @for (order of orders; track order) {
                                <div class="card-select-quotation {{order.orderId === orderIdSelected ? 'selected' : ''}}" (click)="selectOrder(order.orderId)">
                                    <div class="card-header">
                                        <div class="titulo-resposta">
                                            <b>{{ 'buyer.curador.juntar-pedidos.pedido-numero' | translate}}{{order.orderId}}</b>
                                        </div>
                                    </div>

                                    <div class="card-body" style="padding:0">
                                        <div class="items-table table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th class="col-md-1 text-center">{{ 'buyer.cotacao.area-respostas.resposta.pecas.quantidade' | translate}}</th>
                                                        <th class="col-md-6">{{ 'buyer.cotacao.area-respostas.resposta.pecas.nome' | translate}}</th>
                                                        <th class="col-md-3 text-end">{{ 'buyer.cotacao.area-respostas.resposta.pecas.total' | translate}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    @for (item of order.items; track item) {
                                                        <tr>
                                                            <td class="text-center">{{item.quantityBought}}</td>
                                                            <td style="max-width: 150px;"><div class="truncate">{{item.name}}{{item.partNumber ? ' - ' + item.partNumber : ''}}</div></td>
                                                            <td class="text-end colunaValor">
                                                                {{item.price | currency:'R$'}}
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div class="float-end">
                                <button class="btn btn-sm btn-primary" [disabled]="disableButtonMergeOrder()" (click)="mergeOrder()">
                                    {{'buyer.curador.juntar-pedidos.juntar-pedido' |translate}}
                                </button>
                                &nbsp;
                                <button class="btn btn-sm btn-light" (click)="close()">
                                    {{ 'elemento.generico-botao.fechar' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-scrollbar>
        </div>
    </div>
    <ngx-ui-loader [loaderId]="'loader-merge-orders'"></ngx-ui-loader>
</ng-template>
