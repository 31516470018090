@if (montadoras.length > 0) {
    <div class="filter">
        <h2>{{ 'buyer.mercado-partsfy.filtro-lateral.titulo' | translate }}</h2>

        <div class="tags">
            @if (filterList.length == 0) {
                <p class="tag default">
                    <span>{{ 'buyer.mercado-partsfy.filtro-lateral.filtros.tags.all-brands' | translate }}</span>
                </p>
            } @else {
                @for (filter of filterList; track filter) {
                    <p class="tag" (click)="removeFilter(filter)">
                        <i-feather name="x"></i-feather>
                        <span>{{filter}}</span>
                    </p>
                }
            }
        </div>

        <div class="section">
            @if (montadoras.length > 0) {
                <div class="header" data-bs-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <label class="title">{{ 'buyer.mercado-partsfy.filtro-lateral.filtros.brand' | translate }}</label>
                    <i-feather name="chevron-down" class="colapse-section-chevron chevron-down"></i-feather>
                    <i-feather name="chevron-up" class="colapse-section-chevron chevron-up"></i-feather>
                </div>

                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne">
                    <div>
                        <ng-scrollbar height="auto">
                            @for (montadora of montadoras; track montadora) {
                                <div #montadoras class="input-group">
                                    <input type="checkbox" name="montadora" [value]="montadora?.brandId" [id]="montadora?.brandId" [attr.data-name]="montadora.brandName" (click)="updateFilter(_montadoraFilterName, filterSelectedMontadoras, montadora.brandId, montadora.brandName, true)"/>
                                    <label [for]="montadora?.brandId">
                                        {{montadora.brandName}}
                                        <span>{{montadora?.totalItems < 10 ? '0' + montadora?.totalItems : montadora?.totalItems}}</span>
                                    </label>
                                    <div class="fa fa-spinner spin montadora_{{montadora?.brandId}}" style="display: none;"><div></div></div>
                                </div>
                            }
                        </ng-scrollbar>
                    </div>
                </div>
            }
        </div>
    </div>
}
