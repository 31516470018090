<div class="page" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <nav class="nav-market">
        <ul class="nav-main">
            @for (category of categories; track category) {
                <a [ngClass]="{'active': this.selectedCategory == category.categoryId}" [routerLink]="'/comprador/mercado/' + category.name" [id]="category.name">{{ ('buyer.mercado-partsfy.navegacao.' + category.name) | translate }}</a>
            }
        </ul>
        <ul class="nav nav-right">
            <li class="shopping-cart" (click)="openModal({ modalId: 2 })">
                <i-feather name="shopping-cart" class="cart"></i-feather>
                @if (shoppingCartTotalQuantity > 0) {
                    <span> {{ shoppingCartTotalQuantity > 99 ? '99+' : shoppingCartTotalQuantity }} </span>
                }
            </li>
        </ul>
    </nav>

    <ng-scrollbar #scrollable="ngScrollbar" class="mercado-scrollable">
        <main>
            <div class="list-wrapper">
                @if (parts?.length) {
                    <section class="list-header">
                        <p [innerHTML]="'buyer.mercado-partsfy.informacoes.quantidade-pecas' | translate: { quantity: parts.length, totalQuantity: totalItems }"></p>
                    </section>
                }
                <section class="list-body">
                    <app-filter #filterComponent [montadoras]="montadoras" [filterGlobal]="filterGlobal" (filterChanged)="filter($event)"></app-filter>

                    @if (parts?.length) {
                        <div class="main-content">
                            <section class="list">
                                @for (part of parts; track part) {
                                    <app-card [part]="part" [companyRelationship]="companyRelationship" (openModalEvent)="openModal($event)"></app-card>
                                }
                            </section>
                            @if (totalPages > 1) {
                                <app-pagination [currentPage]="currentPage" [filterGlobal]="filterGlobal" [totalPages]="totalPages" (changePage)="changePage($event)"></app-pagination>
                            }
                            <ngx-loading [show]="loading" ></ngx-loading>
                        </div>
                    } @else {
                        <div class="no-products-found">
                            <div class="top-section">
                                <i-feather name="frown"></i-feather>
                                <h1>{{ 'buyer.mercado-partsfy.listagem-vazia.titulo' | translate }}</h1>
                                <p>{{ 'buyer.mercado-partsfy.listagem-vazia.descricao' | translate }}</p>
                            </div>
                            <a [routerLink]="'/comprador/visao-geral/nova-cotacao'" class="btn-quotation">
                                {{ 'buyer.mercado-partsfy.listagem-vazia.botao' | translate }}
                            </a>
                        </div>
                    }
                </section>
            </div>
        </main>
    </ng-scrollbar>
</div>
<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>

<app-part-details #templatePartDetails [shoppingCart]="shoppingCart" [companyRelationship]="companyRelationship" [companyAddress]="companyAddress" (saveCart)="saveShoppingCart($event)" (setShoppingCartTotalQuantity)="setShoppingCartTotalQuantity($event)"></app-part-details>
@if (companyRelationship) {
    <app-shopping-cart #templateShoppingCart [shoppingCart]="shoppingCart" [companyRelationship]="companyRelationship" (deleteCartItems)="deleteShoppingCartItems()" (saveCart)="saveShoppingCart($event)" (setShoppingCartTotalQuantity)="setShoppingCartTotalQuantity($event)"></app-shopping-cart>
}

