<div class="convites col-md-12" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [hasBackButton]="false"
        [title]="'buyer.convidar.titulo' | translate"
    ></app-header>

    <div class="card card-default col-md-8 offset-md-2">
        <ng-scrollbar>
            <app-dados-convite [loading_local]="false"></app-dados-convite>
        </ng-scrollbar>
    </div>
</div>
<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
