import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({ selector: 'app-pagination', templateUrl: './pagination.component.html', styleUrls: ['./pagination.component.scss'] })
export class BuyerMercadoPartsfyListPaginationComponent
{
    @Input() currentPage: number;
    @Input() filterGlobal: any
    @Input() totalPages: number;

    @Output() changePage = new EventEmitter<number>()

    constructor(private _location: Location) { }

    public nextPage() {
        if (this.currentPage + 1 > this.totalPages) return

        let nextPage = this.currentPage + 1

        this.updateUrlFilter({ page: nextPage.toString() })
        this.changePage.emit(nextPage)
    }

    public previousPage() {
        if (this.currentPage - 1 <= 0) return

        let previousPage = this.currentPage - 1

        this.updateUrlFilter({ page: previousPage.toString() })
        this.changePage.emit(previousPage)
    }

    updateUrlFilter(filter: any) {
        this.filterGlobal = Object.assign(this.filterGlobal, filter);
        let filterKeys = Object.keys(this.filterGlobal);
        const queryParams = filterKeys.map(filterKey => {
            if (this.filterGlobal[filterKey] && this.filterGlobal[filterKey].length > 0)
                return `${filterKey}=${this.filterGlobal[filterKey]}`;
        }).filter((filter) => filter !== undefined).join('&');

        const baseUrl = this._location.path().split('?')[0];
        const pageUrl = queryParams?.length > 0 ? `${baseUrl}?${queryParams}` : baseUrl;

        this._location.go(pageUrl);
    }
}
