import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';

import { AccountService } from 'app/account/account.service';
import { BaseService } from 'app/_services/base.service';
import { SettingsService } from 'app/_services/settings.service';


@Injectable({ providedIn: 'root' })
export class BuyerVendedoresConvidadosService extends BaseService {
    private _domain: string = '/buyer/invites';

    constructor(private accountService: AccountService, private config: SettingsService) {
        super();
    }

    public archiveInvite(id: any): Observable<any> {
        return this.config.executePut({ url: `${this._domain}/${id}/archive` });
    }

    public getAllByBuyerCompanyId(): Observable<any> {
        if (!this.usuarioLocal) {
            this.accountService.logout();
            return EMPTY;
        }

        const buyer_company_id = this.usuarioLocal.user_company_id;

        return this.config.executeGet({ url: `${this._domain}/${buyer_company_id}` });
    }

    public resendInvite(id: any): Observable<any> {
        return this.config.executePost({ url: `${this._domain}/${id}/resend` });
    }
}
